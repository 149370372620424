import { Box, useMediaQuery, useTheme } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { Carousel } from '../../../components/ui/Carousel';
import { moduleRoutes } from './helpers/constants';
import { ServiceCategory } from '../../../components/ui/ServiceCategory';
import { ServiceItem } from '../../../components/ui/ServiceItem';
import { useDispatch } from '../../../store';
import {
  useFetchOrganisationListQuery,
  useLazyFetchServicesListQuery,
} from '../../../store/hooks/services';
import useUrlParams from '../../../shared/utils/useUrlParams';
import TableCellToRow from 'components/ui/TableCellToRow/TableCellToRow';
import TableCellToRowKey from 'components/ui/TableCellToRow/TableCellToRowKey/TableCellToRowKey';
import TableCellToRowValue from 'components/ui/TableCellToRow/TableCellToRowValue/TableCellToRowValue';

const EgovServices: FC = () => {
  const [params, setUrlParams] = useUrlParams();
  const { data: orgs } = useFetchOrganisationListQuery();
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const [refetch, serviceRes] = useLazyFetchServicesListQuery(params.orgId);

  const organisatios = useMemo(() => {
    return (
      orgs?.items?.map((item) => ({
        iconUrl: item.image,
        name: item.name,
        id: item.id,
        services: item.services,
      })) || []
    );
  }, [orgs]);

  useEffect(() => {
    if (organisatios.length > 0 && !params.orgId) {
      setUrlParams('orgId', organisatios[0].id);
    }
  }, [organisatios, params]);

  useEffect(() => {
    if (params.orgId) {
      refetch(params.orgId);
    }
  }, [params.orgId]);

  const serviceList = serviceRes.data || [];

  return (
    <Box sx={{ py: 4 }}>
      <Box py={10}>
        <Carousel
          width={192}
          items={organisatios}
          renderView={(data) => (
            <ServiceCategory
              onClick={() => {
                setUrlParams('orgId', data.id);
              }}
              active={Number(params.orgId) === data.id}
              {...data}
            />
          )}
        />
      </Box>
      {serviceList.length > 0 && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: isSmallDevice ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)',
            gap: 2,
            px: 2,
            py: 5,
            borderRadius: '20px',
            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.24)',
            backgroundColor: '#fff',
          }}
        >
          {serviceList.map((item) => {
            return (
              <ServiceItem
                id={String(item.id)}
                key={item.id}
                route={moduleRoutes[item.id] || ''}
                name={item.name}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default EgovServices;
