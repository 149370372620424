import store from '../../store';
import { setAuthStatus } from '../../store/slices/credentials';
import { ILoginRes } from '../../store/slices/credentials/models';
import { LS_ACCESS_TOKEN } from '../constants/auth';

export const getTokenFromStorage = (fullInfo?: boolean) => {
  let data: any = localStorage.getItem(LS_ACCESS_TOKEN);

  try {
    if (data) {
      data = JSON.parse(data);
      store.dispatch(setAuthStatus());
      return fullInfo ? (data as ILoginRes) : data.accessToken;
    }
  } catch {}

  return null;
};

export const setTokenToStorage = (data: ILoginRes) => {
  localStorage.setItem(LS_ACCESS_TOKEN, JSON.stringify(data));
};

export const clearTokenFromStorage = () => {
  localStorage.removeItem(LS_ACCESS_TOKEN);
  localStorage.removeItem('userDetails');
};
