import React, { useState } from 'react';
import { Box, Paper, Typography, useMediaQuery } from '@mui/material';
import UserForm from './forms/UserForm';
import {
  actionContainerSx,
  containerSx,
  paperSx,
  typographySx,
} from './styles';
import CustomButton from '../../../components/ui/CustomButton';
import {
  IInitialLegalValues,
  IInitialValues,
  initialValues,
  validationSchema,
  initialLegalValues,
  validationLegalSchema,
} from './schema';
import { useFormik } from 'formik';
import {
  useCreateLegalMutation,
  useCreateUserMutation,
} from '../../../store/hooks/users';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { setRedirectedUser } from '../../../store/slices/credentials';
import { useDispatch } from '../../../store';
import { AppRoutes } from '../../../shared/constants/AppRoutes';
import { login } from '../../../store/slices/credentials/actions';
import TabsSegment, { ITabsSegmentItem } from 'components/ui/CustomSegment';
import { tabsOptions } from '../login/helpers/constants';
import LegalForm from './forms/LegalForm';

const RegistrationPage = () => {
  const [createUser] = useCreateUserMutation();
  const [createLegal] = useCreateLegalMutation();
  const [certificateRequired, setCertificateRequired] = useState(false);
  const [formType, setFormType] = useState<ITabsSegmentItem | null>(
    tabsOptions[0]
  );

  const tabsChange = (value: ITabsSegmentItem) => {
    setFormType(value);
    setCertificateRequired(value.value === 'entity');
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');

  const handleLogin = (values: IInitialValues) => {
    const data: any = {
      userLogin: values.phone,
      password: values.passWord,
      grantType: 'password',
      code: String(values.code),
      userType: formType?.code,
    };

    const toastId = toast.loading(
      'Подождите, сейчас перенаправим Вас в систему'
    );

    dispatch(
      login({
        data,
        onSuccess: () => {
          toast.dismiss(toastId);
          navigate('/profile/account');
        },
        onError: () => toast.dismiss(toastId),
      })
    );
  };

  const handleLegalLogin = (values: IInitialLegalValues) => {
    const data: any = {
      userLogin: values.phone,
      password: values.passWord,
      grantType: 'password',
      code: String(values.code),
      userType: formType?.code,
    };

    const toastId = toast.loading(
      'Подождите, сейчас перенаправим Вас в систему'
    );

    dispatch(
      login({
        data,
        onSuccess: () => {
          toast.dismiss(toastId);
          navigate('/profile/account');
        },
        onError: () => toast.dismiss(toastId),
      })
    );
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit(values) {
      const { phoneCodeSended, codeChecked, ...payload } = values;

      const promise = createUser(payload).then((res: any) => {
        if (res.error) {
          return;
        }
        toast.success('Пользователь успешно создан');
        // handleLogin(values);
        navigate('/auth/login');
      });

      toast.promise(promise, {
        pending: 'Пользователь создается',
      });
    },
  });

  const formik_legal = useFormik({
    initialValues: initialLegalValues,
    validationSchema: validationLegalSchema,
    onSubmit(values) {
      const { phoneCodeSended, codeChecked, ...payload } = values;
      const promise = createLegal(payload).then((res: any) => {
        if (res.error) {
          return;
        }
        toast.success('Пользователь успешно создан');
        // handleLegalLogin(values);
        navigate('/auth/login');
      });

      toast.promise(promise, {
        pending: 'Пользователь создается',
      });
    },
  });

  return (
    <Box sx={containerSx}>
      <Box
        sx={{
          width: isSmallDevice ? '100%' : '50%',
          marginX: isSmallDevice ? 3 : 0,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 5 }}>
          <Box sx={{ width: isSmallDevice ? '100%' : '50%' }}>
            <TabsSegment
              items={tabsOptions}
              value={formType}
              onChange={tabsChange}
            />
          </Box>
        </Box>
        <Paper sx={paperSx(isSmallDevice)}>
          <CustomButton
            size="small"
            onClick={() => navigate('/auth/login')}
            variant="contained"
            sx={{
              width: isSmallDevice ? '230px' : '280px',
              height: isSmallDevice ? '48px' : '60px',
              fontSize: isSmallDevice ? '14px' : '18px',
              position: 'absolute',
              top: '0',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            Вход
          </CustomButton>
          {formType?.value === 'individual' ? (
            <UserForm formik={formik} />
          ) : (
            <LegalForm formik={formik_legal} />
          )}
          <Box sx={actionContainerSx}>
            {formType?.value === 'individual' ? (
              <CustomButton
                disabled={!formik.values.codeChecked}
                sx={{
                  width: isSmallDevice ? '230px' : '320px',
                  height: isSmallDevice ? '48px' : '60px',
                  fontSize: isSmallDevice ? '14px' : '16px',
                }}
                variant="contained"
                onClick={() => formik.submitForm()}
              >
                Зарегистрироваться
              </CustomButton>
            ) : (
              <CustomButton
                disabled={!formik_legal.values.codeChecked}
                sx={{
                  width: isSmallDevice ? '230px' : '320px',
                  height: isSmallDevice ? '48px' : '60px',
                  fontSize: isSmallDevice ? '14px' : '16px',
                }}
                variant="contained"
                onClick={() => {
                  console.log(1);
                  formik_legal.submitForm();
                }}
              >
                Зарегистрироваться
              </CustomButton>
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default RegistrationPage;
