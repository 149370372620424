import { Box, IconButton, Paper, useMediaQuery } from '@mui/material';
import React, { FC, useState } from 'react';
import CustomField from '../../../../components/forms/CustomField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LockIcon from '@mui/icons-material/Lock';
import SourceIcon from '@mui/icons-material/Source';
import { FormikProps } from 'formik';
import authApi from '../../../../store/slices/credentials/service';
import { toast } from 'react-toastify';
// import { IInitialValues, KeyOfInitials } from '../schema';
import { getFieldErrors } from '../../../../shared/utils/yup';
import { phoneRegexp } from 'routes/public/login/helpers/constants';
import { KeyOfInitials } from '../schema';

interface IUserForm {
  formik: any;
}

const UserForm: FC<IUserForm> = ({ formik }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');

  const { values, setFieldValue, setFieldTouched } = formik;
  console.log(values);

  const handleCheckCode = (code: string) => {
    const data = {
      login: values.login,
      code: Number(code),
      authType: 1,
    };
    const toastId = toast.loading('Проверяется введенный вами код');
    authApi.checkCode(data).then((res) => {
      toast.dismiss(toastId);
      if (res.status === 200) {
        toast.success('Проверка кода успешно');
        setFieldValue('codeChecked', true);
      }
    });
  };

  const handlers = {
    phone(key = 'phone') {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;

        if (value.length === 3) {
          setFieldValue(key, '');
          return;
        }

        if (!value.startsWith('992')) {
          value = '992' + value;
        }

        if (value.length > 12 || !phoneRegexp.test(value)) return;

        setFieldValue(key, value);
        if (key !== 'phone') {
          setFieldValue('phone', value);
        }
      };
    },

    getForKey(key: KeyOfInitials) {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const digits = /^[0-9]{0,}$/;

        if (key === 'code') {
          if (!digits.test(value) || value.length > 4) {
            return;
          }

          if (value.length === 4) {
            handleCheckCode(value);
          }
        }
        setFieldValue(key, value);
        setFieldTouched(key, true);
      };
    },

    getHandleBlur(key: string) {
      return () => {
        setFieldTouched(key, true);
      };
    },
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 4 }}>
      <CustomField
        id="login"
        label="Телефон"
        type="text"
        StartIcon={PersonIcon}
        isSmallScreen={isSmallDevice}
        value={values.login || ''}
        onChange={handlers.phone('login')}
        onBlur={handlers.getHandleBlur('login')}
        {...getFieldErrors(formik, 'login')}
      />

      <CustomField
        disabled={!values.codeSended}
        id="code"
        label="Код"
        type="text"
        autoComplete="current-password"
        isSmallScreen={isSmallDevice}
        StartIcon={SourceIcon}
        value={values.code}
        onChange={handlers.getForKey('code')}
      />
      <CustomField
        id="newPassWord"
        label="Новый пароль"
        disabled={!values.codeChecked}
        type={passwordVisible ? 'text' : 'password'}
        StartIcon={LockIcon}
        isSmallScreen={isSmallDevice}
        value={values.newPassWord || ''}
        onChange={handlers.getForKey('newPassWord')}
        endIcon={
          <IconButton onClick={() => setPasswordVisible(!passwordVisible)}>
            {passwordVisible ? (
              <VisibilityOffIcon color="primary" fontSize="large" />
            ) : (
              <VisibilityIcon color="primary" fontSize="large" />
            )}
          </IconButton>
        }
        onBlur={handlers.getHandleBlur('newPassWord')}
        {...getFieldErrors(formik, 'newPassWord')}
      />
      <CustomField
        id="passWordAgain"
        label="Повторите новый пароль "
        type={passwordVisible ? 'text' : 'password'}
        StartIcon={LockIcon}
        disabled={!values.codeChecked}
        isSmallScreen={isSmallDevice}
        value={values.passWordAgain || ''}
        onChange={handlers.getForKey('passWordAgain')}
        endIcon={
          <IconButton onClick={() => setPasswordVisible(!passwordVisible)}>
            {passwordVisible ? (
              <VisibilityOffIcon color="primary" fontSize="large" />
            ) : (
              <VisibilityIcon color="primary" fontSize="large" />
            )}
          </IconButton>
        }
        onBlur={handlers.getHandleBlur('passWordAgain')}
        {...getFieldErrors(formik, 'passWordAgain')}
      />
    </Box>
  );
};

export default UserForm;
