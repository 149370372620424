import ListItemIcon from '@mui/material/ListItemIcon';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DescriptionIcon from '@mui/icons-material/Description';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import TaskIcon from '@mui/icons-material/Task';
import SecurityIcon from '@mui/icons-material/Security';
import InfoIcon from '@mui/icons-material/Info';
import ServicesIcon from '@mui/icons-material/DesignServicesOutlined';
import ApartmentIcon from '@mui/icons-material/Apartment';

export const menuItem = [
  {
    id: 1,
    title: 'Учетная запись',
    Icon: DashboardIcon,
    path: 'account',
    pathName: '/profile/account',
  },
  {
    id: 1,
    title: 'Организация',
    Icon: ApartmentIcon,
    path: 'organization',
    pathName: '/profile/organization',
  },
  // {
  //   id: 9,
  //   title: 'Услуги',
  //   Icon: ServicesIcon,
  //   path: '/',
  //   pathName: '/',
  // },
  // {
  //   id: 2,
  //   title: 'Организация',
  //   Icon: PersonIcon,
  //   path: '',
  //   pathName: '',
  // },
  // {
  //   id: 3,
  //   title: 'Реквизиты',
  //   Icon: CreditCardIcon,
  //   path: 'requisites',
  //   pathName: '/profile/requisites',
  // },
  {
    id: 4,
    title: 'Документы и данные',
    Icon: DescriptionIcon,
    path: 'documents',
    pathName: '/profile/documents',
  },
  // {
  //   id: 5,
  //   title: 'Биометрия',
  //   Icon: FingerprintIcon,
  //   path: '',
  //   pathName: '',
  // },
  // {
  //   id: 6,
  //   title: 'Согласия и доверенность',
  //   Icon: TaskIcon,
  //   path: '',
  //   pathName: '',
  // },
  // {
  //   id: 7,
  //   title: 'Безопасность',
  //   Icon: SecurityIcon,
  //   path: '',
  //   pathName: '',
  // },
  {
    id: 8,
    title: 'Поддержка',
    Icon: InfoIcon,
    path: 'support',
    pathName: '/profile/support',
  },
];
