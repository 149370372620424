import { Box, IconButton } from '@mui/material';
import { FC, ReactNode, useRef } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ServiceCategory } from '../ServiceCategory';

interface ICarousel {
  width?: number;
  items: any[];
  renderView: (data: any) => ReactNode;
}

export const Carousel: FC<ICarousel> = ({ width = 184, items, renderView }) => {
  const scrollRef = useRef<HTMLDivElement>();
  const step = width + 24;

  const handlers = {
    changeStep(toRight?: boolean) {
      if (scrollRef.current) {
        const left = scrollRef.current.scrollLeft + step * (toRight ? -1 : 1);

        scrollRef.current.scrollTo({
          left,
          behavior: 'smooth',
        });
      }
    },
  };

  return (
    <Box sx={{ px: 8, position: 'relative' }}>
      <Box ref={scrollRef} sx={{ overflowX: 'auto', position: 'relative' }}>
        <Box display="flex" flexWrap="nowrap" gap="24px" position="relative">
          {items.map((item, idx) => (
            <Box key={idx} minWidth={width + 'px'} minHeight="100px">
              {renderView(item)}
            </Box>
          ))}
        </Box>
      </Box>
      <IconButton
        onClick={() => handlers.changeStep(true)}
        sx={{
          position: 'absolute',
          top: '50%',
          left: 0,
          transform: 'translateY(-50%)',
          boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
      >
        <ChevronLeftIcon fontSize="large" sx={{ fontSize: '30px' }} />
      </IconButton>
      <IconButton
        onClick={() => handlers.changeStep()}
        sx={{
          position: 'absolute',
          top: '50%',
          right: 0,
          transform: 'translateY(-50%)',
          boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
      >
        <ChevronRightIcon fontSize="large" sx={{ fontSize: '30px' }} />
      </IconButton>
    </Box>
  );
};
