import { FC, SyntheticEvent } from 'react';
import { boxSx, getItemSx, tabsListSx, tabsSx } from './styles';
import CustomTitle from '../CustomTitle';
import { Box, useTheme } from '@mui/material';

export interface ITabsSegmentItem {
  label: string;
  value: string;
  code: number;
}

interface ITabsSegment {
  items: ITabsSegmentItem[];
  value: ITabsSegmentItem | null;
  onChange: (value: ITabsSegmentItem) => void;
}

const TabsSegment: FC<ITabsSegment> = ({ items, value, onChange }) => {
  const handlers = {
    change(currValue: string | number | null) {
      const option = items.find((item) => item.value === currValue);
      if (option) {
        onChange(option);
      }
    },
  };

  const theme = useTheme();

  return (
    <Box sx={boxSx}>
      {items.map((item) => (
        <Box
          onClick={() => handlers.change(item.value)}
          sx={getItemSx(value?.value === item.value, theme)}
          key={item.value}
        >
          <CustomTitle size="sm">{item.label}</CustomTitle>
        </Box>
      ))}
    </Box>
  );
};

export default TabsSegment;
