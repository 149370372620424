import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { FC } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import {
  formControllSx,
  iconSx,
  inputStyles,
  labelStyles,
  selectFieldSx,
} from './styles';
import { MuiIconType } from '../../../shared/utils/types';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export interface ICustomSelectItem {
  label: string;
  value: string;
}

interface ICustomSelect {
  value: any;
  label: string;
  items: Array<any>;
  onChange: (item: any) => void;
  StartIcon: MuiIconType;
}

const CustomSelect: FC<ICustomSelect> = ({
  value,
  items,
  label,
  StartIcon,
  onChange,
}) => {
  const handleChange = (e: any, newVal: any) => {
    const found = items.find(item => item.value === e.target.value);

    if (found) {
      onChange(found)
    }
  };

  return (
    <FormControl sx={formControllSx}>
      {StartIcon && <StartIcon fontSize="large" sx={iconSx} />}
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        value={value.value}
        onChange={handleChange}
        input={<OutlinedInput sx={inputStyles} label={label} />}
        MenuProps={MenuProps}
        sx={selectFieldSx}
      >
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
