import { SxProps } from '@mui/material';

export type TitleSizeType = 'sm' | 'md' | 'lg';

export const titleSizesSx: Record<TitleSizeType, SxProps> = {
  sm: {
    fontSize: '1.8rem',
    lineHeight: '120%',
    fontWeight: 600,
    fontFamily: 'Mulish sans-serif',
  },
  md: {
    fontSize: '2.1rem',
    lineHeight: '120%',
    fontWeight: 700,
    fontFamily: 'Mulish sans-serif',
  },
  lg: {
    fontSize: '2.4rem',
    lineHeight: '120%',
    fontWeight: 700,
    fontFamily: 'Mulish sans-serif',
  },
};
