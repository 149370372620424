import { Box, Button, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import { FC, useState } from 'react';
import { InputField } from '../../../../../../components/forms/InputField';
import { useGetEgovContactsQuery } from '../../../../../../store/hooks/general';
import { useSession } from '../../../../../../shared/hooks/useSession';
import {
  ISupportSendReques,
  useGetUserprofileDetailsQuery,
  useSendSupportMessageMutation,
} from '../../../../../../store/hooks/userprofile';
import { toast } from 'react-toastify';

export const FeedbackForm: FC = () => {
  const { data } = useGetEgovContactsQuery();
  const { data: profileData, refetch } = useGetUserprofileDetailsQuery();
  const [sendSupportMessage] = useSendSupportMessageMutation();
  const [message, setMessage] = useState('');
  const [errorProps, setErrorProps] = useState({});
  const session = useSession();
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');

  const handleSubmit = () => {
    if (!message.trim().length) {
      setErrorProps({
        error: true,
        helperText: 'Поле обязательно для заполнения',
      });
      return;
    }

    const payload: ISupportSendReques = {
      regUser: session.user?.info?.id as number,
      message,
    };

    const promise = sendSupportMessage(payload).then(() => {
      setMessage('');
      setErrorProps({});
    });

    toast.promise(promise, {
      pending: 'Ваш запрос обрабатывается',
      success: 'Ваше сообщение доставлено',
      error: 'Произошло ошибка',
    });
  };

  return (
    <Box >
      <Typography sx={{ marginBottom: isSmallDevice ? '20px' : '' }} variant="h6">Свяжитесь с нами</Typography>
      <Grid spacing={isSmallDevice ? 0 : 2}>
        <Grid item md={6}>
          <InputField
            id="tel"
            label="Телефон"
            placeholder="+992XXXXXXXXX"
            value={profileData?.authorized ? profileData?.phone : ''}
            sx={{ marginBottom: isSmallDevice ? '10px' : '' }}
          />
        </Grid>
        <Grid item md={6}>
          <InputField
            disabled
            id="email"
            label="Email"
            placeholder="example@mail.ru"
            value={profileData?.authorized ? profileData?.email : ''}
            sx={{ marginBottom: isSmallDevice ? '10px' : '' }}

          />
        </Grid>
        <Grid item md={12}>
          <InputField
            id="description"
            label="Задайте свой вопрос"
            multiline
            minRows={6}
            placeholder="Введите вопрос..."
            value={message}
            {...errorProps}
            onChange={(e) => {
              setMessage(e.target.value);
              setErrorProps({});
            }}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: isSmallDevice ? 'center' : 'end',
          pt: 3,
        }}
      >
        <Button
          sx={{ borderRadius: '5px', minWidth: '150px' }}
          variant="contained"
          onClick={handleSubmit}
        >
          Отправить
        </Button>
      </Box>
    </Box>
  );
};
