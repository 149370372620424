import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  IconButton,
  Pagination,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { SectionForm } from 'components/forms/SectionForm';
import Loading from 'components/ui/Loading';
import TableCellToRow from 'components/ui/TableCellToRow/TableCellToRow';
import TableCellToRowKey from 'components/ui/TableCellToRow/TableCellToRowKey/TableCellToRowKey';
import TableCellToRowValue from 'components/ui/TableCellToRow/TableCellToRowValue/TableCellToRowValue';
import { useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSession } from 'shared/hooks/useSession';
import FileAddIcon from 'shared/icons/FileAddIcon';
import ListIcon from 'shared/icons/ListIcon';
import { deepCopy } from 'shared/utils/deepCopy';
import {
  IServiceSearchRequest,
  useLazyGetReceiptWithInvoiceNumberQuery,
  useMyServiceQuery,
} from 'store/hooks/services';
import { useIsAuthorizedQuery } from 'store/hooks/userprofile';
import { serviceStatusOptions } from '../services/application/create/helpers/constants';
import {
  headerTitles,
  initialFilters,
  payStatuses,
  statuses,
} from './helpers/constants';
import DataTable from 'components/MySerivces/DataTable';
import { UploadFile } from '@mui/icons-material';

function MyServices() {
  const [state, setState] = useState<number>(0);
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const [check, data] = useLazyGetReceiptWithInvoiceNumberQuery();

  const [filters, setFilters] = useState<IServiceSearchRequest['filters']>(
    deepCopy(initialFilters)
  );

  const { pathname } = useLocation();

  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { data: isAuthorized } = useIsAuthorizedQuery({});
  const { user } = useSession();

  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage1(value);
  };
  const { data: serviceList, isFetching } = useMyServiceQuery({
    ids: null,
    orderBy: {
      orderColumn: 1,
      direction: 1,
    },
    pageInfo: {
      pageNumber: isSmallDevice ? page1 : page + 1,
      pageSize: pageSize,
    },
    filters: {
      ...filters,
    },
  });

  const selectedState = useMemo(
    () => serviceStatusOptions?.find((item: any) => item.id === filters.state),
    [filters.state]
  );

  return (
    <>
      <Box sx={{ py: 4 }}>
        <SectionForm emptyBox title={'Мои услуги'}>
          <div className="tw-grid  tw-grid-cols-[1fr_187px] tw-w-full tw-gap-4 tw-mb-10">
            {isSmallDevice ? null : (
              <div className="tw-grid  tw-grid-flow-col-dense tw-auto-cols-[200px] tw-gap-4">
                <Autocomplete
                  disablePortal
                  options={serviceStatusOptions}
                  size="small"
                  value={selectedState}
                  getOptionLabel={(option) => option.value as string}
                  renderInput={(params) => (
                    <TextField {...params} label="Статус" />
                  )}
                  onChange={(event, value) => {
                    setFilters({
                      ...filters,
                      state: Number(value?.id) || 0,
                    });
                  }}
                />

                <Button
                  startIcon={
                    isFetching ? (
                      <Loading />
                    ) : (
                      <ListIcon
                        width="18px"
                        height="18px"
                        fill="currentColor"
                        stroke="none"
                      />
                    )
                  }
                  disabled={isFetching}
                  onClick={() => { }}
                >
                  Список
                </Button>
              </div>
            )}
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                pt: isSmallDevice ? '20px' : '0',
              }}
            >
              <Link to={'/'}>
                <Button
                  sx={{ display: isSmallDevice ? 'none' : 'flex' }}
                  variant="contained"
                  startIcon={
                    <FileAddIcon
                      width="18px"
                      height="18px"
                      fill="currentColor"
                      stroke="none"
                    />
                  }
                >
                  Добавить
                </Button>
              </Link>
            </Box>
          </div>

          {isSmallDevice && (
            <div className="tw-py-4 tw-bg-[white] tw-p-6 tw-rounded-[8px]">
              <div className="tw-grid tw-grid-cols-[1fr_187px] tw-w-full tw-gap-4 tw-mb-10">
                {isSmallDevice ? null : (
                  <div className="tw-grid  tw-grid-flow-col-dense tw-auto-cols-[200px] tw-gap-4">
                    <Autocomplete
                      disablePortal
                      options={serviceStatusOptions}
                      size="small"
                      value={selectedState}
                      getOptionLabel={(option) => option.value as string}
                      renderInput={(params) => (
                        <TextField {...params} label="Статус" />
                      )}
                      onChange={(event, value) => {
                        setFilters({
                          ...filters,
                          state: Number(value?.id) || 0,
                        });
                      }}
                    />

                    <Button
                      startIcon={
                        isFetching ? (
                          <Loading />
                        ) : (
                          <ListIcon
                            width="18px"
                            height="18px"
                            fill="currentColor"
                            stroke="none"
                          />
                        )
                      }
                      disabled={isFetching}
                      onClick={() => { }}
                    >
                      Список
                    </Button>
                  </div>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    gap: '20px',
                    pt: isSmallDevice ? '20px' : '0',
                  }}
                >
                  <Autocomplete
                    sx={{ width: '140px' }}
                    fullWidth
                    disablePortal
                    options={serviceStatusOptions}
                    size="small"
                    value={selectedState}
                    getOptionLabel={(option) => option.value as string}
                    renderInput={(params) => (
                      <TextField {...params} label="Статус" />
                    )}
                    onChange={(event, value) => {
                      setFilters({
                        ...filters,
                        state: Number(value?.id) || 0,
                      });
                    }}
                  />

                  <Button
                    onClick={() => {
                      if (user) {
                        if (isAuthorized) {
                          navigate('/');
                        } else {
                          toast.error('Необходимо пройти аутентификацию.', {
                            autoClose: 5000,
                          });
                          navigate('/profile/account');
                        }
                      }
                    }}
                    variant="contained"
                    startIcon={
                      <FileAddIcon
                        width="18px"
                        height="18px"
                        fill="currentColor"
                        stroke="none"
                      />
                    }
                  >
                    Добавить
                  </Button>
                </Box>
              </div>
              {isSmallDevice
                ? serviceList?.items?.length > 0 && (
                  <>
                    {serviceList?.items?.map((item: any) => {
                      return (
                        <>
                          <TableCellToRow
                            key={item.id}
                            onClick={() => {
                              navigate(
                                `/services/details/${item.service.id}/application/show/${item.id}`
                              );
                            }}
                          >
                            <TableCellToRowKey>№</TableCellToRowKey>
                            <TableCellToRowValue>
                              {item.id}
                            </TableCellToRowValue>
                            <TableCellToRowKey>
                              Название сервиса
                            </TableCellToRowKey>
                            <TableCellToRowValue>
                              {item.service.value}
                            </TableCellToRowValue>
                            <TableCellToRowKey>
                              Название сервиса
                            </TableCellToRowKey>
                            <TableCellToRowValue>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 2,
                                }}
                              >
                                <Avatar src={item.user?.avatar} />
                                <Typography fontSize="15px">
                                  {item.user?.value}
                                </Typography>
                              </Box>
                            </TableCellToRowValue>
                            <TableCellToRowKey>Сумма</TableCellToRowKey>
                            <TableCellToRowValue>
                              {item.invoicePaidMoney}
                            </TableCellToRowValue>
                            <TableCellToRowKey>
                              Статус оплаты
                            </TableCellToRowKey>
                            <TableCellToRowValue>
                              {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                //@ts-ignore
                                payStatuses[item.state as string]
                                  ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  //@ts-ignore
                                  payStatuses[item.state as string]
                                  : 'не активен'
                              }
                            </TableCellToRowValue>
                            <TableCellToRowKey>
                              Статус заявки
                            </TableCellToRowKey>
                            <TableCellToRowValue>
                              {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                //@ts-ignore
                                statuses[item.state as string]
                                  ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  //@ts-ignore
                                  statuses[item.state as string]
                                  : 'не активен'
                              }
                            </TableCellToRowValue>
                            <TableCellToRowKey>Квитаниция</TableCellToRowKey>
                            <TableCellToRowValue>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (!item?.invoiceNumber)
                                    return toast.error(
                                      'Номер счета не найден '
                                    );

                                  window.open(window.location.origin + '/receipt/' + item.invoiceNumber)
                                }}
                              >
                                <UploadFile fontSize="large" />
                              </IconButton>
                            </TableCellToRowValue>
                          </TableCellToRow>
                        </>
                      );
                    })}
                    <Pagination
                      color="primary"
                      siblingCount={0}
                      count={Math.ceil(serviceList?.total / 10)}
                      page={page1}
                      onChange={handleChange}
                    />
                  </>
                )
                : null}
            </div>
          )}
          {!isSmallDevice && (
            <DataTable
              sx={{ fontSize: '13px' }}
              pushUri={'/services/details'}
              columns={headerTitles((id: string) => {
                window.open(window.location.origin + '/receipt/' + id)
              })}
              items={serviceList?.items || []}
              isLoading={isFetching}
              page={page}
              pageSize={pageSize}
              setPageSize={setPageSize}
              setPage={setPage}
              totalItems={serviceList?.total}
            />
          )}
        </SectionForm>
      </Box>
    </>
  );
}

export default MyServices;
