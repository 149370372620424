import { Box, CircularProgress, Dialog } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ChatHeader } from './ChatHeader';
import { ChatMessages } from './ChatMessages';
import { ChatInput } from './ChatInput';
import {
  IChatMessageService,
  IChatResponse,
  useCloseChatMutation,
  useEditMessageMutation,
  useGetChatByIdMutation,
  useNewMessageMutation,
  useOpenChatMutation,
  useSendMessageMutation,
} from 'store/hooks/services/chat';
import fileService from 'store/services/fileService';
import { getMessagePath } from './helpers';
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { log } from 'console';

interface IChat {
  applicationId?: number;
  visibility: boolean;
  onClose: () => void;
}

export const Chat: FC<IChat> = ({ applicationId, visibility, onClose }) => {
  // States
  const [details, setDetails] = useState<IChatResponse | null>(null);
  const [text, setText] = useState('');
  const [files, setFiles] = useState<string[]>([]);
  const [messageId, setMessageId] = useState<number>(-1);

  // Mutations
  const [openChat, { isLoading }] = useOpenChatMutation();
  const [sendMessage] = useSendMessageMutation();
  const [editMessage] = useEditMessageMutation();
  const [getChatDetails] = useGetChatByIdMutation();
  const [newMessage] = useNewMessageMutation();
  const [closeChat] = useCloseChatMutation();

  useEffect(() => {
    if (applicationId && visibility) {
      openChat({
        id: applicationId,
        docType: 25,
        clientChat: true,
      }).then((res: any) => {
        if ('data' in res) {
          setDetails(res.data);
        }
      });
    }
  }, [applicationId, visibility]);

  const handlers = {
    send() {
      const payload = {
        chatId: details?.id || 0,
        text,
        files,
      };

      const onSuccess = (res: any) => {
        setText('');
        setFiles([]);
        setMessageId(-1);
        if ('data' in res) {
          this.getNewMessage(res.data.messageId);
        }
      };

      if (messageId > -1) {
        editMessage({ ...payload, id: messageId }).then(onSuccess);
        return;
      }

      sendMessage(payload).then(onSuccess);
      this.refresh()
    },

    refresh: () => {
      getChatDetails(details?.id || 0).then((res: any) => {
        if ('data' in res) {
          setDetails(res.data);
        }
      });
    },

    close: () => {
      onClose();
    },

    uploadFile: (e: React.ChangeEvent<HTMLInputElement>) => {
      const uploadedFiles = e.target.files;

      if (!uploadedFiles?.length) return;

      const formData = new FormData();
      formData.append('file', uploadedFiles[0]);

      fileService.uploadFileV2(formData).then((res: any) => {
        setFiles([...files, res.data.url as string]);
      });
    },

    deleteFile(url: string) {
      setFiles(files.filter((item) => item != url));
    },

    getNewMessage: async (id: number) => {
      await newMessage({
        chatId: details?.id || 0,
        lastId: id,
      }).then((res: any) => {
        if ('data' in res) {
          const [groupIdx, msgIdx] = getMessagePath(
            details?.messages || [],
            id
          );

          if (groupIdx && msgIdx && details) {
            details.messages[groupIdx].messages[msgIdx] = res.data.messages[0];
          }
        }
      });
    },

    onEditMessage(item: IChatMessageService) {
      setText(item.text);
      setFiles(item.files || []);
      setMessageId(item.id || -1);
    },
  };

  useEffect(() => {
    let connection: null | HubConnection = null;
    if (details?.id) {
      try {
        connection = new HubConnectionBuilder()
          .withUrl((process.env.REACT_APP_CHAT_URL || '/') + 'EgovServiceRequest_' + details?.id, {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
          })
          .configureLogging(LogLevel.Information)
          .build();

        connection.on(
          'receivemessage',
          async (message: string, last: number, mesText: string) => {
            // setNewMessageId(last);
            // getDiscutions()
            handlers.refresh()
          }
        );

        connection.on(
          'starttypingmessage',
          (message: string, userId: number, userName: string) => {
            // setNewUserTyping({ id: userId, name: userName });
          }
        );

        connection.onclose((e) => {
          console.log(999, 'close');
        });

        connection.start();
      } catch (e) {
        console.log('ERROR', e);
        connection = null;
      }
    }

    return () => {
      if (connection) connection.stop();
    };
  }, [details?.id]);

  return (
    <Dialog
      className="tw-relative"
      PaperProps={{
        sx: {
          position: 'relative',
        },
      }}
      open={visibility}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      {isLoading && (
        <Box className="tw-flex tw-justify-center tw-items-center tw-z-50 tw-bg-overlay tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full">
          <CircularProgress />
        </Box>
      )}
      <ChatHeader title="Чат" refreshData={handlers.refresh} />
      <ChatMessages
        messages={details?.messages || []}
        onEditMessage={handlers.onEditMessage.bind(handlers)}
      />
      <ChatInput
        value={text}
        onChange={(value) => setText(value)}
        onSend={handlers.send.bind(handlers)}
        onFileUpload={handlers.uploadFile}
        onDeleteFile={handlers.deleteFile}
        files={files}
      />
    </Dialog>
  );
};
