import { CSSProperties } from 'react';

export const headerStyles: CSSProperties = {
  height: '9rem',
  padding: '0 5rem',
  display: 'flex',
  alignItems: 'center',
};

export const mainStyles: CSSProperties = {
  height: '100%',
  // backgroundImage: 'url(/assets/images/public-bg.png)',
  // backgroundColor: '#607D8B90',
  // backgroundImage: 'linear-gradient(135deg, #607D8B90 9%, #9599E2bb 85%)',
  background: 'linear-gradient(90deg, #F3E7E9 0%, #E3EEFF 99%, #E3EEFF 100%)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  flex: 1,
  position: 'relative',
};
