import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Logo from 'components/ui/Logo';
import { headerStyles } from 'layouts/public/style';
import { useNavigate } from 'react-router-dom';
import { useSession } from 'shared/hooks/useSession';

function NotFound() {
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const navigate = useNavigate();
  const { data, status } = useSession();

  return (
    <>
      <header
        style={isSmallDevice ? { padding: 15 } : headerStyles}
        className="header"
      >
        <Container maxWidth="xl">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Logo />
            {status === 'unauthorized' && (
              <Button
                onClick={() => navigate('/auth/login')}
                variant="contained"
                sx={{ minWidth: '67px' }}
                className="publicContentLayoutBtn"
              >
                Войти
              </Button>
            )}
          </Box>
        </Container>
      </header>
      <div className="tw-mt-[81px] tw-flex tw-justify-center tw-flex-col tw-items-center">
        <div className="tw-mb-6">
          {isSmallDevice ? (
            <img src={'/assets/images/mob.png'} />
          ) : (
            <img src={'/assets/images/desktop.png'} />
          )}
        </div>

        <Typography
          component={'p'}
          sx={{ fontSize: 14, mb: '12px', textAlign: 'center' }}
        >
          Запрашиваемая вами страница не найдена.
        </Typography>
        <Typography
          component={'p'}
          sx={{ fontSize: 14, fontWeight: 600, mb: 4, textAlign: 'center' }}
        >
          Ввернитесь назад или перейдите на главную страницу сайта
        </Typography>
        <Button
          variant="contained"
          sx={{ width: '208px', textTransform: 'none' }}
          onClick={() => {
            navigate('/');
          }}
        >
          На главную
        </Button>
      </div>
    </>
  );
}

export default NotFound;
