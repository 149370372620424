import { createSlice } from '@reduxjs/toolkit';
import initialState from './initials';
import { LS_ACCESS_TOKEN } from '../../../shared/constants/auth';
import { getCode, getUserProfile, login } from './actions';

const credentialsSlice = createSlice({
  name: 'credentials',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem(LS_ACCESS_TOKEN);
      state.user = null;
      state.isAuthorized = false;
    },

    setAuthStatus: (state) => {
      state.isAuthorized = true;
    },

    setRedirectedUser: (state, action) => {
      state.redirectedUser = action.payload;
    },

    clearRedirectedUser: (state) => {
      state.redirectedUser = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = { login: action.payload?.login || '' };
      state.isAuthorized = true;
      state.loading = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.error = action.error.message || '';
      state.loading = false;
    });

    // Get code
    builder.addCase(getCode.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCode.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getCode.rejected, (state, action) => {
      state.error = action.error.message || '';
      state.loading = false;
    });

    builder.addCase(getUserProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      if (!state.user) {
        state.user = { login: action.payload?.email || '' };
      }
      state.user.info = action.payload;
      state.loading = false;
    });
    builder.addCase(getUserProfile.rejected, (state, action) => {
      state.error = action.error.message || '';
      state.loading = false;
    });
  },
});

export const { setAuthStatus, setRedirectedUser, clearRedirectedUser } =
  credentialsSlice.actions;
export default credentialsSlice.reducer;
