import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { menuItem } from 'routes/private/profile/constants';

const SelectForMonile = () => {
    const [selecter, setSelecter] = useState('');

    const navigate = useNavigate();
    const { pathname } = useLocation();
  
    const clicker = (path: string) => {
        navigate(path);
      };
    
      const handleChangeSelect = (event: SelectChangeEvent) => {
        setSelecter(event.target.value as string);
      };
  return (
    <div className="tw-mb-8 lg:tw-hidden tw-pt-6">
        <FormControl fullWidth>
          {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={handleChangeSelect}
            value={pathname}
            sx={{ background: '#E3E9F5', color: '#607D8B' }}
          >
            {menuItem.map(({ id, title, path, pathName }) => {
              return (
                <MenuItem
                  key={id}
                  value={pathName}
                  onClick={() => clicker(pathName)}
                >
                  {title}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>  )
}

export default SelectForMonile