import { createAsyncThunk } from '@reduxjs/toolkit';
import { ActionsType } from '../../helpers/ActionsType';
import authApi from './service';
import {
  ICheckCode,
  IGetCodeInResetPassword,
  IGetCodeReq,
  ILoginReq,
  IRefreshTokenReq,
  IResetPassword,
} from './models';
import {
  clearTokenFromStorage,
  setTokenToStorage,
} from '../../../shared/utils/getTokenFromStorage';
import { PayloadWithCallback } from '../../../shared/utils/types';

export const login = createAsyncThunk(
  ActionsType.AUTH_LOGIN,
  async (payload: PayloadWithCallback<ILoginReq>, { rejectWithValue }) => {
    try {
      const { data, onSuccess, onError } = payload;
      const response = await authApi.login(data);

      const accessToken = response.data.accessToken;

      if (accessToken && response.status === 200) {
        setTokenToStorage(response.data);
        if (onSuccess) onSuccess();
        return {
          login: data.userLogin,
        };
      } else {
        rejectWithValue('Error Auth Token not defined');
        if (onError) onError();
      }
    } catch (err) {
      console.error('[Auth Api]: ', err);
      rejectWithValue('Error Auth Token not defined');
    }
  }
);

export const logout = createAsyncThunk(
  ActionsType.AUTH_LOGOUT,
  async (onSuccess: () => void, { rejectWithValue }) => {
    try {
      const response = await authApi.logout();
      if (response.status === 200) {
        clearTokenFromStorage();
        onSuccess();
      }
    } catch (err: any) {
      rejectWithValue('Logout error');
    }
  }
);

export const getCode = createAsyncThunk(
  ActionsType.AUTH_GET_CODE,
  async (payload: PayloadWithCallback<IGetCodeReq>, { rejectWithValue }) => {
    try {
      const { data, onSuccess } = payload;
      const response = await authApi.getCode(data);

      if (response.status === 200 && onSuccess) onSuccess();
    } catch (err: any) {
      rejectWithValue('Logout error');
    }
  }
);

export const checkCode = createAsyncThunk(
  ActionsType.Auth_CHECK_CODE,
  async (payload: PayloadWithCallback<ICheckCode>, { rejectWithValue }) => {
    try {
      const { data, onSuccess } = payload;
      const response = await authApi.checkCode(data);

      if (response.status === 200 && onSuccess) onSuccess();
    } catch (err: any) {
      rejectWithValue('Logout error');
    }
  }
);

export const getCodeInResetPassword = createAsyncThunk(
  ActionsType.AUTH_CODE_RESET_PASSWORD,
  async (
    payload: PayloadWithCallback<IGetCodeInResetPassword>,
    { rejectWithValue }
  ) => {
    try {
      const { data, onSuccess } = payload;
      const response = await authApi.getCodeInResetPassword(data);

      if (response.status === 200 && onSuccess) onSuccess();
    } catch (err: any) {
      rejectWithValue('Logout error');
    }
  }
);

export const resetPassword = createAsyncThunk(
  ActionsType.AUTH_CODE_RESET_PASSWORD,
  async (payload: PayloadWithCallback<IResetPassword>, { rejectWithValue }) => {
    try {
      const { data, onSuccess } = payload;
      const response = await authApi.resetPassword(data);

      if (response.status === 200 && onSuccess) onSuccess();
    } catch (err: any) {
      rejectWithValue('Logout error');
    }
  }
);

export const refreshToken = createAsyncThunk(
  ActionsType.AUTH_REFRESH_TOKEN,
  async (payload: IRefreshTokenReq, { rejectWithValue }) => {
    try {
      const response = await authApi.refreshToken(payload);

      if (response.data) {
      }

      return response.data;
    } catch (err: any) {
      rejectWithValue('Refresh token doesnt work!');
    }
  }
);

export const getUserProfile = createAsyncThunk(
  ActionsType.AUTH_REFRESH_TOKEN,
  async (not: any, { rejectWithValue }) => {
    try {
      const response = await authApi.getUserProfileDetail();

      return response.data;
    } catch (err: any) {
      rejectWithValue('Refresh token doesnt work!');
    }
  }
);
