import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import CachedIcon from '@mui/icons-material/Cached';

interface IChatHeader {
  title: string;
  refreshData: () => void;
}
export const ChatHeader: FC<IChatHeader> = ({ title, refreshData }) => {
  return (
    <Box className="tw-bg-primary tw-px-3 tw-py-5 tw-text-center tw-relative">
      <Typography color="#fff" variant="h6">
        Чат
      </Typography>
      <CachedIcon
        onClick={refreshData}
        fontSize="large"
        className="tw-absolute tw-left-12 tw-top-6 tw-text-white tw-cursor-pointer"
      />
    </Box>
  );
};
