import * as Yup from 'yup';
import { IChangePasswordRequest } from '../../../../../../store/hooks/users';
import { stringSchema } from '../../../../../../shared/utils/yup';

export interface IInitialValues extends IChangePasswordRequest {
  newPasswordConfirmation: string;
}

export type KeyofInitialValues = keyof IInitialValues;

export const initialValues: IInitialValues = {
  oldPassWord: '',
  newPassWord: '',
  newPasswordConfirmation: '',
};

export const validationSchema = Yup.object({
  oldPassWord: stringSchema(5),
  newPassWord: stringSchema(5),
  newPasswordConfirmation: Yup.mixed()
    .required('Обязательно')
    .when('newPassWord', (pass: any, schema: any) => {
      if (pass) {
        return schema.test({
          test: (value: string) => {
            return value === pass[0];
          },
          message: 'Пароли не совпадают',
        });
      }

      return schema;
    }),
});
