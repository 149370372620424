import { Box, Typography, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTokenFromStorage } from 'shared/utils/getTokenFromStorage';

const Logo: FC = () => {
  const navigate = useNavigate();
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');



  return (
    <Box
      sx={{
        display: 'flex',
        gap: getTokenFromStorage() ? 1 : 2,
        alignItems: 'center',
        maxWidth: !isSmallDevice ? '600px' : '360px',
        cursor: 'pointer',
        position: 'relative',
      }}
      onClick={() => navigate('/')}
    >

      {isSmallDevice ?
        <Box style={{ width: '50px', position: 'relative' }}>
          <img
            style={{ maxWidth: '100%', zIndex: 10, position: 'relative' }}
            src="/assets/images/gerb.png"
            alt="Логотип"
          />
        </Box> : <Box style={{ width: '70px', position: 'relative' }}>
          <img
            style={{ minWidth: '50px', zIndex: 10, position: 'relative' }}
            src="/assets/images/gerb.png"
            alt="Логотип"
          />
        </Box>}
      {isSmallDevice ? <Typography
        sx={{
          fontSize: '12px',
          lineHeight: '16px',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          fontWeight: 700
        }}
      >
        <span className='tw-uppercase tw-font-semibold'>Портал государственных услуг</span> <br />

        <span className='tw-caption-bottom'>
          Министерство Финансов
        </span> <br />
        <span className='tw-caption-bottom'>Республики Таджикистан</span>
      </Typography> : <Typography
        sx={{
          fontSize: '12px',
          lineHeight: '24px',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
        }}
      >
        <span className='tw-uppercase tw-font-semibold'>Портал государственных услуг</span> <br />

        <span className='tw-caption-bottom'>
          Министерство Финансов
        </span> <br />
        <span className='tw-caption-bottom'>Республики Таджикистан</span>
      </Typography>
      }
    </Box>
  );
};

export default Logo;
