import { ICredentialsState } from './models';

const initialState: ICredentialsState = {
  user: null,
  redirectedUser: null,
  isAuthorized: false,
  loading: false,
  error: '',
};

export default initialState;
