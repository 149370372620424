import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { downloadFileByUrl, getParamFromUrl } from 'shared/utils/functions';
import { IChatMessageService } from 'store/hooks/services/chat';

// Item
interface IChatMessageItemFile {
  url: string;
  fileName: string;
}
export const ChatMessageItemFile: FC<IChatMessageItemFile> = ({
  fileName,
  url,
}) => {
  return (
    <Box
      onClick={() => downloadFileByUrl(url)}
      className="tw-flex tw-items-center tw-cursor-pointer tw-duration-200 hover:tw-opacity-50 tw-flex-gap-2 tw-p-2 tw-bg-gray-200 tw-rounded-md"
    >
      <InsertDriveFileIcon color="primary" />
      <Typography className="tw-max-w-[100px] tw-truncate" variant="body2">
        {fileName}
      </Typography>
    </Box>
  );
};

// List
interface IChatMessageItemFiles {
  files: IChatMessageService['files'];
}
export const ChatMessageItemFiles: FC<IChatMessageItemFiles> = ({
  files = [],
}) => {
  return (
    <Box className="tw-flex tw-flex-wrap tw-gap-3 tw-border-t tw-border-gray-500 tw-mt-2 tw-pt-2">
      {files.map((item) => {
        return (
          <ChatMessageItemFile
            key={item}
            url={item}
            fileName={getParamFromUrl(item, 'fileName') || ''}
          />
        );
      })}
    </Box>
  );
};
