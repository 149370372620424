import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  Box,
  Chip,
  CircularProgress,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { SectionBox } from 'components/forms/SectionForm';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { downloadFileByUrl } from 'shared/utils/functions';
import {
  IServiceDetailFile,
  useGetServiceDetailQuery,
} from 'store/hooks/services';
import fileService from 'store/services/fileService';
// import { titles } from './constants';
import TableCellToRow from 'components/ui/TableCellToRow/TableCellToRow';
import TableCellToRowKey from 'components/ui/TableCellToRow/TableCellToRowKey/TableCellToRowKey';
import TableCellToRowValue from 'components/ui/TableCellToRow/TableCellToRowValue/TableCellToRowValue';
import { titles, typeDoc } from 'routes/private/services/application/create/components/forms/constants';
import { useUserProfileGetQuery } from 'store/hooks/userprofile';
import { DocStatus } from '../../helpers/constants';

const CHIP_MAX_WIDTH = 500;
const CHIP_ICON_WIDTH = 30;

const EllipsisText = (props: any) => {
  const { children } = props;

  return (
    <div
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: CHIP_MAX_WIDTH - CHIP_ICON_WIDTH,
      }}
    >
      {children}
    </div>
  );
};

interface IServiceFiles {
  formik: any;
}

function createData(
  name: string,
  template: string,
  createdAt: string,
  createdBy: string
) {
  return { id: Math.random(), name, template, createdAt, createdBy };
}

export const UserFiles: FC<IServiceFiles> = ({ formik }) => {
  const { id } = useParams();

  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');

  const { values } = formik;

  const handleUploadFile = async (idx: number, event: any) => {
    const file = event.target.files;
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file[0]);

    formik.setFieldValue(`files.${idx}.loading`, true);
    formik.setFieldValue(
      `files.${idx}.fileName`,
      file[0].name || 'something.pdf'
    );

    await fileService.uploadFileV2(formData).then((e) => {
      formik.setFieldValue(`files.${idx}.loading`, false);
      formik.setFieldValue(`files.${idx}.filePath`, e.data.url);
      formik.setFieldValue(`files.${idx}.haveFile`, true);
    });
  };

  const renderInput = (item: IServiceDetailFile, idx: number) => {
    if (item.filePath) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <InsertDriveFileIcon color="primary" fontSize="large" />
        </Box>
      );
    }

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 0.5,
        }}
      >
        <Box>
          <input
            style={{ display: 'none' }}
            type="file"
            id={String(item.id)}
            onChange={(event) => handleUploadFile(idx, event)}
          />
          <label htmlFor={String(item.id)}>
            <AttachFileIcon
              sx={{ cursor: 'pointer' }}
              color="primary"
              fontSize="large"
            />
          </label>
        </Box>
        {item.loading && (
          <Box sx={{ pt: '4px' }}>
            <CircularProgress size={20} />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      {isSmallDevice ? (
        values?.files?.length > 0 && (
          <>
            {values.files.map((row: any, idx: number) => {
              return (
                <TableCellToRow key={row.id}>
                  <TableCellToRowKey>Название</TableCellToRowKey>
                  <TableCellToRowValue>
                    <Box
                      onClick={() => {
                        window.open(`${row.docPath}`);
                      }}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        cursor: 'pointer',
                        maxWidth: '250px',
                        overflow: 'hidden',
                        whiteSpace: 'pre-line',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {row.fileName || ''}
                    </Box>
                  </TableCellToRowValue>
                  <TableCellToRowKey>Тип документа</TableCellToRowKey>
                  <TableCellToRowValue>{row.name}</TableCellToRowValue>
                  {/* <TableCellToRowKey>Дата создания</TableCellToRowKey> */}
                  {/* <TableCellToRowValue>{row.createAt}</TableCellToRowValue> */}
                  {/* <TableCellToRowKey>Создал</TableCellToRowKey> */}
                  {/* <TableCellToRowValue>
                    {details?.organisation.value}
                  </TableCellToRowValue> */}
                </TableCellToRow>
              );
            })}
          </>
        )
      ) : (
        <Box>
          <TableContainer component={SectionBox}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {titles.slice(1).map((item) => (
                    <TableCell key={item} align="left">
                      <Typography fontSize={14} color="primary">
                        {item}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {values.files.map((row: any, idx: number) => (
                  <TableRow
                    key={row.id}
                    sx={{ '& td, & th': { border: 0, fontSize: 14 } }}
                  >
                    {/* <TableCell
                      component="th"
                      scope="row"
                      align="left"
                    ></TableCell> */}
                    <TableCell component="th" scope="row" align="left">
                      <Box
                        onClick={() => {
                          console.log('ssss', row);
                          window.open(`${row.docPath}`);
                        }}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          cursor: 'pointer',
                          maxWidth: '250px',
                          overflow: 'hidden',
                          whiteSpace: 'pre-line',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {row.docName || ''}
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Chip
                        sx={{ minWidth: '120px', fontSize: '14px' }}
                        label={
                          <EllipsisText>{
                            typeDoc[String(row.docType)]}
                          </EllipsisText>
                        }
                      />
                    </TableCell>
                    {/* <TableCell align="left">
                      {row.createAt.slice(0, 10)}
                    </TableCell> */}
                    {/* <TableCell align="left">
                      {details?.organisation.value}
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};
