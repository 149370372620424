import { Box, Paper, useMediaQuery } from '@mui/material';
// import UserForm from './forms/UserForm';
import CustomButton from '../../../components/ui/CustomButton';
import { actionContainerSx, containerSx, paperSx } from './styles';
// import { IInitialValues, initialValues, validationSchema } from './schema';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IGetCodeInResetPassword } from 'store/slices/credentials/models';
import { useDispatch } from '../../../store';
import {
  getCodeInResetPassword,
  resetPassword,
} from '../../../store/slices/credentials/actions';
import { sendBtnSx } from '../login/style';
import UserForm from './forms/UserForm';
import { initialUserValues, KeyOfInitials, validationSchema } from './schema';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import TabsSegment, { ITabsSegmentItem } from 'components/ui/CustomSegment';
import { tabsOptions } from '../login/helpers/constants';
import { useState } from 'react';

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const { loading } = useSelector((state) => state.credentials);

  const [certificateRequired, setCertificateRequired] = useState(false);
  const [formType, setFormType] = useState<ITabsSegmentItem | null>(
    tabsOptions[0]
  );

  const tabsChange = (value: ITabsSegmentItem) => {
    setFormType(value);
    setCertificateRequired(value.value === 'entity');
  };


  const formik = useFormik({
    initialValues: initialUserValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit() {},
  });

  const { values, errors, touched, setFieldValue } = formik;

  const onSendCode = () => {
    if (loading) return;
    if (values.codeSended && values.codeChecked) {
      // login
      const data: any = {
        login: values.login,
        newPassWord: values.newPassWord,
        passWordAgain: values.passWordAgain,
        UserType: formType?.code || 1
      };

      dispatch(
        resetPassword({
          data,
          onSuccess: () => {
            navigate('/auth/login');
            toast.success(`Пароль успешно изменен`);
          },
        })
      );
    } else if (!values.codeSended) {
      
      const data: IGetCodeInResetPassword = {
        login: values.login,
        UserType: formType?.code || 1
      };

      dispatch(
        getCodeInResetPassword({
          data,
          onSuccess: () => {
            setFieldValue('codeSended', true);
            toast.success(`Код успешно отправлен на номер ${values.login}`);
          },
        })
      );
    }
  };

  console.log('ERrr: ', errors, touched, formik.isValid);

  const isSubmitDisabled = () => {
    const isAllFieldsTouched = touched.login;

    console.log(
      'hhhhh',
      Boolean(values.codeSended && !values.codeChecked),
      !formik.isValid,
      !isAllFieldsTouched
    );

    return (
      loading ||
      Boolean(values.codeSended && !values.codeChecked) ||
      Boolean(!formik.isValid || !isAllFieldsTouched)
    );
  };

  return (
    <Box sx={containerSx}>
      <Box
        sx={{
          width: isSmallDevice ? '100%' : '35%',
          marginX: isSmallDevice ? 3 : 0,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 5 }}>
          <Box sx={{ width: isSmallDevice ? '100%' : '60%' }}>
            <TabsSegment
              items={tabsOptions}
              value={formType}
              onChange={tabsChange}
            />
          </Box>
        </Box>
        <Paper sx={paperSx(isSmallDevice)}>
          <CustomButton
            size="small"
            onClick={() => navigate('/auth/login')}
            variant="contained"
            sx={{
              width: isSmallDevice ? '230px' : '280px',
              height: isSmallDevice ? '48px' : '60px',
              fontSize: isSmallDevice ? '14px' : '18px',
              position: 'absolute',
              top: '0',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            Вход
          </CustomButton>
          <UserForm formik={formik} />
          <div className="tw-text-center tw-mt-4">
            <Link
              to="/auth/registration"
              className="tw-font-bold tw-text-primary"
              style={{ fontSize: 16 }}
            >
              Зарегистрироваться{' '}
            </Link>
          </div>
          <Box sx={actionContainerSx}>
            <CustomButton
              disabled={isSubmitDisabled()}
              onClick={onSendCode}
              sx={sendBtnSx(isSmallDevice)}
              variant="contained"
              color="primary"
              loading={loading}
            >
              {values.codeSended && values.codeChecked
                ? 'Cбросить пароль'
                : 'Отправить код'}
            </CustomButton>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default ForgetPassword;
