import { ChangeEvent } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import {
  boxSx,
  cardLogoBoxSx,
  cardTextBoxSx,
  modalCardSx,
  modalContainerCardSx,
  modalGroupCardSx,
} from '../../styles';
import { allCards, banks } from './constants';
import { useFormik } from 'formik';
import { initialValues, validationSchema } from './schema';

import { toastPromise } from '../../../../../../shared/utils/toastPromise';
import { getFieldErrors } from '../../../../../../shared/utils/yup';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useCreateCardsMutation } from 'store/hooks/userprofile';

interface IAddCard {
  refetch: () => void;
  onClose: () => void;
  changeContentModal: boolean;
  changeModalContentHandler: () => void;
}

const AddCard = ({
  refetch,
  onClose,
  changeContentModal,
  changeModalContentHandler,
}: IAddCard) => {
  const [setCreateCards] = useCreateCardsMutation();
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');

  const [visibilityCardNumber, setVisibilityCardNumber] = useState(false);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit(values) {
      const payload = {
        userId: Math.floor(Math.random()),
        cvc: values.cvc.toString(),
        cartNumber: values.cardNumber.toString(),
        bankName: values.bankName,
        nameAndSurname: values.name.toString(),
        expireDate: values.year.toString(),
        createAt: '2024-01-16T11:08:19.065Z',
        updateAt: '2024-01-16T11:08:19.065Z',
      };

      const promise = setCreateCards(payload);

      toastPromise(
        promise,
        {
          pending: 'Добавление карты.',
          success: 'Карта успешно добавлена!',
          error: 'Проверьте введенные данные!',
        },
        {
          then: () => {
            refetch();
            onClose();
          },
        }
      );
    },
  });
  const { values, setFieldValue, handleBlur } = formik;

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const latinLettersRegex = /^[a-zA-Z\s]{0,}$/;

    if (!latinLettersRegex.test(value)) return;
    setFieldValue('name', value);
  };

  const formatCardNumber = (value: string) => {
    let formatted = '';
    for (let i = 0; i < value.length; i += 4) {
      if (i > 0) formatted += ' ';
      const sliced = value.slice(i, i + 4);
      formatted += visibilityCardNumber
        ? sliced
        : Array.from({ length: sliced.length }, () => '✳').join('');
    }

    return formatted;
  };

  const handleCardChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (value.length > 19) {
      return;
    }

    if (!visibilityCardNumber) {
      const valueLength = value.split('').filter((item) => item !== ' ').length;

      // const diff = e.target.value.length - valueLength;
      // let selectionStart = e.target.selectionStart;
      // if (selectionStart !== null) {
      //   selectionStart--;
      //   console.log('SelectionStart: ', selectionStart, e.target.value.length);
      //   value =
      //     values.cardNumber.slice(0, selectionStart - diff) +
      //     e.target.value[selectionStart] +
      //     values.cardNumber.slice(selectionStart - diff);
      // } else {
      //   value = values.cardNumber + e.target.value[value.length - 1];
      // }
      value = values.cardNumber + e.target.value[value.length - 1];
      value = value.slice(0, valueLength);
    }
    setFieldValue(
      'cardNumber',
      value
        .split('')
        .filter((item) => item >= '0' && item <= '9')
        .join('')
    );
  };

  const handleCvcChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const containsOnlyDigits = /^[0-9]{0,}$/.test(value);
    if (!containsOnlyDigits || value.length > 3) return;
    setFieldValue('cvc', value);
  };

  const formatYearNumber = (value: string) => {
    let formatted = '';
    for (let i = 0; i < value.length; i += 2) {
      if (i > 0) formatted += '/';
      const sliced = value.slice(i, i + 2);
      console.log(formatted);
      formatted += sliced;
    }
    return formatted;
  };

  const handleYearChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length > 5) return;
    console.log(value);

    setFieldValue(
      'year',
      value
        .split('')
        .filter((item) => item >= '0' && item <= '9')
        .join('')
    );
  };

  return (
    <Box >
      {changeContentModal ? (
        <Box> 
          <div className="tw-grid tw-grid-cols-3  tw-gap-2 tw-mb-10">
            <Box className="tw-col-span-3" sx={{ height: '62px' }}>
              <TextField
                fullWidth
                name="cardNumber"
                label="Hомер карты"
                size="small"
                value={formatCardNumber(values.cardNumber)}
                type={'text'}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() =>
                        setVisibilityCardNumber(!visibilityCardNumber)
                      }
                    >
                      {visibilityCardNumber ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  ),
                }}
                onBlur={handleBlur}
                {...getFieldErrors(formik, 'cardNumber')}
                onChange={handleCardChange}
              />
            </Box>
            <Box className="tw-col-span-3" sx={{ height: '62px' }}>
              <TextField
                label="Имя и фамилия"
                size="small"
                type="text"
                onChange={handleNameChange}
                value={values.name}
                {...getFieldErrors(formik, 'name')}
                name="name"
                onBlur={handleBlur}
                fullWidth
              />
            </Box>
            <Box className="tw-col-span-3" sx={{ height: '62px' }}>
              <Autocomplete
                size="small"
                options={banks}
                noOptionsText="Нет данных"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Банк"
                    onBlur={handleBlur}
                    name="bankName"
                    {...getFieldErrors(formik, 'bankName')}
                  />
                )}
                onChange={(e, value) => setFieldValue('bankName', value)}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection : isSmallDevice ? 'column ': '',
                gap: '20px',
                height:  isSmallDevice ? '92px' : '62px',
                marginRight: '20px',
              }}
              className="tw-col-span-2"
            >
              <TextField
                label="ММ/ГГ"
                size="small"
                onChange={handleYearChange}
                value={formatYearNumber(values.year)}
                {...getFieldErrors(formik, 'year')}
                name="year"
                onBlur={handleBlur}
                fullWidth
              />
              <TextField
                label="CVC"
                type="text"
                size="small"
                onChange={handleCvcChange}
                value={values.cvc}
                {...getFieldErrors(formik, 'cvc')}
                name="cvc"
                onBlur={handleBlur}
                fullWidth
              />
            </Box>
            <img src="/assets/images/cvc-example.png" alt="" />
          </div>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' , gap: isSmallDevice ? '10px' : '0'    }}>
            <Button
              onClick={onClose}
              variant="outlined"
              sx={{ borderRadius: '8px' }}
            >
              Отмена
            </Button>
            <Button
              sx={{ borderRadius: '8px' }}
              variant="contained"
              onClick={() => {
                formik.submitForm();
              }}
            >
              Добавить
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2.4rem',
          width: isSmallDevice ? '300px' : '400px',
        }}>
          <Typography fontSize={20} fontWeight={500}>
            Выберите тип карты
          </Typography>
          {allCards.map((item) => (
            <Box sx={{
              marginLeft : isSmallDevice ? '-25px' : 0,
              marginRight : isSmallDevice ? '10px' : 0 ,
              padding: '2.4rem',
              border: '1px solid rgba(0, 0, 0, 0.32)',
              borderRadius: '1.6rem',
            }} key={item.id}>
              <Box sx={modalContainerCardSx}>
                <Box sx={cardLogoBoxSx}>
                  <img src={item.logo} alt="" />
                </Box>
                <Box sx={cardTextBoxSx}>
                  <Typography fontSize={16} fontWeight={400}>
                    {item.title}
                  </Typography>
                  <Button
                    onClick={changeModalContentHandler}
                    sx={{ alignSelf: 'flex-end' }}
                  >
                    Добавить
                  </Button>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default AddCard;
