import { IUserCreate } from '../../../store/hooks/users';
import * as Yup from 'yup';
import {
  innSchema,
  phoneSchema,
  stringSchema,
} from '../../../shared/utils/yup';
import { phoneRegexp, phoneRegexpCompleted } from '../login/helpers/constants';

export interface IInitialValues {
  login: string;
  newPassWord: string;
  passWordAgain: string;
  code: string;
  type: string;
  codeSended: boolean;
  codeChecked: boolean;
}

export type KeyOfInitials = keyof IInitialValues;

export const initialUserValues: IInitialValues = {
  login: '',
  newPassWord: '',
  passWordAgain: '',
  code: '',
  type: '',
  codeSended: false,
  codeChecked: false,
};

export const validationSchema = Yup.object({
  login: Yup.string()
    .required('Обязательно')
    .test({
      test: (value) => phoneRegexpCompleted?.test(value),
      message: 'Неправильный формат логина: 992XXXXXXXXX',
    }),
  newPassWord: stringSchema(5, 20, false).when('codeChecked', {
    is: true,
    then: (schema) => schema.required('Обязательно'),
  }),
  passWordAgain: stringSchema(5, 20, false).when('codeChecked', {
    is: true,
    then: (schema) => schema.required('Обязательно'),
  }),
});
