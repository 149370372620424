import { Box, Divider, Typography, useTheme } from '@mui/material';
import { FC } from 'react';

interface IServiceCategory {
  iconUrl: string;
  name: string;
  active?: boolean;
  onClick?: () => void;
}

export const ServiceCategory: FC<IServiceCategory> = ({
  iconUrl,
  name,
  active,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <Box onClick={onClick} sx={{ p: '4px', height: '100%' }}>
      <Box
        sx={{
          width: '192px',
          height: '100%',
          borderRadius: '16px',
          boxShadow: '0px 6px 12px 0px rgba(28, 39, 49, 0.05)',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          boxSizing: 'border-box',
          transition: 'background .3s ease',
          background: active ? theme.palette.primary.main : '#fff',
          color: active ? '#fff' : '',
          cursor: 'pointer',
          '&:hover': {
            background: theme.palette.primary.main,
            color: '#fff',
          },
        }}
      >
        <Box sx={{ px: '24px', pt: '24px', height: '56px', mb: 2 }}>
          <img
            style={{
              width: '56px',
              height: '56px',
              display: 'block',
              margin: '0 auto',
            }}
            src={iconUrl || '/assets/images/gerb.png'}
          />
        </Box>
        <Divider color="#fff" />
        <Box sx={{ px: '24px', pb: '24px' }}>
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '17px',
              fontWeight: 500,
              textAlign: 'center',
              transition: 'color .3s ease',
            }}
          >
            {name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
