import { createTheme } from '@mui/material';

export const theme = createTheme({
  shape: {
    borderRadius: 5,
  },
  palette: {
    primary: {
      main: '#607D8B',
    },
  },

  typography: {
    fontSize: 16,

    h6: {
      color: '#222',
      fontSize: '1.6rem',
      fontWeight: 600,
    },
  },
  components: {
    MuiTablePagination: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
          background: '#FEFBFF',
          borderRadius: '26px',
          '.MuiOutlinedInput-notchedOutline': {
            border: '2px solid #9E9E9E57',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#9E9E9E97',
          },
          // MUI DISABLED STYLES
          '&.Mui-disabled': {
            '.MuiOutlinedInput-notchedOutline': {
              border: '2px solid #9E9E9E47',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#9E9E9E47',
            },
          },
        },
      },
    },
    //AUTOCOMPLETE LABEL
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
          padding: '0 8px',
          borderRadius: '10px',
          top: '3px',
          '&.MuiFormLabel-filled': {
            transform: 'translate(9px, -4px) scale(0.75) !important',
          },
          '&.Mui-focused': {
            transform: 'translate(9px, -4px) scale(0.75)',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // minHeight: '44px',
          // maxHeight: 'auto',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '26px',
          fontSize: '1.6rem',
          boxSizing: 'border-box',

          '&.Mui-disabled': {
            color: '#fff',
            backgroundColor: '#8cb1c1',
          },
        },
        sizeSmall: {
          lineHeight: 'normal',
          padding: '7px 9px',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          height: '46px',
        },
        // MUI AUTOCOMPLETE POPUP ARROW STYLES
        popupIndicator: {
          svg: {
            display: 'none',
          },
          '&::after': {
            content: '""',
            width: '9px',
            height: '9px',
            margin: '8px',
            borderBottom: '2px solid #009688',
            borderLeft: '2px solid #009688',
            transform: 'rotate(-45deg) translate(2px, -2px)',
          },
        },
        // MUI AUTOCOMPLETE POPPER STYLES
        paper: {
          marginLeft: -8,
          marginRight: -8,
          borderRadius: 26,
          border: '8px solid #F1F3FD',
          background:
            'linear-gradient(0deg, rgba(96, 125, 139, 0.16) 0%, rgba(96, 125, 139, 0.16) 100%), #FEFBFF',
          boxShadow:
            '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)',
          // MUI AUTOCOMPLETE POPPER SCROLLBAR STYLES
          '& ::-webkit-scrollbar': {
            width: 4,
          },

          '& ::-webkit-scrollbar-track': {
            webkitBoxShadow: '5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset',
            backgroundColor: '#f9f9fd',
          },

          '& ::-webkit-scrollbar-thumb': {
            backgroundColor: '#607D8B',
          },
        },
        option: {
          '&:hover': {
            background: '#00968824 !important',
          },
        },
      },
    },
  },
});
