import axios from 'axios';
import { ApiRoutes } from '../../shared/constants/ApiRoutes';

const baseDomain = process.env.REACT_APP_BASE_API_FILE_URL;

class FileService {
  uploadFileV2(req: FormData) {
    return axios.post(baseDomain + ApiRoutes.uploadFileV2, req);
  }
}

export default new FileService();
