import { Box, IconButton, TextField } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { styles } from './styles';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ChatFileAttacher } from './ChatFileAttacher';
import { ChatSendFileItem } from './ChatSendFileItem';
import { getParamFromUrl } from 'shared/utils/functions';

interface IChatInput {
  value: string;
  files: string[];
  onChange: (value: string) => void;
  onFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSend: () => void;
  onDeleteFile: (url: string) => void;
}

export const ChatInput: FC<IChatInput> = ({
  value,
  files,
  onChange,
  onSend,
  onFileUpload,
  onDeleteFile,
}) => {

  return (
    <form onSubmit={(e) => {
      e.preventDefault()
      onSend()
    }}>

      <Box className="tw-px-12 tw-py-6 tw-border-t tw-bg-gray-50 tw-relative">
        <Box className="tw-flex tw-gap-5 tw-items-center">
          <TextField
            sx={styles.input}
            multiline
            maxRows={3}
            fullWidth
            placeholder="Текст..."
            value={value}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChange(e.target.value)
            }
            onKeyPress={event => {
              if (event.key === 'Enter') {
                onSend()
                onChange('')
              }
            }}
          />
          <Box className="tw-flex tw-gap-3 tw-items-center">
            <ChatFileAttacher onChange={onFileUpload} />
            <IconButton type='submit' sx={{ cursor: 'pointer' }} disabled={value.trim().length === 0 && files.length === 0}   >
              <SendIcon color="primary" fontSize="large" />
            </IconButton>
          </Box>
        </Box>
        {files.length > 0 && (
          <Box className="tw-flex tw-flex-wrap tw-gap-3 tw-border-t tw-mt-4 tw-pt-3">
            {files.map((item) => {
              return (
                <ChatSendFileItem
                  fileName={getParamFromUrl(item, 'fileName') || ''}
                  onDelete={() => onDeleteFile(item)}
                />
              );
            })}
          </Box>
        )}
      </Box>
    </form>
  );
};
