import { FC } from 'react';
import { FormGroup, TextField, useMediaQuery } from '@mui/material';
import { FormikProps } from 'formik';
import { IInitialValues } from '../../schema';

interface IServiceInfoForm {
  disabled?: boolean;
  formik: FormikProps<IInitialValues>;
}

export const ServiceInfoForm: FC<IServiceInfoForm> = ({ disabled, formik }) => {
  const defaultCallback = () => { };
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');

  return (
    <div className="tw-p-4 tw-pb-0">
      <FormGroup className="tw-mb-4">
        <div className="lg:tw-grid tw-grid-cols-2 tw-gap-4">
          <TextField
            sx={{ marginTop: isSmallDevice ? '15px' : '0', width: isSmallDevice ? '100%' : '100%', py: 1 }}
            name="fio"
            label="ФИО"
            value={formik.values.fio}
            size="small"
            onChange={defaultCallback}
            required
          />
          <TextField
            sx={{ marginTop: isSmallDevice ? '15px' : '0', width: isSmallDevice ? '100%' : '100%', py: 1 }}
            name="passportSeries"
            label="Номер паспорта"
            value={formik.values.passport}
            size="small"
            onChange={defaultCallback}
            required
          />
          <TextField
            sx={{ marginTop: isSmallDevice ? '15px' : '0', width: isSmallDevice ? '100%' : '100%', py: 1 }}
            name="phone"
            label="Телефон"
            value={formik.values.phone}
            size="small"
            onChange={defaultCallback}
            required
          />
          <TextField
            sx={{ marginTop: isSmallDevice ? '15px' : '0', width: isSmallDevice ? '100%' : '100%', py: 1 }}
            name="email"
            label="Электронная почта"
            value={formik.values.email}
            size="small"
            onChange={defaultCallback}
            required
          />
        </div>
      </FormGroup>
    </div>
  );
};
