import { FC, ReactNode, useEffect, useState } from 'react';

interface IScriptLoader {
  src: string;
  children?: ReactNode;
  onLoadScript?: () => void;
}

const loadedScripts: Record<string, boolean> = {};

const ScriptLoader: FC<IScriptLoader> = ({ src, children, onLoadScript }) => {
  const [scriptLoaded, setScriptLoaded] = useState(loadedScripts[src]);

  useEffect(() => {
    if (src) {
      const script = document.querySelector(`script[src="${src}"]`);

      if (!script) {
        const newScript = document.createElement('script');
        newScript.src = src;

        newScript.onload = () => {
          setScriptLoaded(true);
          if (onLoadScript) onLoadScript();
          loadedScripts[src] = true;
        };

        newScript.onerror = () => {
          console.log(`Произошло ошибка подгрузки скрипта ${src}`);
        };
        document.body.append(newScript);
      } else if (loadedScripts[src]) {
        setScriptLoaded(true);
        if (onLoadScript) onLoadScript();
      }
    }
  }, [src]);

  // if (!scriptLoaded) return <></>;

  return <>{children}</>;
};

export default ScriptLoader;
