import { Typography } from '@mui/material'

interface Props {
    children: React.ReactNode
}

function TableCellToRowKey(props: Props) {
    return (
        <Typography variant="h5" sx={{ fontSize: '16px', fontWeight: '500', mb: '4px' }}>
            {props.children}:
        </Typography>
    )
}

export default TableCellToRowKey