import { useSelector } from '../../store';

export const useSession = () => {
  const { isAuthorized, rutoken, user } = useSelector(
    (state) => state.credentials
  );

  return {
    data: { rutoken },
    status: isAuthorized ? 'authenticated' : 'unauthorized',
    user,
  };
};
