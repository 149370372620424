import { SxProps } from '@mui/material';
import { CSSProperties } from 'react';

export const textFieldSx: SxProps = {
  borderBottom: 'none',
  borderRadius: 5,
  height: '50px',
  WebkitBoxShadow: '0 0 0 1000px white inset',
  overflow: 'hidden',
  '& .MuiFormLabel-root': {
    padding: '0 8px',
    borderRadius: '10px',
    paddingLeft: 0,
    '&.MuiFormLabel-filled': {
      transform: 'translate(13px, 4px) scale(0.75) !important',
    },
    '&.Mui-focused': {
      transform: 'translate(13px, 4px) scale(0.75)',
    },
  },
  '& .MuiInputBase-root': {
    borderRadius: 4,

    '&::before': {
      display: 'none',
    },
    '&::after': {
      display: 'none',
    },

    '& input': {
      paddingTop: '25px',
      paddingBottom: '8px',
    },
  },
};

export const iconSx: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '1.8rem',
  transform: 'translateY(-50%)',
  color: '#009688',
  zIndex: 99,
};

export const labelStyles = (isSmallScreen: boolean) => ({
  fontSize: isSmallScreen ? '14px' : '1.8rem',
  paddingLeft: '5rem',
});

export const inputStyles = (isSmallScreen: boolean) => ({
  fontSize: isSmallScreen ? '14px' : '1.8rem',
  fontWeight: 400,
  paddingLeft: '5rem',
  height: '60px',
});
