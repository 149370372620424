import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, Fragment } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

interface IStateIndicator {
  states: Array<{
    id: number;
    name: string;
  }>;
  state: number;
}

export const UserStateIndicator: FC<IStateIndicator> = ({ states, state }) => {
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');

  const theme = useTheme();

  const colors = {
    green: 'green',
    yellow: '#f98923',
    blue: theme.palette.primary.main,
  };

  const linearGradientFrom = `linear-gradient(to right, ${colors.yellow}, ${theme.palette.primary.main})`;
  const linearGradientTo = `linear-gradient(to right, ${colors.green}, ${colors.yellow})`;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        py: 3,
        px: 4,
      }}
    >
      {states.map((item, idx) => {
        const isActive =
            item.id < state || (item.id === state && idx === states.length - 1),
          isNextCurrState =
            states.length > idx + 1 && states[idx + 1].id === state,
          isCurrState = item.id === state;

        return (
          <Fragment key={item.id}>
            <Box position="relative">
              <Box
                sx={{
                  width: isSmallDevice ? '24px' : '40px',
                  height: isSmallDevice ? '24px' : '40px',
                  borderRadius: '50%',
                  display: isSmallDevice ? 'none' : 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: isActive
                    ? colors.green
                    : isCurrState
                    ? colors.yellow
                    : colors.blue,
                }}
              >
                {isActive ? (
                  <CheckIcon
                    sx={{ color: '#fff', width: '24px', height: '24px' }}
                  />
                ) : (
                  <AccessTimeFilledIcon
                    sx={{ color: '#fff', width: '24px', height: '24px' }}
                  />
                )}
              </Box>
              {idx === 0 ? (
                <Typography
                  sx={{
                    display: isSmallDevice ? 'none' : 'block',
                    position: 'absolute',
                    top: 'calc(100% + 8px)',
                    left: '250%',
                    transform: 'translateX(-50%)',
                    whiteSpace: 'nowrap',
                    fontSize: isSmallDevice ? '8px' : '16px',
                    color: theme.palette.primary.main,
                    fontWeight: 600,
                  }}
                >
                  {item.name}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    display: isSmallDevice ? 'none' : 'block',
                    position: 'absolute',
                    top: 'calc(100% + 8px)',
                    left: '-150%',
                    transform: 'translateX(-50%)',
                    whiteSpace: 'nowrap',
                    fontSize: isSmallDevice ? '8px' : '16px',
                    color: theme.palette.primary.main,
                    fontWeight: 600,
                  }}
                >
                  {item.name}
                </Typography>
              )}
            </Box>
            {idx + 1 < states.length && (
              <Box
                sx={{
                  display: isSmallDevice ? 'none' : 'block',
                  flex: 1,
                  height: '6px',
                  background:
                    isActive && !(idx + 1 < states.length - 1)
                      ? colors.green
                      : isNextCurrState
                      ? linearGradientTo
                      : isCurrState
                      ? linearGradientFrom
                      : colors.blue,
                }}
              ></Box>
            )}
          </Fragment>
        );
      })}
    </Box>
  );
};
