import { Box } from '@mui/material';
import { FC } from 'react';
import { ChatMessageItem } from './ChatMessageItem';
import { ChatMessageGroup } from './ChatMessageGroup';
import { IChatMessageService, IChatMessages } from 'store/hooks/services/chat';
import { useSession } from 'shared/hooks/useSession';

interface IChatMessagesProps {
  messages: IChatMessages[];
  onEditMessage: (item: IChatMessageService) => void;
}
export const ChatMessages: FC<IChatMessagesProps> = ({
  messages,
  onEditMessage,
}) => {
  return (
    <Box className="tw-min-h-[400px] tw-max-h-[600px] tw-overflow-hidden">
      <Box className="tw-overflow-x-hidden tw-px-12 tw-h-[500px] tw-overflow-y-auto tw-py-5">
        {messages.map((group) => (
          <ChatMessageGroup group={group} onEditMessage={onEditMessage} />
        ))}
      </Box>
    </Box>
  );
};
