import {
  Box,
  Button,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { logout } from 'store/slices/credentials/actions';
import { mainStyles } from './style';
import Logo from '../../components/ui/Logo';
import { useDispatch } from 'store';
import { getTokenFromStorage } from '../../shared/utils/getTokenFromStorage';
import SnowFlake from '../../components/ui/SnowFlake';
import './index.scss';
import './publicContentLayout.scss';
import {
  useFetchHotLineEmailQuery,
  useFetchHotLinePhoneQuery,
} from 'store/hooks/general';
import { supportStyles } from 'layouts/private/styles';
import MainMenu from 'components/ui/MainMenu';
import { Close } from '@mui/icons-material';

interface IPublicContentLayout {
  children?: ReactNode;
}

const PublicContentLayout: FC<IPublicContentLayout> = ({ children }) => {
  const isAnimationAllowed = parseInt(
    process.env.REACT_APP_SEASON_ANIMATION || ''
  );

  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const { data: email } = useFetchHotLineEmailQuery('');
  const { data: phone } = useFetchHotLinePhoneQuery('');
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const navigate = useNavigate();
  if (
    getTokenFromStorage() &&
    !pathname.includes('confirmation') &&
    !pathname.includes('receipt')
  ) {
    return <Navigate to={'/services'} />;
  }

  const handlers = {
    logout() {
      dispatch(
        logout(() => {
          navigate('auth/login');
        })
      );
    },
  };

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      {isSmallDevice && (
        <div style={supportStyles}>
          <div className="tw-flex tw-gap-8 tw-py-2 tw-justify-center tw-w-full ">
            <div className="tw-flex tw-flex-col">
              <Typography variant="body2">Телефон центра поддержки:</Typography>
              {phone?.length > 0 &&
                phone.map((item: string) => (
                  <a href={`tel:${item}`} key={item}>
                    {item}
                  </a>
                ))}
            </div>
            <div className="tw-flex tw-flex-col">
              <Typography variant="body2">Электроный адрес:</Typography>
              {email?.length > 0 &&
                email.map((item: string) => (
                  <a href={`mailto:${item}`} key={item}>
                    {item}
                  </a>
                ))}
            </div>
          </div>
        </div>
      )}
      <header className="headerStyles">
        <Container>
          <Box display="flex" alignItems="center" position={'relative'}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {isSmallDevice && getTokenFromStorage() && (
                <img
                  src="/assets/images/Burger.svg"
                  alt="burgerMenu"
                  width={32}
                  height={32}
                  onClick={() => {
                    setOpen(true);
                  }}
                />
              )}
              <Logo />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                right: 0,
              }}
            >
              {getTokenFromStorage() ? (
                <Box>
                  <MainMenu onLogout={handlers.logout} />
                </Box>
              ) : (
                <div className="tw-flex tw-gap-8 tw-items-center">
                  {!isSmallDevice && (
                    <div className="tw-flex tw-gap-8 ">
                      <div className="tw-flex tw-flex-col">
                        <Typography variant="body2">
                          Телефон центра поддержки:
                        </Typography>
                        {phone?.length > 0 &&
                          phone.map((item: string) => (
                            <a href={`tel:${item}`} key={item}>
                              {item}
                            </a>
                          ))}
                      </div>
                      <div className="tw-flex tw-flex-col">
                        <Typography variant="body2">
                          Электроный адрес:
                        </Typography>
                        {email?.length > 0 &&
                          email.map((item: string) => (
                            <a href={`mailto:${item}`} key={item}>
                              {item}
                            </a>
                          ))}
                      </div>
                    </div>
                  )}
                  <Button
                    onClick={() => navigate('/auth/login')}
                    variant="contained"
                    sx={{ minWidth: '67px' }}
                    className="publicContentLayoutBtn"
                  >
                    Войти
                  </Button>
                </div>
              )}
            </Box>
          </Box>
        </Container>
      </header>
      <main style={mainStyles}>
        <Container>
          {isAnimationAllowed > 0 && <SnowFlake />}
          <Outlet />
        </Container>
      </main>

      {getTokenFromStorage() ? (
        <div className="sidenav" style={{ width: open ? '100%' : '0px' }}>
          <div className="tw-py-4 tw-pl-5">
            <IconButton
              onClick={() => {
                setOpen(false);
              }}
              sx={{ color: '#fff' }}
            >
              <Close fontSize="large" />
            </IconButton>
          </div>
          <div className="tw-flex tw-flex-col tw-mt-8 tw-ml-8 tw-mb-10">
            <p
              className="tw-text-3xl tw-mb-6 tw-text-white tw-cursor-pointer"
              onClick={() => {
                navigate('/');
                setOpen(false);
              }}
            >
              Все услуги
            </p>
            <p
              className="tw-text-3xl tw-mb-6 tw-text-white tw-cursor-pointer"
              onClick={() => {
                navigate('/my-services');
                setOpen(false);
              }}
            >
              Мои услуги{' '}
            </p>
          </div>
          <div className="tw-ml-5">
            <MainMenu
              isDrawer
              closeDrawer={() => {
                setOpen(false);
              }}
              onLogout={handlers.logout}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default PublicContentLayout;
