import { Box, Button, Typography } from '@mui/material';
import { useLocaleText } from '@mui/x-date-pickers/internals';
import { FC, MouseEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './index.scss'

interface IServiceItem {
  route?: string;
  name?: string;
  id?: string;
}

export const ServiceItem: FC<IServiceItem> = ({ name, id }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isFromPublic = pathname === '/';

  const detailsRoute = !isFromPublic
    ? `/services/details/${id}`
    : `/service-details/${id}`;

  return (
    <Box
      onClick={() => {
        navigate(detailsRoute);
      }}
      sx={{
        width: '100%',
        height: '100%',
        p: 6,
        borderRadius: 2,
        position: 'relative',
        boxShadow:
          '0px 3px 8px -1px rgba(50, 50, 71, 0.05), 0px 0px 1px 0px rgba(12, 26, 75, 0.24)',
        '&:hover': {
          opacity: 0.8,
          cursor: 'pointer',
        },
      }}
    >
      <img
        src={'/assets/images/gerb.png'}
        style={{
          height: '100px',
          display: 'block',
          margin: '0 auto 24px auto',
        }}
      />
      <Typography variant="h5" color="primary" textAlign="center" className="serviceItemTypography">
        {name || 'Lorem ipsum dolor sit amet consectetur.'}
      </Typography>
    </Box>
  );
};
