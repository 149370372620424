import axios from "axios"


const baseUrl = process.env.REACT_APP_BASE_API_URL

const baseApiReq = axios.create({
  baseURL: baseUrl,
})

export const getConfirmationDetail = async(id: string) => {

    const {data} = await baseApiReq.get(`api/Egov_Service_Requests/getSignedText/${id}`)

    return data
}