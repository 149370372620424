import styled from '@emotion/styled/macro';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Logo from 'components/ui/Logo';
import { headerStyles } from 'layouts/private/styles';
import { useNavigate, useParams } from 'react-router-dom';
import './index.scss';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiRoutes } from 'shared/constants/ApiRoutes';
import { toast } from 'react-toastify';
import { getTokenFromStorage } from 'shared/utils/getTokenFromStorage';
import MainMenu from 'components/ui/MainMenu';
import { useDispatch } from 'store';
import { logout } from 'store/slices/credentials/actions';
import {
  useFetchHotLineEmailQuery,
  useFetchHotLinePhoneQuery,
} from 'store/hooks/general';

const PrintCell = styled(Box)``;

const Container1 = styled.div`
  @media print {
    ${PrintCell} {
      display: none;
    }
  }
`;

const invoiceStatus = {
  0: 'Пардохтшуда',
  1: 'Пардохтнашуда',
  2: 'Қисман пардохт',
  3: 'Пардохтшуда',
};

const paymentStatus = {
  0: 'Қисман пардохт',
  1: 'Пардохтшуда',
};

function Receipt() {
  const params = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>({});
  const { data: email } = useFetchHotLineEmailQuery('');
  const { data: phone } = useFetchHotLinePhoneQuery('');

  const getReceiptById = async (id: string) => {
    try {
      setIsLoading(true);
      const { data: receipt } = await axios.get(
        process.env.REACT_APP_BASE_API_URL + ApiRoutes.getReceiptById + id
      );
      console.log(receipt);
      if (receipt.paymentState !== 1) {
        navigate('/404');
      }
      setData(receipt);
    } catch (error: any) {
      toast.error(error?.response?.data?.Message, {
        autoClose: 2000,
      });
      navigate('/404');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getReceiptById(params.id as string);
  }, [params.id]);

  const handlers = {
    logout() {
      dispatch(
        logout(() => {
          navigate('auth/login');
        })
      );
    },
  };

  return (
    <>
      <Container1>
        <PrintCell>
          <header style={headerStyles} className="header">
            <Container maxWidth="xl">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Logo />

                {getTokenFromStorage() ? (
                  <Box>
                    <MainMenu onLogout={handlers.logout} />
                  </Box>
                ) : (
                  <div className="tw-flex tw-gap-8 tw-items-center">
                    {!isSmallDevice && (
                      <div className="tw-flex tw-gap-8 ">
                        <div className="tw-flex tw-flex-col">
                          <Typography variant="body2">
                            Телефон центра поддержки:
                          </Typography>
                          {phone?.length > 0 &&
                            phone.map((item: string) => (
                              <a href={`tel:${item}`} key={item}>
                                {item}
                              </a>
                            ))}
                        </div>
                        <div className="tw-flex tw-flex-col">
                          <Typography variant="body2">
                            Электроный адрес:
                          </Typography>
                          {email?.length > 0 &&
                            email.map((item: string) => (
                              <a href={`mailto:${item}`} key={item}>
                                {item}
                              </a>
                            ))}
                        </div>
                      </div>
                    )}
                    <Button
                      onClick={() => navigate('/auth/login')}
                      variant="contained"
                      sx={{ minWidth: '67px' }}
                      className="publicContentLayoutBtn"
                    >
                      Войти
                    </Button>
                  </div>
                )}
              </Box>
            </Container>
          </header>
        </PrintCell>
      </Container1>
      <div className="tw-flex tw-justify-center tw-items-center tw-flex-col tw-max-w-[1200px] tw-mx-auto tw-mt-10">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <div className="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-mb-6">
              <Box sx={{ width: '50px', position: 'relative' }}>
                <img
                  style={{ maxWidth: '100%', zIndex: 10, position: 'relative' }}
                  src="/assets/images/gerb.png"
                  alt="Логотип"
                  width={80}
                  height={80}
                />
              </Box>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '18px',
                  lineHeight: '24px',
                }}
                className="tw-uppercase"
              >
                {data?.orgName}
              </Typography>
              {/* <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '24px',
                            }}
                            className='tw-text'
                        >
                            {data?.orgAdress}
                        </Typography> */}
            </div>
            <div
              className={
                isSmallDevice
                  ? 'tw-flex tw-flex-col tw-px-10 tw-w-full'
                  : 'tw-flex tw-justify-between tw-w-full tw-mb-4'
              }
            >
              {isSmallDevice ? (
                <div className="tw-flex tw-justify-between tw-w-full">
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#607D8B',
                    }}
                  >
                    Хисобномаи №
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#607D8B',
                    }}
                  >
                    {data?.invoiceNumber}
                  </Typography>
                </div>
              ) : (
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#607D8B',
                  }}
                >
                  Хисобномаи № {data?.invoiceNumber}
                </Typography>
              )}
              {isSmallDevice ? (
                <div className="tw-flex tw-justify-between tw-w-full">
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#607D8B',
                    }}
                  >
                    Сана
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#607D8B',
                    }}
                  >
                    {data?.invoiceDate?.slice(0, 10)}
                  </Typography>
                </div>
              ) : (
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: '#607D8B',
                  }}
                >
                  Сана {data?.invoiceDate?.slice(0, 10)}
                </Typography>
              )}
            </div>
            <div
              className={
                isSmallDevice
                  ? 'tw-flex tw-justify-between tw-w-full tw-mb-2 tw-px-10'
                  : 'tw-flex tw-justify-between tw-w-full tw-mb-2'
              }
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#607D8B',
                }}
                className="tw-text"
              >
                Пардохткунанда
              </Typography>
              {isSmallDevice ? (
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#607D8B',
                  }}
                  className="tw-text"
                >
                  {data?.invoicePayer}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: '#607D8B',
                  }}
                >
                  Раками мобили
                </Typography>
              )}
            </div>
            <div
              className={
                isSmallDevice
                  ? 'tw-flex tw-justify-between tw-w-full tw-mb-6 tw-px-10'
                  : 'tw-flex tw-justify-between tw-w-full tw-mb-6'
              }
            >
              {isSmallDevice ? (
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: '#607D8B',
                  }}
                >
                  Раками мобили
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#607D8B',
                  }}
                  className="tw-text"
                >
                  {data?.invoicePayer}
                </Typography>
              )}
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '24px',
                  color: '#607D8B',
                }}
              >
                *****{data?.phoneNumber}
              </Typography>
            </div>

            {/* <Accordion question='a' answer='b' /> */}
            <div
              className={
                isSmallDevice
                  ? 'tw-flex tw-justify-between tw-w-full tw-mb-6 tw-px-10'
                  : 'tw-flex tw-justify-between tw-w-full tw-mb-6'
              }
            >
              {isSmallDevice ? (
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: '#607D8B',
                  }}
                >
                  Ҳолати пардохт
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#607D8B',
                  }}
                  className="tw-text"
                >
                  Ҳолати пардохт
                </Typography>
              )}
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '24px',
                  color: '#607D8B',
                }}
              >
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  paymentStatus[data?.paymentState]
                }
              </Typography>
            </div>
            <div
              className={
                isSmallDevice
                  ? 'tw-flex tw-justify-between tw-w-full tw-mb-6 tw-px-10'
                  : 'tw-flex tw-justify-between tw-w-full tw-mb-6'
              }
            >
              {isSmallDevice ? (
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: '#607D8B',
                  }}
                >
                  Ҳолати ҳисобнома-фактура
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#607D8B',
                  }}
                  className="tw-text"
                >
                  Ҳолати ҳисобнома-фактура
                </Typography>
              )}
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '24px',
                  color: '#607D8B',
                }}
              >
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  invoiceStatus[data?.invoiceState]
                }
              </Typography>
            </div>
            <div
              className={
                isSmallDevice
                  ? 'tw-flex tw-w-full tw-mb-4 tw-px-10'
                  : 'tw-flex tw-justify-end tw-w-full tw-mb-10'
              }
            >
              {/* <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: '#607D8B'
                            }}
                            className='tw-text'
                        >
                            {data?.invoiceDescription}
                        </Typography> */}
              {isSmallDevice ? null : (
                <Typography
                  sx={{
                    fontWeight: 800,
                    fontSize: '18px',
                    lineHeight: '24px',
                    color: '#607D8B',
                  }}
                >
                  {data?.invoicePaidMoney} Сомони
                </Typography>
              )}
            </div>
            {isSmallDevice ? (
              <div className="tw-flex tw-justify-end tw-w-full tw-px-10 tw-mb-5">
                <Typography
                  sx={{
                    fontWeight: 800,
                    fontSize: '18px',
                    lineHeight: '24px',
                    color: '#607D8B',
                  }}
                >
                  {data?.invoicePaidMoney} Сомони
                </Typography>
              </div>
            ) : null}
            <div className="tw-flex tw-justify-center">
              <img src="/assets/images/Approve.png" />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Receipt;
