import {
  Box,
  Divider,
  FormGroup,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { StateIndicator } from 'components/ui/StateIndicator';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetUserQuery,
  useLazyGetOrgQuery,
  useUserLegalUpdateMutation,
  useUserProfileGetQuery,
  useUserUpdateMutation,
} from 'store/hooks/userprofile';
// import { getInitialValues } from './schema';
// import { Chat } from './components/Chat';

import { userStates, UserStatus } from './helpers/constants';
import { SectionForm } from 'components/forms/SectionForm';
import { validationLegalSchema } from 'routes/public/registration/schema';
import { useFormik } from 'formik';
import { UserAction } from './components/forms/UserAction';
import { UserInfoForm } from './components/forms/UserInfoForm';
import { getFieldErrors, innSchema, stringSchema } from 'shared/utils/yup';
import * as Yup from 'yup';
import {
  phoneRegexp,
  phoneRegexpCompleted,
} from 'routes/public/login/helpers/constants';
import { UserFiles } from './components/forms/UserFiles';
import { toast } from 'react-toastify';
import { UserStateIndicator } from 'components/User/UserStateIndicator';

const validationUserSchema = Yup.object({
  name: stringSchema(),
  surName: stringSchema(),
  login: stringSchema(),
  inn: innSchema(),
  phone: Yup.string()
    .required('Обязательно')
    .test({
      test: (value) => phoneRegexpCompleted?.test(value),
      message: 'Неправильный формат телефона: 992XXXXXXXXX',
    }),
  passportNumber: stringSchema(),
});

const validationLegalUserSchema = Yup.object({
  orgName: stringSchema(),
  orgINN: innSchema(),
  orgAdress: stringSchema(),
  name: stringSchema(),
  surName: stringSchema(),
  login: stringSchema(),
  inn: innSchema(),
  phone: Yup.string()
    .required('Обязательно')
    .test({
      test: (value) => phoneRegexpCompleted?.test(value),
      message: 'Неправильный формат телефона: 992XXXXXXXXX',
    }),
  passportNumber: stringSchema(),
});

const User: FC = () => {
  const theme = useTheme();
  const { id } = useParams();
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const { data: user, refetch } = useUserProfileGetQuery(id);
  const [updateUser] = useUserUpdateMutation();
  const [updateUserLegal] = useUserLegalUpdateMutation();
  const [editMode, setEditMode] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      surName: '',
      patronicName: '',
      login: '',
      inn: '',
      phone: '',
      email: '',
      passportNumber: '',
      address: '',
      files: [],
    },
    validationSchema: validationUserSchema,
    async onSubmit(values) {
      await updateUser({ ...values, id });
      toast.success('Пользователь успешно обновлен');
      setEditMode(false);
    },
  });

  const formikLegal = useFormik({
    initialValues: {
      orgName: '',
      orgINN: '',
      orgAdress: '',
      name: '',
      surName: '',
      patronicName: '',
      login: '',
      inn: '',
      phone: '',
      email: '',
      passportNumber: '',
      address: '',
      files: [],
    },
    validationSchema: validationLegalUserSchema,
    async onSubmit(values) {
      await updateUserLegal({ ...values, id });
      toast.success('Пользователь успешно обновлен');
      refetch();
      setEditMode(false);
    },
  });

  const { values, errors, touched, setFieldValue, setFieldTouched } = formik;

  const {
    values: valuesLegal,
    errors: errorsLegal,
    touched: touchedLegal,
    setFieldValue: setFieldValueLegal,
    setFieldTouched: setFieldTouchedLegal,
  } = formikLegal;

  useEffect(() => {
    if (user) {
      if (user.userType === 2) {
        setFieldValueLegal('orgName', user?.orgName || '');
        setFieldValueLegal('orgINN', user?.orgINN || '');
        setFieldValueLegal('orgAdress', user?.orgAdress || '');
        setFieldValueLegal('name', user?.name || '');
        setFieldValueLegal('surName', user?.surName || '');
        setFieldValueLegal('patronicName', user?.patronicName || '');
        setFieldValueLegal('login', user?.login || '');
        setFieldValueLegal('inn', user?.inn || '');
        setFieldValueLegal('phone', user?.phone || '');
        setFieldValueLegal('email', user?.email || '');
        setFieldValueLegal('passportNumber', user?.passportNumber || '');
        setFieldValueLegal('address', user?.address || '');
        setFieldValueLegal('files', user.userDocs || []);
      }
      if (user?.userType !== 2) {
        setFieldValue('name', user?.name || '');
        setFieldValue('surName', user?.surName || '');
        setFieldValue('patronicName', user?.patronicName || '');
        setFieldValue('login', user?.login || '');
        setFieldValue('inn', user?.inn || '');
        setFieldValue('phone', user?.phone || '');
        setFieldValue('email', user?.email || '');
        setFieldValue('passportNumber', user?.passportNumber || '');
        setFieldValue('address', user?.address || '');
        setFieldValue('files', user?.userDocs || []);
      }

    }
  }, [user]);

  const getHandler = (key: string, regexp?: RegExp) => (e: any) => {
    const value = e.target.value;
    if (regexp && !regexp.test(value)) {
      return;
    }
    setFieldValue(key, value);
  };

  const getPhone = (key = 'phone') => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;

      if (value.length === 3) {
        setFieldValue(key, '');
        return;
      }

      if (!value.startsWith('992')) {
        value = '992' + value;
      }

      if (value.length > 12 || !phoneRegexp.test(value)) return;

      setFieldValue(key, value);
    };
  };

  const getHandleBlur = (key: string) => () => {
    setFieldTouched(key, true);
  };

  const getHandleBlurLegal = (key: string) => () => {
    setFieldTouchedLegal(key, true);
  };

  const getHandlerLegal = (key: string, regexp?: RegExp) => (e: any) => {
    const value = e.target.value;
    if (regexp && !regexp.test(value)) {
      return;
    }
    setFieldValueLegal(key, value);
  };

  const getPhoneLegal = (key = 'phone') => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;

      if (value.length === 3) {
        setFieldValueLegal(key, '');
        return;
      }

      if (!value.startsWith('992')) {
        value = '992' + value;
      }

      if (value.length > 12 || !phoneRegexp.test(value)) return;

      setFieldValueLegal(key, value);
    };
  };

  return (
    <Box sx={{ pt: 4 }}>
      <Box
        sx={{
          p: 3,
          borderRadius: 3,
          border: `1px solid ${theme.palette.primary.main}`,
          mb: 5,
          backgroundColor: '#fff',
        }}
      >
        <UserAction
          refetch={() => refetch()}
          save={() => {
            if (user?.userType === 2) {
              formikLegal.submitForm();
              return;
            }
            formik.submitForm();
          }}
          disabled={editMode}
          editable={() => setEditMode(true)}
        />
        <Divider sx={{ color: theme.palette.primary.main, mt: 3, mb: 2 }} />
        <UserStateIndicator
          states={userStates}
          state={user?.authorized ? UserStatus.Done : UserStatus.New}
        />
      </Box>

      {user?.userType == 2 && (
        <SectionForm title="Информация об организации">
          <FormGroup className="tw-mb-4">
            <div className="lg:tw-grid tw-grid-cols-2 tw-gap-4">
              <TextField
                sx={{
                  marginTop: isSmallDevice ? '15px' : '0',
                  width: isSmallDevice ? '100%' : '100%',
                }}
                name="orgName"
                label="Название организации"
                size="small"
                disabled={!editMode}
                value={valuesLegal?.orgName}
                onChange={getHandlerLegal('orgName')}
                onBlur={getHandleBlurLegal('orgName')}
                {...getFieldErrors(formikLegal, 'orgName')}
                required
              />
              <TextField
                sx={{
                  marginTop: isSmallDevice ? '15px' : '0',
                  width: isSmallDevice ? '100%' : '100%',
                }}
                name="orgINN"
                label="ИНН ораганизации"
                size="small"
                disabled={!editMode}
                value={valuesLegal?.orgINN}
                onChange={getHandlerLegal('orgINN')}
                onBlur={getHandleBlurLegal('orgINN')}
                {...getFieldErrors(formikLegal, 'orgINN')}
                required
              />
              <TextField
                sx={{
                  marginTop: isSmallDevice ? '15px' : '0',
                  width: isSmallDevice ? '100%' : '100%',
                }}
                name="orgAdress"
                label="Адрес ораганизации"
                size="small"
                disabled={!editMode}
                value={valuesLegal?.orgAdress}
                onChange={getHandlerLegal('orgAdress')}
                onBlur={getHandleBlurLegal('orgAdress')}
                {...getFieldErrors(formikLegal, 'orgAdress')}
                required
              />
            </div>
          </FormGroup>
        </SectionForm>
      )}

      <SectionForm title="Основная информация">
        {user?.userType == 1 || user?.userType == 0 ? (
          <UserInfoForm
            editMode={editMode}
            formik={formik}
            values={values}
            getHandler={getHandler}
            getHandleBlur={getHandleBlur}
            getPhone={getPhone}
          />
        ) : (
          <UserInfoForm
            editMode={editMode}
            formik={formikLegal}
            values={valuesLegal}
            getHandler={getHandlerLegal}
            getHandleBlur={getHandleBlurLegal}
            getPhone={getPhoneLegal}
          />
        )}
      </SectionForm>

      <SectionForm emptyBox title="Необходимые документы">
        {
          user?.userType === 2 ?
            <UserFiles formik={formikLegal} /> : <UserFiles formik={formik} />
        }
      </SectionForm>
    </Box>
  );
};

export default User;
