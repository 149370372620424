import { FC } from 'react';
import { FormGroup, TextField, useMediaQuery } from '@mui/material';
import { getFieldErrors } from 'shared/utils/yup';

interface IServiceInfoForm {
  disabled?: boolean;
  values: any;
  editMode: boolean;
  getHandler: any;
  getHandleBlur: any;
  getPhone: any;
  formik: any;
}

export const UserInfoForm: FC<IServiceInfoForm> = ({
  disabled,
  values,
  formik,
  editMode,
  getHandleBlur,
  getHandler,
  getPhone
}) => {
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');

  return (
    <div className="tw-p-4 tw-pb-0">
      <FormGroup className="tw-mb-4">
        <div className="lg:tw-grid tw-grid-cols-2 tw-gap-4">
          <TextField
            sx={{
              marginTop: isSmallDevice ? '15px' : '0',
              width: isSmallDevice ? '100%' : '100%',
            }}
            name="name"
            label="Имя"
            size="small"
            disabled={!editMode}
            value={values.name}
            onChange={getHandler('name')}
            onBlur={getHandleBlur('name')}
            {...getFieldErrors(formik, 'name')}
            required
          />
          <TextField
            sx={{
              marginTop: isSmallDevice ? '15px' : '0',
              width: isSmallDevice ? '100%' : '100%',
            }}
            name="surName"
            label="Фамилия"
            size="small"
            disabled={!editMode}
            value={values.surName}
            onChange={getHandler('surName')}
            onBlur={getHandleBlur('surName')}
            {...getFieldErrors(formik, 'surName')}
            required
          />
          <TextField
            sx={{
              marginTop: isSmallDevice ? '15px' : '0',
              width: isSmallDevice ? '100%' : '100%',
            }}
            name="patronicName"
            label="Отчество"
            size="small"
            disabled={!editMode}
            value={values.patronicName}
            onChange={getHandler('patronicName')}
            required
          />
          <TextField
            sx={{
              marginTop: isSmallDevice ? '15px' : '0',
              width: isSmallDevice ? '100%' : '100%',
            }}
            name="passportNumber"
            label="Номер паспорта"
            size="small"
            disabled={!editMode}
            value={values.passportNumber}
            onChange={getHandler('passportNumber')}
            onBlur={getHandleBlur('passportNumber')}
            {...getFieldErrors(formik, 'passportNumber')}
            required
          />
          <TextField
            sx={{
              marginTop: isSmallDevice ? '15px' : '0',
              width: isSmallDevice ? '100%' : '100%',
            }}
            name="login"
            size="small"
            label="Логин"
            disabled={!editMode}
            value={values.login}
            onChange={getHandler('login')}
            onBlur={getHandleBlur('login')}
            {...getFieldErrors(formik, 'login')}
            required
          />
          <TextField
            sx={{
              marginTop: isSmallDevice ? '15px' : '0',
              width: isSmallDevice ? '100%' : '100%',
            }}
            name="inn"
            label="ИНН"
            size="small"
            disabled={!editMode}
            value={values.inn}
            onChange={getHandler('inn', /^[0-9]{0,9}$/)}
            onBlur={getHandleBlur('inn')}
            {...getFieldErrors(formik, 'inn')}
            required
          />
          <TextField
            sx={{
              marginTop: isSmallDevice ? '15px' : '0',
              width: isSmallDevice ? '100%' : '100%',
            }}
            name="address"
            label="Адрес"
            size="small"
            value={values.address}
            disabled={!editMode}
            onChange={getHandler('address')}
            onBlur={getHandleBlur('address')}
            {...getFieldErrors(formik, 'address')}
            required
          />
          <TextField
            sx={{
              marginTop: isSmallDevice ? '15px' : '0',
              width: isSmallDevice ? '100%' : '100%',
            }}
            name="phone"
            label="Телефон"
            size="small"
            disabled={!editMode}
            value={values.phone}
            onChange={getPhone('phone')}
            onBlur={getHandleBlur('phone')}
            {...getFieldErrors(formik, 'phone')}
            required
          />
          <TextField
            sx={{
              marginTop: isSmallDevice ? '15px' : '0',
              width: isSmallDevice ? '100%' : '100%',
            }}
            name="email"
            label="Электронная почта"
            size="small"
            disabled={!editMode}
            value={values.email}
            onChange={getHandler('email')}
            onBlur={getHandleBlur('email')}
            {...getFieldErrors(formik, 'email')}
            required
          />
        </div>
      </FormGroup>
    </div>
  );
};
