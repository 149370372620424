import { useState } from 'react';
import { Box, Button, IconButton, Typography, useMediaQuery } from '@mui/material';
import { boxSx, titleSx, typographySx } from './styles';
import Modal from '../../../../components/ui/Modal';
import DeleteIcon from '@mui/icons-material/Delete';

import { toastPromise } from '../../../../shared/utils/toastPromise';
import AddCard from './components/AddCard';
import {
  useDeleteCardsMutation,
  useGetCardsQuery,
} from 'store/hooks/userprofile';
import SelectForMonile from 'components/selectForMobile/SelectForMonile';

const Requisites = () => {
  const [modal, setModal] = useState(false);
  const [changeContentModal, setChangeContentModal] = useState(false);
  const [deleteCards] = useDeleteCardsMutation();
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');


  const { data, refetch } = useGetCardsQuery({
    ids: null,
    filters: {},
    orderBy: {},
    pageInfo: {
      pageNumber: 1,
      pageSize: 1000,
    },
  });

  const toggleModal = () => {
    setModal(!modal);
    setChangeContentModal(changeContentModal && false);
  };

  const changeModalContentHandler = () => {
    setChangeContentModal(!changeContentModal);
  };

  const deleteCard = (id: number) => {
    const promise = deleteCards({ id });
    toastPromise(
      promise,
      {
        pending: 'Удаление карты.',
        success: 'Карта успешно удалена!',
        error: 'Не удалось удалить карту!',
      },
      {
        then: () => refetch(),
      }
    );
  };

  return (
    <Box>
      <SelectForMonile/>
      <Box
        className="lg:tw-grid tw-grid-cols-2 "
        sx={{ gap: '5.6rem', minHeight: '450px' }}
      >
        <Box className="tw-col-span-1 tw-flex tw-flex-col tw-gap-16">
          <Box>
            <Typography sx={titleSx}>Банковские карты</Typography>
            <Box sx={{
              border: '1px dashed rgba(0, 0, 0, 0.31)',
              borderRadius: '1.6rem',
              padding: '2.4rem',
              boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
              display: isSmallDevice ? '' : 'flex',
              alignItems: 'center',
              gap: '5.8rem',
              background: '#FBFCFF',
            }}>
              <Box>
                <Typography sx={typographySx}>
                  Добавьте реквизиты счёта в банке - можно будет использовать
                  для зачисления социальных выплат
                </Typography>
              </Box>
              <Box>
                <Button sx={{marginTop : isSmallDevice ? '10px' : ''}} onClick={toggleModal}> Добавить</Button>
              </Box>
            </Box>
          </Box>

          {data?.items?.map((item) => (
            <Box key={item.id}>
              <Box
                sx={{
                  padding: '2.4rem',
                  borderRadius: '16px',
                  border: '1px solid rgba(0, 0, 0, 0.32)',
                  boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
                  marginBottom: isSmallDevice ? '20px' : ''
                }}
              >
                <Box sx={{ marginBottom: '2.4rem' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <img src={item.bankLogo} alt="" />
                    <img alt="" />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography fontSize={20} fontWeight={400}>
                    {item.cartNumber
                      ?.toString()
                      .replace(/\d{4}(?=\d)/g, '**** ')
                      .trim()}
                  </Typography>
                  <IconButton onClick={() => deleteCard(item.id)}>
                    <DeleteIcon color="primary" fontSize="large" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>

        <Box className="tw-col-span-1 ">
          <Box>
            <Typography sx={titleSx}>Банковские счета</Typography>
            <Box sx={{
               border: '1px dashed rgba(0, 0, 0, 0.31)',
               borderRadius: '1.6rem',
               padding: '2.4rem',
               boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
               display: isSmallDevice ? '' : 'flex',
               alignItems: 'center',
               gap: '5.8rem',
               background: '#FBFCFF',
            }}>
              <Box>
                <Typography sx={typographySx}>
                  Добавьте реквизиты счёта в банке - можно будет использовать
                  для зачисления социальных выплат
                </Typography>
              </Box>
              <Box>
                <Button sx={{marginTop : isSmallDevice ? '10px' : ''}}> Добавить</Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal toggleModal={toggleModal} modal={modal} title="Добавить карту">
        <AddCard
          refetch={refetch}
          onClose={toggleModal}
          changeContentModal={changeContentModal}
          changeModalContentHandler={changeModalContentHandler}
        />
      </Modal>
    </Box>
  );
};

export default Requisites;
