import { Box, IconButton, Typography } from '@mui/material';
import { FC } from 'react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CancelIcon from '@mui/icons-material/Cancel';

interface IChatSendFileItem {
  fileName: string;
  loading?: boolean;
  onDelete: () => void;
}

export const ChatSendFileItem: FC<IChatSendFileItem> = ({
  fileName,
  onDelete,
}) => {
  return (
    <Box className="tw-flex tw-items-center tw-flex-gap-2 tw-p-2 tw-bg-gray-200 tw-rounded-md">
      <InsertDriveFileIcon color="primary" />
      <Typography className="tw-max-w-[100px] tw-truncate" variant="body2">
        {fileName}
      </Typography>
      <CancelIcon
        className="tw-ml-4 tw-cursor-pointer"
        color="error"
        onClick={onDelete}
      />
    </Box>
  );
};
