import { format } from 'date-fns';

export const getFileExtension = (fileName: string) => {
  const fileNames: Array<string> = fileName?.split('.');
  if (fileNames.length === 0) {
    return '';
  }

  return fileNames[fileNames.length - 1];
};

export const downloadFile = (res: any, withUrl?: boolean, name?: string) => {
  const { file64, fileName } = res.data || {};
  const a = document.createElement('a');
  a.href = res;
  a.download = name || 'file';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const downloadFileByUrl = (url: string, name?: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.download = name || 'file';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const getParamFromUrl = (url: string, paramKey: string) => {
  const [path, paramStr] = url.split('?');
  const arr = paramStr.split('&');
  const found = arr.find((item) => item.split('=')[0] === paramKey);

  if (!found) return;
  return found.split('=')[1];
};

export const hoursFormat = (date: string | number | Date) => {
  date = date instanceof Date ? date : new Date(date);
  return format(date, 'HH:mm');
};
