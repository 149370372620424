export const allCards = [
  { id: 1, logo: '/assets/images/korti-milli.png', title: 'КОРТИ МИЛЛИ' },
  {
    id: 2,
    logo: '/assets/images/visa-mc-card.png',
    title: 'VISA/MASTERCARD/МИР',
  },
];

export const banks = ['IBT', 'Amonat', 'Eskhata', 'Spitamen', 'Dushanbe City'];
