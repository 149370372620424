import { Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { TitleSizeType, titleSizesSx } from './styles';

interface ICustomTitle {
  size: TitleSizeType;
  children?: string | ReactNode;
}

const CustomTitle: FC<ICustomTitle> = ({ size, children }) => {
  return <Typography sx={titleSizesSx[size]}>{children}</Typography>;
};

export default CustomTitle;
