import { SxProps } from '@mui/material';
import { CSSProperties } from 'react';

export const styles: Record<string, SxProps> = {
  input: {
    '& fieldset': {
      border: 'none !important',
    },
    '& .MuiInputBase-root': {
      backgroundColor: 'transparent !important',
      padding: '0 !important',
    },
  },
};
