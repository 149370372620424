import { Box, Button, Divider, useMediaQuery, useTheme } from '@mui/material';
import { SectionForm } from 'components/forms/SectionForm';
import { StateIndicator } from 'components/ui/StateIndicator';
import { useFormik } from 'formik';
import { FC, useEffect, useMemo, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toastPromise } from 'shared/utils/toastPromise';
import {
  IServiceCreateRequest,
  IServiceCreateResponse,
  useCreateServiceMutation,
  useGetServiceDetailQuery,
  useLazyGetServiceByIdQuery,
  useUpdateServiceMutation,
  useGetSignedTextMutation,
} from 'store/hooks/services';
import {
  useGetUserprofileDetailsQuery,
  useLazyGetOrgQuery,
} from 'store/hooks/userprofile';
import { Chat } from './components/Chat';
import { ServiceActions } from './components/forms/ServiceActions';
import { ServiceExecutors } from './components/forms/ServiceExecutors';
import { ServiceFiles } from './components/forms/ServiceFiles';
import { ServiceInfoForm } from './components/forms/ServiceInfoForm';
import { ServiceOrgForm } from './components/forms/ServiceOrgForm';
import { ServiceResultForm } from './components/forms/ServiceResultForm';
import { ServiceStatus, serviceStates } from './helpers/constants';
import { getInitialValues } from './schema';

const escapeTabsAndSpaces = (text: string) => {
  const htmlTagsRegex = /<\/?[^>]+>/g;

  const parts = text.split('\t');

  const paragraphs = parts.map((part) => {
    if (part === '') {
      return '&nbsp;';
    }

    if (htmlTagsRegex.test(part)) {
      return part;
    }

    return part.replace(/ /g, '&nbsp;');
  });

  return paragraphs.join('\t');
};

const ServiceCreateApplication: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { serviceId, applicationId } = useParams();
  const { data: user } = useGetUserprofileDetailsQuery();
  const [refetch, { data }] = useLazyGetServiceByIdQuery();
  const [createService] = useCreateServiceMutation();
  const [updateService] = useUpdateServiceMutation();
  const { data: details } = useGetServiceDetailQuery(serviceId || '');
  const [dto, setDto] = useState<IServiceCreateResponse | null>(null);
  const [chatOpen, setChatOpen] = useState(false);
  const [readyText, setReadyText] = useState('');
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const [getOrg, { data: org }] = useLazyGetOrgQuery();
  const { pathname } = useLocation();

  console.log(data, 'data');

  const fetchData = async (id: string) => {
    await refetch(id)
      .then((res: any) => {
        if (res.data) {
          const text = res?.data?.readyDucementText?.readyText || '';

          const signBase64 = res?.data?.readyDucementText?.sign;

          const updatedReadyText =
            text +
            `<br><br><br><br><br><br><img src="data:image/png;base64,${signBase64}" alt="document-signature" />`;

          setReadyText(
            res?.data?.readyDucementText?.sign ? updatedReadyText : text
          );
        }
      })
      .catch(() => { });
  };

  useEffect(() => {
    if (applicationId) {
      fetchData(applicationId);
    }
  }, [applicationId]);

  useEffect(() => {
    if (pathname.includes('create')) {
      getOrg(user?.id);
    }
  }, [pathname, user]);
  const initialValues = useMemo(() => {
    console.log('initial values')
    if (!data) return getInitialValues(user, org, pathname, details);
    return getInitialValues(user, org, pathname, details, dto || data);
  }, [data, user, details, dto, org, pathname]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit(values) {
      const files =
        values.files
          .filter((item) => item.haveFile)
          .map((item) => ({
            fileName: item.fileName || '',
            fileId: item.id,
            filePath: item.filePath || '',
          })) || [];
      if (!files.length) {
        toast.error('Выберите минимум один файл для создания заявки');
        return;
      }

      const toastStates = {
        pending: `Заявка ${applicationId ? 'сохарняется' : 'создается'}`,
        success: `Заявка успешно ${applicationId ? 'сохарнена' : 'создана'}`,
        error: 'Произошла ошибка',
      };

      const payload: IServiceCreateRequest = {
        serviceId: Number(serviceId) || 0,
        files,
      };

      let promise = null;
      if (applicationId) {
        promise = updateService({ ...payload, id: Number(applicationId) });
      } else {
        promise = createService(payload);
      }

      toastPromise(promise, toastStates, {
        then: (res) => {
          navigate(`/services/details/${serviceId}/application/show/${res.id}`);
          refetch(res.id);
          setDto(res);
        },
      });
    },
  });

  return (
    <Box sx={{ pt: 4 }}>
      <Box
        sx={{
          p: 3,
          borderRadius: 3,
          border: `1px solid ${theme.palette.primary.main}`,
          mb: 5,
          backgroundColor: '#fff',
        }}
      >
        <ServiceActions
          formik={formik}
          transitions={data?.transitions}
          setDto={setDto}
        />
        <Divider sx={{ color: theme.palette.primary.main, mt: 3, mb: 2 }} />
        <StateIndicator
          states={serviceStates}
          state={(dto || data)?.state || ServiceStatus.New}
        />
      </Box>

      {data?.regUserType == 2 && (
        <SectionForm title="Информация об организации">
          <ServiceOrgForm formik={formik} />
        </SectionForm>
      )}

      {pathname.includes('create') && user?.userType == 2 && (
        <SectionForm title="Информация об организации">
          <ServiceOrgForm formik={formik} />
        </SectionForm>
      )}

      <SectionForm title="Основная информация">
        <ServiceInfoForm formik={formik} />
      </SectionForm>

      <SectionForm emptyBox title="Необходимые документы">
        {applicationId && data?.executor && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              mb: 1.5,
            }}
          >
            <Button
              size="medium"
              variant="contained"
              disabled={
                data?.transitions?.buttonSettings.btn_openChatWithClient
                  ?.readOnly
              }
              onClick={() => setChatOpen(true)}
            >
              Открыть чат
            </Button>
          </Box>
        )}
        <ServiceFiles formik={formik} />
      </SectionForm>

      {applicationId && data?.executor && (
        <SectionForm title="Исполнитель">
          <ServiceExecutors formik={formik} />
        </SectionForm>
      )}

      {applicationId && Number(data?.readyFiles?.length) > 0 && (
        <SectionForm title="Результат  - электронный документ с ЭЦП">
          <div className="tw-flex tw-flex-col tw-gap-2 tw-py-4 tw-px-4 tw-mb-4 mf_block_bg tw-rounded-[26px]">
            <div className="tw-flex tw-gap-1 tw-flex-col tw-space-y-6">
              <ReactQuill
                readOnly
                className="tw-w-[210mm] tw-h-[280mm] tw-m-auto"
                value={readyText}
                modules={{
                  toolbar: [],
                  clipboard: {
                    matchVisual: false,
                  },
                }}
                theme="snow"
              />
            </div>
          </div>
        </SectionForm>
      )}

      {applicationId && Number(data?.readyFiles?.length) > 0 && (
        <SectionForm title="Результатом является физический отсканированный документ с мокрой подписью.">
          <ServiceResultForm formik={formik} />
        </SectionForm>
      )}

      <Chat
        applicationId={Number(applicationId)}
        visibility={chatOpen}
        onClose={() => {
          setChatOpen(false);
        }}
      />
    </Box>
  );
};

export default ServiceCreateApplication;
