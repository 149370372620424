import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { FC } from 'react';
import { buttonSx, circularSx } from './styles';

interface ICustomButton extends ButtonProps {
  prop?: 'success';
  loading?: boolean;
}

const CustomButton: FC<ICustomButton> = (props) => {
  const { prop, loading, children, sx, ...otherProps } = props;

  return (
    <Button
      sx={{
        ...buttonSx,
        ...(sx || {}),
      }}
      {...otherProps}
    >
      <span style={{ position: 'relative' }}>
        {loading && <CircularProgress sx={circularSx} size={24} />}
        {children}
      </span>
    </Button>
  );
};

export default CustomButton;
