import { Box, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { Carousel } from '../../../components/ui/Carousel';
import { categories, moduleRoutes } from './helpers/constants';
import { ServiceCategory } from '../../../components/ui/ServiceCategory';
import { ServiceItem } from '../../../components/ui/ServiceItem';
import { useFetchOrganisationListQuery } from '../../../store/hooks/general';
import './index.scss'


const HomePage: FC = () => {
  const { data: orgs } = useFetchOrganisationListQuery();
  const [currentOrg, setCurrentOrg] = useState(-1);

  const organisatios = useMemo(() => {
    return (
      orgs?.items?.map((item) => ({
        iconUrl: item.image,
        name: item.name,
        id: item.id,
        services: item.services,
      })) || []
    );
  }, [orgs]);

  const services = useMemo(() => {
    if (currentOrg === -1) return [];
    return organisatios.find((item) => item.id === currentOrg)?.services || [];
  }, [currentOrg, organisatios]);

  // useEffect(() => {
  //   dispatch(getUserProfile({}));
  // }, []);

  useEffect(() => {
    if (organisatios.length > 0) {
      setCurrentOrg(organisatios[0].id);
    }
  }, [organisatios]);

  return (
    <Box sx={{ py: 4 }}>
      <Box py={10}>
        <Carousel
          width={192}
          items={organisatios}
          renderView={(data) => (
            <ServiceCategory
              onClick={() => setCurrentOrg(data.id)}
              active={data.id === currentOrg}
              {...data}
            />
          )}
        />
      </Box>
      {services.length > 0 && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            justifyItems: 'center',
            gap: 2,
            px: 2,
            py: 5,
            borderRadius: '20px',
            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.24)',
            backgroundColor: '#fff',
          }}
          className="homeBox"
        >
          {services.map((item) => {
            return (
              <ServiceItem
                key={item.id}
                route={moduleRoutes[item.id] || ''}
                name={item.name}
                id={String(item.id)}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default HomePage;
