import { SxProps } from '@mui/material';
import { CSSProperties } from 'react';

type GetUserFormSx = (certificateFormVisible: boolean) => SxProps;

export const containerSx: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 'calc(100vh - 90px)',
  position: 'relative',
};

export const paperSx = (isSmallScreen: boolean) => ({
  paddingTop: 6,
  paddingBottom: 8,
  paddingX: isSmallScreen ? 3 : 6,
  boxSizing: 'border-box',
  borderRadius: 4,
  position: 'relative',
  minHeight: '480px',
});

export const actionContainerSx: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  position: 'absolute',
  bottom: '-30px',
  left: '50%',
  transform: 'translateX(-50%)',
  gap: 2,
};

export const cancelBtnSx: SxProps = {
  width: '200px',
  background: '#fff',

  '&:hover': {
    background: '#fff',
  },
};

export const nextBtnSx: SxProps = {
  width: '200px',
};

export const sendBtnSx = (isSmallScreen: boolean) => ({
  width: isSmallScreen ? '230px' : '280px',
  height: isSmallScreen ? '48px' : '60px',
  fontSize: isSmallScreen ? '14px' : '18px'
});

export const getUserFormSx: GetUserFormSx = (certificateFormVisible) => ({
  position: 'absolute',
  paddingTop: '30px',
  top: 0,
  left: 0,
  transition: 'transform .3s ease, opacity .3s ease',
  transform: `translateX(${certificateFormVisible ? '-80px' : 0})`,
  opacity: certificateFormVisible ? 0 : 1,
  width: '100%',
  visibility: certificateFormVisible ? 'hidden' : 'visible',
});

export const getCertificateFormSx: GetUserFormSx = (
  certificateFormVisible
) => ({
  position: 'absolute',
  paddingTop: '30px',
  top: 0,
  left: 0,
  transition: 'transform .3s ease, opacity .3s ease',
  transform: `translateX(${certificateFormVisible ? '0' : '80px'})`,
  opacity: certificateFormVisible ? 1 : 0,
  width: '100%',
  visibility: certificateFormVisible ? 'visible' : 'hidden',
});

export const boxWithMainBg: CSSProperties = {
  zIndex: 999,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  position: 'absolute',
  top: 0,
  left: '-100%',
  width: '100%',
  height: '100%',
};

export const getContainerSx = (focused: boolean) => ({
  transition: 'all .5s ease-out',
  width: focused ? '50%' : '100%',
  paddingY: 4,
  position: 'relative',
  left: focused ? '50%' : '0',
  backgroundImage:
    'linear-gradient(90deg, #F3E7E9 0%, #E3EEFF 99%, #E3EEFF 100%)',
  display: 'flex',
  justifyContent: 'center',
});

export const closeIconSx: SxProps = {
  position: 'absolute',
  top: '20px',
  right: '20px',
  zIndex: 999,
};
