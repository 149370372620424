import { generateOptions, generateStates } from 'shared/utils/generateOptions';

export enum ServiceStatus {
  New = 1,
  Concelyar1 = 2,
  Resolution = 3,
  Execution = 4,
  Collect = 5,
  Concelyar2 = 6,
  Done = 7,
}

export const ServiceStatusName = {
  [ServiceStatus.New]: 'Новая',
  [ServiceStatus.Concelyar1]: 'Канцелярия',
  [ServiceStatus.Resolution]: 'Резолюция',
  [ServiceStatus.Execution]: 'Исполнение',
  [ServiceStatus.Collect]: 'Подготовка документов',
  [ServiceStatus.Concelyar2]: 'Канцелярия',
  [ServiceStatus.Done]: 'Завершено',
};

export const serviceStatusOptions = generateOptions(ServiceStatusName);
export const serviceStates = generateStates(ServiceStatusName);
