/* eslint-disable @typescript-eslint/ban-ts-comment */
import { IServiceCreateResponse, IServiceDetail } from 'store/hooks/services';
import { IUserprofileDetails } from 'store/hooks/userprofile';

export interface IInitialValues {
  orgName?: string;
  orgINN?: string;
  orgAdress?: string;
  fio: string;
  passport: string;
  phone: string;
  email: string;
  files: IServiceDetail['files'];
  readyFiles: IServiceCreateResponse['readyFiles'];
  executor: IServiceCreateResponse['executor'] | null;
}

const getFileName = (url?: string) => {
  if (!url) return '';

  const params = new URLSearchParams(url);
  return params.get('fileName');
};

const getFiles = (
  detailFiles?: IServiceDetail['files'],
  appFiles?: IServiceCreateResponse['files']
) => {
  if (!detailFiles) return [];
  return detailFiles.map((item) => {
    const found = appFiles?.find((file) => file.fileId === item.id);

    return {
      ...item,
      fileName: getFileName(found?.filePath),
      haveFile: found?.haveFile || false,
      filePath: found?.filePath,
    };
  });
};

export const getInitialValues = (
  user?: IUserprofileDetails,
  org?: any,
  pathname?: string,
  details?: any,
  app?: IServiceCreateResponse
) => {
  console.log('User: ', user, org, details, app);
  //@ts-ignore
  const org1: Pick<IInitialValues, 'orgName' | 'orgINN' | 'orgAdress'> = {};
  console.log(pathname);
  if (pathname?.includes('create')) {
    org1.orgName = org?.orgName || '';
    org1.orgINN = org?.orgINN || '';
    org1.orgAdress = org?.orgAdress || '';

    return {
      ...org1,
      fio: user?.displayName || '',
      passport: user?.inn || '',
      phone: user?.phone || '',
      email: user?.email || '',
      files: getFiles(details?.files, app?.files) || [],
      readyFiles: app?.readyFiles || [],
      executor: app?.executor,
    } as IInitialValues;
  }

  if (app?.regUserType === 2 && !pathname?.includes('create')) {
    org1.orgName = app?.orgName || '';
    org1.orgINN = app?.orgINN || '';
    org1.orgAdress = app?.orgAdress || '';
    console.log(app);
    return {
      ...org1,
      fio: app?.regUserName || '',
      passport: app?.passportNumber || '',
      phone: app?.phoneNumber || '',
      email: app?.email || '',
      files: getFiles(details?.files, app?.files) || [],
      readyFiles: app?.readyFiles || [],
      executor: app?.executor,
    } as IInitialValues;
  }

  return {
    ...org1,
    fio: app?.regUserName || '',
    passport: app?.passportNumber || '',
    phone: app?.phoneNumber || '',
    email: app?.email || '',
    files: getFiles(details?.files, app?.files) || [],
    readyFiles: app?.readyFiles || [],
    executor: app?.executor,
  } as IInitialValues;
};
