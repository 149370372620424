import { Box, Typography, useMediaQuery } from '@mui/material';
import React, {
  FC,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  getContainerSx,
  contentBoxSx,
  getBackgroundSx,
  titleBoxSx,
  titleSx,
} from './styles';

interface IModal {
  title: string;
  modal: boolean;
  toggleModal: (formik?: any) => void;
  children?: ReactNode;
}

const Modal: FC<IModal> = ({ title, modal, toggleModal, children }) => {
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [modal]);
  if (!modal) return null;
  return (
    <Box
      id="hook"
      onClick={(e) => {
        const target = e.target as HTMLElement;
        if (target.id === 'hook') {
          toggleModal();
        }
      }}
      sx={getBackgroundSx(modal)}
    >
      <Box sx={getContainerSx(modal)}>
        <Box sx={{ 
          width : isSmallDevice ? '100%' : '100%' ,
          margin : isSmallDevice ? 'auto' : 0,
         }}>
          <Box sx={titleBoxSx}>
            <Typography sx={titleSx}>{title}</Typography>
          </Box>
          <Box sx={contentBoxSx}>{children}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Modal;
