import { Box, IconButton } from '@mui/material';
import { useFormik } from 'formik';
import React, {
  memo,
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { RdnValue, RuTokenPlugin } from './plugin';
import { toast } from 'react-toastify';
import { RutokenPluginContext } from '../TokenAuth';
import { TokenInfo } from '../../../shared/utils/types';
import CustomField from '../../forms/CustomField';
import CustomSelect from '../../forms/CustomSelect';
import UsbIcon from '@mui/icons-material/Usb';
import SecurityIcon from '@mui/icons-material/Security';
import FiberPinIcon from '@mui/icons-material/FiberPin';
import { TokenSubmitContext } from '../../../routes/public/login';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { dateFormat } from '../../../shared/constants';
export interface LoginProps {
  title?: string;
  rutoken?: any;
  onSuccess: (data: TokenInfo) => void;
  onOutsideClick?: () => void;
}

interface ISelectOption {
  value: number | string;
  label: string;
}

export interface FormProps {
  pin: string;
  deviceId?: number;
  certId?: string;
  device: null | ISelectOption;
  cert: null | ISelectOption;
}

interface Certificate {
  id: string;
  startDate: string;
  expiryDate: string;
  serialNumber?: string;
  issuer?: RdnValue;
  subject?: RdnValue;
}

export default memo(function RutokenLogin({
  title = 'Требуется сертификат(E-token или Rutoken)',
  onOutsideClick,
  onSuccess,
}: LoginProps) {
  const contentRef: RefObject<any> = useRef(null);
  const modalRef: RefObject<any> = useRef(null);
  const [devices, setDevices] = useState<number[]>([]);
  const [certs, setCerts] = useState<Certificate[]>([]);
  const [login, setLogin] = useState(false);
  const rutoken = useContext(RutokenPluginContext) as RuTokenPlugin;
  const [passwordVisible, setPasswordVisible] = useState(false);

  const submitController = useContext(TokenSubmitContext);

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    setErrors,
  } = useFormik<FormProps>({
    initialValues: {
      pin: '',
      device: { label: 'Hello', value: 1 },
      cert: { label: 'Hello', value: 1 },
    },
    onSubmit(params) {
      setLogin(true);
      // rutoken?.pluginObject?.logout(params.deviceId as number)
      rutoken?.pluginObject.login(params.deviceId!, params.pin).then(
        () => {
          const certFounded = certs.find(({ id }) => id === params.certId);
          onSuccess({
            startDate: certFounded?.startDate || '',
            expiryDate: certFounded?.expiryDate || '',
            deviceId: params.deviceId!,
            certId:
              certFounded?.serialNumber?.split(':').join('').toLowerCase() ||
              '',
          });
          toast.success('ПИН-код успешно принят');
          rutoken?.pluginObject.logout(params.deviceId as number);
        },
        (err) => {
          // console.log('Error: ', err);
          setLogin(false);
          setErrors({ pin: 'Некорректный PIN-код' });
          toast.error('Некорректный PIN-код');
          submitController?.resetSubmitSignature();
        }
      );
    },
  });

  useEffect(() => {
    const handleOutsideClick = (e: Event) => {
      const target = e.target;
      if (
        target === modalRef.current ||
        target === modalRef.current?.firstChild
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onOutsideClick && onOutsideClick();
      }
    };

    if (onOutsideClick && contentRef.current) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => document.removeEventListener('click', handleOutsideClick);
  }, [contentRef.current]);

  useEffect(() => {
    const timerId = setInterval(() => {
      rutoken?.getDevices().then((deviceList: any[]) => {
        setDevices(deviceList.map((item) => item.id));
      });
    }, 2000);

    return () => {
      clearInterval(timerId);
    };
  }, [rutoken]);

  useEffect(() => {
    if (values.deviceId !== undefined) {
      rutoken?.getCertificates(values.deviceId).then((certificates: any[]) => {
        setCerts(
          certificates.map((item: any) => ({
            id: item.serialNumber,
            serialNumber: item.serialNumber,
            startDate: item.validNotBefore,
            expiryDate: item.validNotAfter,
            issuer: item.issuer.find(
              (item2: any) => item2.rdn === 'commonName'
            ),
            subject: item.subject.find(
              (item2: any) => item2.rdn === 'commonName'
            ),
          })).filter(item => {
            const date = new Date(item.expiryDate)
            return date.getTime() > Date.now()
          })
        );
      });
    }
  }, [rutoken, values.deviceId]);

  useEffect(() => {
    const submitSignature = submitController?.submitSignature;
    if (handleSubmit && submitSignature && submitSignature !== -1) {
      handleSubmit();
    }
  }, [handleSubmit, submitController?.submitSignature]);

  const deviceOptions = useMemo(
    () =>
      (devices &&
        devices.map((deviceId) => ({
          label: `Rutoken ECP #${deviceId}`,
          value: deviceId,
        }))) ||
      [],
    [devices]
  );

  const certOptions = useMemo(
    () =>
      (certs &&
        certs.map((item) => ({
          label: `${item.issuer?.value} ${item.subject?.value} (${dateFormat(item.expiryDate)})`,
          value: item.id,
        }))) ||
      [],
    [certs]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 4,
      }}
    >
      <CustomSelect
        StartIcon={UsbIcon}
        label="Доступные устройство"
        value={values.device}
        items={deviceOptions}
        onChange={(value) => {
          if (value) {
            setFieldValue('deviceId', value.value);
            setFieldValue('device', value);
          }
        }}
      />
      <CustomSelect
        StartIcon={SecurityIcon}
        label="Сертификаты"
        value={values.cert}
        items={certOptions}
        onChange={(value) => {
          if (value) {
            setFieldValue('certId', value.value);
            setFieldValue('cert', value);
          }
        }}
      />
      <CustomField
        id="outlined-password-input"
        label="PIN-код"
        type={passwordVisible ? 'text' : 'password'}
        autoComplete="current-password"
        StartIcon={FiberPinIcon}
        value={values.pin}
        onChange={(e: any) => {
          setFieldValue('pin', e.target.value);
        }}
        endIcon={
          <IconButton onClick={() => setPasswordVisible(!passwordVisible)}>
            {passwordVisible ? (
              <VisibilityOffIcon color="primary" fontSize="large" />
            ) : (
              <VisibilityIcon color="primary" fontSize="large" />
            )}
          </IconButton>
        }
      />
    </Box>
  );
});
