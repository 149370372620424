import * as Yup from 'yup';
import { stringSchema } from '../../../../../../shared/utils/yup';

export const initialValues = {
  cardNumber: '',
  name: '',
  bankName: '',
  year: '',
  cvc: '',
};

export const validationSchema = Yup.object({
  cardNumber: Yup.string()
    .required('Обязательно')
    .length(16, 'Количество цифр должно быть 16'),
  name: stringSchema(),
  bankName: stringSchema(),
  year: stringSchema(4),
  cvc: Yup.string()
    .required('Обязательно')
    .length(3, 'Количество цифр должно быть 3'),
});
