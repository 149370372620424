import { Avatar, Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { IServiceSearchRequest } from 'store/hooks/services';

export const initialRequest: any = {
  ids: null,
  filters: {
    year: 0,
    inn: '',
    goverment: null,
    state: 0,
  },
  orderBy: {
    column: 1,
    order: 1,
  },
  pageInfo: {
    pageNumber: 1,
    pageSize: 15,
  },
};

const statuses = {
  1: 'Новая',
  2: 'Концелярия',
  3: 'Резолюция',
  4: 'Испольнение',
  5: 'Подготовка документов',
  6: 'Концелярия',
  7: 'Завершено',
  100: 'Удален'
}

const payStatuses = {
  0: 'Не оплачено',
  1: 'Оплачено',
  2: 'Частично оплачено'
}

export const initialFilters: Nullable<IServiceSearchRequest['filters']> = {
  organisationId: 0,
  state: 0,
  serviceId: null,
};

export const headerTitles: GridColDef[] = [
  {
    field: 'id',
    headerName: '№',
    flex: 0.5,
    sortable: false,
    filterable: false,
  },
  {
    field: 'service',
    headerName: 'Название сервиса',
    flex: 2,
    sortable: false,
    filterable: false,
    valueFormatter: (params) => {
      // console.log('Params', params.value);
      return params.value.value;
    },
  },
  {
    field: 'user',
    headerName: 'Создал',
    flex: 2,
    sortable: false,
    filterable: false,
    renderCell(params) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Avatar src={params.value?.avatar} />
          <Typography fontSize="15px">{params.value?.value}</Typography>
        </Box>
      );
    },
  },
  {
    field: 'state',
    headerName: 'Статус заявки',
    flex: 2,
    sortable: false,
    filterable: false,
    valueFormatter: (params) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore 
      return statuses[params.value as string] ? statuses[params.value as string] : 'не активен';
    },
  },
  {
    field: 'payState',
    headerName: 'Статус оплаты',
    flex: 2,
    sortable: false,
    filterable: false,
    valueFormatter: (params) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore 
      return payStatuses[params.value as string] ? payStatuses[params.value as string] : 'Статус не определен';
    },
  },
];
