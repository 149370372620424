import { IChatMessages, IChatResponse } from 'store/hooks/services/chat';

export const getMessagePath = (msgs: IChatMessages[], id: number) => {
  for (let i = 0; i < msgs.length; i++) {
    const idx = msgs[i].messages.findIndex((item) => item.id === id);

    if (idx != -1) {
      return [i, idx];
    }
  }

  return [];
};
