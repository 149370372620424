import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import { Box, Button, Divider, Typography, useMediaQuery } from '@mui/material';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import SelectForMonile from 'components/selectForMobile/SelectForMonile';
import { useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useMyServiceQuery } from 'store/hooks/services';
import { Carousel } from '../../../../components/ui/Carousel';
import { ServiceItem } from '../../../../components/ui/ServiceItem';
import { useDispatch } from '../../../../store';
import { getUserProfile } from '../../../../store/slices/credentials/actions';
import { moduleRoutes } from '../../../public/home/helpers/constants';
import { chartData } from './constants';
import { buttonSx, secondBoxSx } from './styles';

ChartJS.register(ArcElement, Tooltip, Legend);

const Services = () => {

  const { data: serviceList } = useMyServiceQuery({
    ids: null,
    orderBy: {
      orderColumn: 1,
      direction: 1,
    },
    pageInfo: {
      pageNumber: 1,
      pageSize: 100,
    },
    filters: {}
  });

  const dispatch = useDispatch();
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');

  useEffect(() => {
    dispatch(getUserProfile({}));
  }, []);

  return (
    <Box >
      <SelectForMonile />
      <Box
        sx={{ display: isSmallDevice ? 'none' : 'grid' }}
        className="lg:tw-grid tw-grid-cols-3"
      >
        <Box className="tw-col-span-2" sx={{ mb: 2 }}>
          <Typography color="primary" sx={{ fontSize: '22px' }}>
            Подтвержденная учетная запись
          </Typography>
        </Box>
        <Box>
          <Typography color="primary" sx={{ fontSize: '22px' }}>
            Диаграмма Бюджета
          </Typography>
        </Box>
      </Box>
      <Box className="lg:tw-grid tw-grid-cols-3" sx={{ mb: 12 }}>
        <Box>
          <Box sx={secondBoxSx}>
            <Typography
              color="primary"
              sx={{
                fontSize: '20px',
                textAlign: 'center',
                px: 4,
              }}
            >
              Фото организации или логотип
            </Typography>
            <PermIdentityOutlinedIcon sx={{ fontSize: '200px' }} />
            <Button sx={buttonSx}>Выбрать фото</Button>
          </Box>
        </Box>

        <Box sx={{ margin: '0 2rem', maxWidth: '400px' }}>
          <Typography
            color="primary"
            sx={{
              fontSize: isSmallDevice ? '16px' : '18px',
              mt: isSmallDevice ? '20px' : '0',
            }}
          >
            Название организации
          </Typography>
          <Typography
            sx={{
              fontSize: isSmallDevice ? '16px' : '18px',
              marginBottom: '1rem',
            }}
          >
            Грандио Бюджетная организация (Grandio Budgetary Corporation)
          </Typography>
          <Typography
            color="primary"
            sx={{ fontSize: isSmallDevice ? '16px' : '18px' }}
          >
            Документация:
          </Typography>
          <Typography sx={{ fontSize: isSmallDevice ? '16px' : '18px' }}>
            Устав организации Финансовый отчёт за последний квартал
            <Typography sx={{ fontSize: isSmallDevice ? '16px' : '18px' }}>
              Политика финансового контроля
            </Typography>
            <Typography sx={{ fontSize: isSmallDevice ? '16px' : '18px' }}>
              Правила и процедуры бюджетного планирования
            </Typography>
          </Typography>
        </Box>
        <Box
          sx={{
            mt: isSmallDevice ? '40px' : '0',
            boxShadow: '0 0 4px  rgba(0, 0, 0, 0.24)',
            display: 'inline-flex',
            borderRadius: '20px',
            flexDirection: 'column',
            alignItems: 'center',
            padding: isSmallDevice ? '0' : '1.5rem',
          }}
        >
          <Doughnut data={chartData} />;
        </Box>
      </Box>
      <Box
        sx={{
          display: isSmallDevice ? 'none' : 'flex',
          gap: 3,
          mt: 6,
          mb: 1,
          '& > div': {
            minWidth: '180px',
          },
        }}
      >
        <Box>
          <Button sx={{ borderRadius: '8px' }} fullWidth>
            К оплате
          </Button>
        </Box>
        <Box>
          <Button sx={{ borderRadius: '8px' }} fullWidth>
            По квитанции
          </Button>
        </Box>
        <Box>
          <Button
            variant="contained"
            sx={{
              borderRadius: '8px',
              'MuiButton-containedPrimary': {
                border: '1px solid #eaecee',
                backgroundColor: '#fff !important',
                color: '#0c1116',
              },
            }}
            fullWidth
          >
            История
          </Button>
        </Box>
      </Box>

      <Box position="relative" left={isSmallDevice ? '0' : '-40px'} pt={6}>
        <Divider sx={{ width: isSmallDevice ? '100%' : 'calc(100% + 80px)', }} />
      </Box>
      {serviceList?.items?.length > 0 &&
        <Box py={10}>
          <Carousel
            width={220}
            items={serviceList.items}
            renderView={(data: any) => (
              <Box width={isSmallDevice ? '100%' : 220} sx={{ p: 1, height: '100%' }}>
                <ServiceItem
                  key={data.id}
                  route={moduleRoutes[data.id] || ''}
                  name={data.name}
                />
              </Box>
            )}
          />
        </Box>}
    </Box>
  );
};

export default Services;
