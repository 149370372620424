import {
  Box,
  FormControl,
  FormHelperText,
  OutlinedInput,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { titleBoxSx } from './styles';

interface IInputField {
  label?: string;
  value?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  multiline?: boolean;
  minRows?: number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const InputField: FC<TextFieldProps> = ({
  label,
  sx,
  ...textFieldProps
}) => {
  return (
    <Box minHeight={56}>
      {label && <Typography sx={titleBoxSx}>{label}</Typography>}
      <TextField
        sx={{ ...sx }}
        InputProps={{
          sx: {
            minHeight: '44px',
            borderRadius: '8px',
            borderColor: '#EDEDED',
          },
        }}
        FormHelperTextProps={{
          sx: {
            fontSize: '10px',
          },
        }}
        fullWidth
        {...textFieldProps}
      />
    </Box>
  );
};
