import { useNavigate } from 'react-router-dom';
import { useDispatch } from '../../store';
import { logout } from '../../store/slices/credentials/actions';

export const useSignOut = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return () => {
    dispatch(
      logout(() => {
        navigate('/auth/login');
      })
    );
  };
};
