import axiosLib, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import {
  clearTokenFromStorage,
  getTokenFromStorage,
  setTokenToStorage,
} from './getTokenFromStorage';
import { ILoginRes } from '../../store/slices/credentials/models';
import authApi from '../../store/slices/credentials/service';

const axios = axiosLib.create();

const requestUseInterceptor = async (config: InternalAxiosRequestConfig) => {
  config.baseURL = process.env.REACT_APP_BASE_API_URL;

  const accessToken = getTokenFromStorage();

  if (accessToken) {
    config.headers.Authorization = 'Bearer ' + accessToken;
  }

  return config;
};

const redirectToAuth = () => {
  clearTokenFromStorage();
  window.location.href = window.location.origin;
};

export const responseErrorInterceptor = (error: AxiosError<any>) => {
  const token = getTokenFromStorage(true) as ILoginRes;
  if (error.response?.status === 401) {
    if (!token) {
      redirectToAuth();
    } else {
      authApi
        .refreshToken({ refreshToken: token.refreshToken })
        .then((res) => {
          setTokenToStorage(res.data);
        })
        .catch(() => redirectToAuth());
    }
  }
  toast.error(error.response?.data.Message || error.message);
};

// AXIOS
axios.interceptors.request.use(requestUseInterceptor, (error) => {
  Promise.reject(error);
});

// Handling error responses
axios.interceptors.response.use(
  (response) => response,
  responseErrorInterceptor
);

export default axios;
