import { generateOptions, generateStates } from 'shared/utils/generateOptions';

export enum UserStatus {
  New = 1,
  Done = 7,
}

export const UserName = {
  [UserStatus.New]: 'Не подтверждённая учётная запись',
  [UserStatus.Done]: 'Подтверждённая учётная запись',
};

export enum DocStatus {
  InternalPassportFront = 1,
  InternalPassportBack = 2,
  InternationalPassport = 3,
  DriverLicense = 4,
  SNILS = 5,
  INN = 6,
  CompSert = 7,
  CompINN = 8,
}

export const UserOptions = generateOptions(UserName);
export const userStates = generateStates(UserName);
