import { FC } from 'react';
import { Avatar, Box, Button, Checkbox, Typography } from '@mui/material';
import UploadIcon from 'shared/icons/UploadIcon';
import { FormikProps } from 'formik';
import { IInitialValues } from '../../schema';
import { downloadFile, downloadFileByUrl } from 'shared/utils/functions';

interface IServiceResultForm {
  formik: FormikProps<IInitialValues>;
}

export const ServiceResultForm: FC<IServiceResultForm> = ({ formik }) => {
  const { values } = formik;
  return (
    <Box sx={{ px: 3 }}>
      {values.readyFiles.map((url, idx) => {
        const params = new URLSearchParams(url);
        return (
          <Box
            key={idx}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px dashed #4676FB',
              borderRadius: '16px',
              gap: 4,
              px: '16px',
              py: '24px',
              mb: 2,
              cursor: 'pointer',
            }}
            onClick={() => {
              downloadFileByUrl(url, params.get('fileName') || `file${idx}`);
            }}
          >
            <Box
              key={params.get('fileName')}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <UploadIcon />
              <Box>
                <Typography fontSize="16px">
                  {params.get('fileName')}
                </Typography>
                <Typography fontSize="14px" color="#009688">
                  PDF не более 10MB
                </Typography>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
