import { Box } from '@mui/material';

interface Props {
  children: React.ReactNode;
  bg?: string;
  onClick?: () => void;
}

function TableCellToRow(props: Props) {
  const { bg = 'white', onClick } = props;
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        p: 3,
        my: 2,
        bgcolor: bg,
        borderRadius: 2,
        position: 'relative',
        boxShadow:
          '0px 3px 8px -1px rgba(50, 50, 71, 0.05), 0px 0px 1px 0px rgba(12, 26, 75, 0.24)',
        border: '2px solid red',
        '&:hover': {
          opacity: 0.8,
          cursor: 'pointer',
        },
      }}
      onClick={() => {
        onClick?.();
      }}
    >
      {props.children}
    </Box>
  );
}

export default TableCellToRow;
