import BaseIcon from './BaseIcon';
type Props = {
  fill?: string;
  stroke?: string;
};
const UploadIcon = (props: any & Props) => {
  return (
    <svg
      width="42"
      height="35"
      viewBox="0 0 46 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.9375 27.2344L22.9995 19.2344L15.0615 27.2344"
        stroke="#009688"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23 19.2344V37.2344"
        stroke="#009688"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M39.6501 32.0173C41.5857 30.9538 43.1147 29.271 43.9959 27.2345C44.8771 25.198 45.0603 22.9237 44.5165 20.7707C43.9727 18.6176 42.733 16.7083 40.993 15.3442C39.2529 13.98 37.1117 13.2387 34.9072 13.2373H32.4067C31.806 10.8958 30.6865 8.72194 29.1322 6.87926C27.5779 5.03658 25.6293 3.57297 23.433 2.59849C21.2367 1.624 18.8497 1.16399 16.4516 1.25304C14.0535 1.34208 11.7066 1.97787 9.58733 3.1126C7.46811 4.24733 5.63171 5.85148 4.21619 7.80444C2.80067 9.75739 1.84287 12.0083 1.41479 14.3881C0.986713 16.7678 1.0995 19.2143 1.74468 21.5438C2.38985 23.8733 3.55063 26.025 5.13973 27.8373"
        stroke="#009688"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30.9375 27.2344L22.9995 19.2344L15.0615 27.2344"
        stroke="#009688"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default UploadIcon;
