import { ILegalCreate, IUserCreate } from '../../../store/hooks/users';
import * as Yup from 'yup';
import {
  innSchema,
  phoneSchema,
  stringSchema,
} from '../../../shared/utils/yup';
import { phoneRegexp, phoneRegexpCompleted } from '../login/helpers/constants';

export type IInitialValues = IUserCreate & {
  phoneCodeSended: boolean;
  codeChecked: boolean;
  code: string;
};

export type KeyOfInitials = keyof IInitialValues;

export const initialValues: IInitialValues = {
  name: '',
  surName: '',
  patronicName: '',
  login: '',
  passWord: '',
  confirmPassWord: '',
  inn: '',
  phone: '',
  email: '',
  passportNumber: '',
  address: '',
  imageUrl: '',
  phoneCodeSended: false,
  codeChecked: false,
  code: '',
};

export const validationSchema = Yup.object({
  name: stringSchema(),
  surName: stringSchema(),
  // login: stringSchema(),
  passWord: stringSchema(5),
  confirmPassWord: Yup.mixed()
    .required('Обязательно')
    .when('passWord', (pass: any, schema: any) => {
      if (pass) {
        return schema.test({
          test: (value: string) => {
            return value === pass[0];
          },
          message: 'Парольи не совпадають',
        });
      }

      return schema;
    }),
  inn: innSchema(),
  phone: Yup.string()
    .required('Обязательно')
    .test({
      test: (value) => phoneRegexpCompleted?.test(value),
      message: 'Неправильный формат телефона: 992XXXXXXXXX',
    }),
  passportNumber: stringSchema(),
  code: stringSchema(),
});

export type IInitialLegalValues = ILegalCreate & {
  phoneCodeSended: boolean;
  codeChecked: boolean;
  code: string;
};

export type KeyOfLegalInitials = keyof IInitialLegalValues;

export const initialLegalValues: IInitialLegalValues = {
  orgName: '',
  orgINN: '',
  orgAdress: '',
  name: '',
  surName: '',
  patronicName: '',
  login: '',
  passWord: '',
  confirmPassWord: '',
  inn: '',
  phone: '',
  email: '',
  passportNumber: '',
  address: '',
  imageUrl: '',
  phoneCodeSended: false,
  codeChecked: false,
  code: '',
};

export const validationLegalSchema = Yup.object({
  orgName: stringSchema(),
  orgINN: innSchema(),
  orgAdress: stringSchema(),
  name: stringSchema(),
  surName: stringSchema(),
  // login: stringSchema(),
  passWord: stringSchema(5),
  confirmPassWord: Yup.mixed()
    .required('Обязательно')
    .when('passWord', (pass: any, schema: any) => {
      if (pass) {
        return schema.test({
          test: (value: string) => {
            return value === pass[0];
          },
          message: 'Парольи не совпадають',
        });
      }

      return schema;
    }),
  inn: innSchema(),
  phone: Yup.string()
    .required('Обязательно')
    .test({
      test: (value) => phoneRegexpCompleted?.test(value),
      message: 'Неправильный формат телефона: 992XXXXXXXXX',
    }),
  passportNumber: stringSchema(),
  code: stringSchema(),
});
