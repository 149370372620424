import { SxProps } from '@mui/material';

export const containerSx: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '80px 0',
};

export const paperSx = (isSmallScreen: boolean): SxProps => ({
  padding: isSmallScreen ? '48px 20px' : '64px 48px',
  position: 'relative',
});

export const typographySx: SxProps = {
  color: 'black',
  textAlign: 'center',
  fontSize: '32px',
  fontWeight: '700',
  marginBottom: '40px',
};

export const actionContainerSx: SxProps = {
  position: 'absolute',
  bottom: '-30px',
  left: '50%',
  transform: 'translateX(-50%)',
};
