import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import ErrorIcon from '@mui/icons-material/Error';
import {
  AvatarGroup,
  Badge,
  Button,
  ListItem,
  ListItemButton,
  ListItemText,
  OutlinedInput,
  useMediaQuery,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import { FC, useState } from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {
  IUserprofileDetails,
  useGetUserprofileDetailsQuery,
} from 'store/hooks/userprofile';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { useFetchHotLineEmailQuery, useFetchHotLinePhoneQuery } from 'store/hooks/general';

interface IMainMenu {
  onLogout: () => void;
  isDrawer?: boolean;
  closeDrawer?: () => void;
}

const MainMenu: FC<IMainMenu> = ({ onLogout, isDrawer = false, closeDrawer }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userDetails] = useLocalStorage<IUserprofileDetails>(
    'userDetails',
    {} as IUserprofileDetails
  );
  const { data: email } = useFetchHotLineEmailQuery("")
  const { data: phone } = useFetchHotLinePhoneQuery("")
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: profileData, refetch } = useGetUserprofileDetailsQuery();

  const [searchbarExpanded, setSearchbarExpanded] = useState(false);
  const navTypographyParams: any = {
    variant: 'h6',
    color: 'primary',
    fontWeight: 700,
    sx: { fontSize: '1.6rem' },
  };



  if (isDrawer) {
    return (
      <>
        <Tooltip title="Account settings" disableHoverListener>
          <div className="tw-relative">
            <div
              className={`tw-absolute tw-z-20 ${profileData?.authorized && profileData?.displayName
                ? 'tw-top-[-7px] tw-left-12'
                : profileData?.authorized && !profileData?.displayName
                  ? 'tw-top-[-11.5px] tw-left-[60px]'
                  : !profileData?.authorized && profileData?.displayName
                    ? 'tw-top-[-11px] tw-left-12'
                    : 'tw-top-[-10px] tw-left-[60px]'
                }`}
            >
              {profileData?.authorized ? (
                <GridCheckCircleIcon fontSize="medium" color="success" />
              ) : (
                <ErrorIcon fontSize="medium" color="error" />
              )}
            </div>
            <Button
              startIcon={
                <>
                  <Avatar
                    src={profileData?.avatar || '/assets/images/ava.png'}
                    sx={{ width: 30, height: 30 }}
                  />
                </>
              }
              endIcon={<KeyboardArrowDownIcon />}
              onClick={handleClick}
              size="small"
              sx={{ minWidth: '150px', textTransform: 'none' }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Typography variant="h6" color="primary" sx={{ mb: 0, color: '#fff' }}>
                {profileData?.displayName || ''}
              </Typography>
            </Button>
          </div>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          sx={{
            p: 0,
            borderRadius: 1,
          }}
          MenuListProps={{
            sx: {
              p: 0,
            },
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              borderRadius: '10px !important',
              minWidth: '150px',
              maxWidth: '200px',
              overflow: 'hidden',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
              mt: 1.5,
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {userDetails?.roles?.includes(1) && (
            <Link to="/admin" onClick={() => {
              closeDrawer?.();
            }}>
              <ListItem disablePadding>
                <ListItemButton sx={{ gap: 1, justifyContent: 'start' }}>
                  <AccountCircleIcon />
                  <ListItemText sx={{ flex: 1 }} primary="Администрирование" />
                </ListItemButton>
              </ListItem>
            </Link>
          )}
          <Link to="/profile/account" style={{ textDecoration: 'none' }} onClick={() => {

            closeDrawer?.();
          }}>
            <ListItem disablePadding>
              <ListItemButton sx={{ gap: 1, justifyContent: 'start' }}>
                <AccountCircleIcon />
                <ListItemText sx={{ flex: 1 }} primary="Профиль" />
              </ListItemButton>
            </ListItem>
          </Link>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              onClick={onLogout}
              sx={{ gap: 1, justifyContent: 'start' }}
            >
              <Logout />
              <ListItemText sx={{ flex: 1 }} primary="Выход" />
            </ListItemButton>
          </ListItem>
        </Menu>
      </>
    );
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {isSmallDevice ? null : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '16px',
              marginRight: '16px',
            }}
          >
            <Link to="/">
              <Typography {...navTypographyParams}>Все услуги</Typography>
            </Link>
            <Link to="/my-services">
              <Typography {...navTypographyParams}>Мои услуги</Typography>
            </Link>
            {/* <Typography {...navTypographyParams}>Документы</Typography>
          <Typography {...navTypographyParams}>Платежи</Typography>
          <Typography {...navTypographyParams}>Помощь</Typography> */}
          </Box>
        )}

        {isSmallDevice ? (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '2px' }}>
            <IconButton sx={{ padding: '4px' }}>
              <SearchIcon sx={{ fontSize: 24 }} />
            </IconButton>
            <IconButton sx={{ padding: '4px' }}>
              <NotificationsNoneIcon sx={{ fontSize: 24 }} />
            </IconButton>
          </Box>
        ) : (
          <Box
            sx={{
              transition: 'width .3s ease',
              width: searchbarExpanded ? '280px' : '100px',
            }}
          >
            {/* <OutlinedInput
              fullWidth
              inputProps={{
                style: {
                  backgroundColor: 'transparent !important',
                },
              }}
              sx={{
                height: '38px',
                borderRadius: 5,
                '& input:-internal-autofill-selected': {
                  backgroundColor: 'transparent !important',
                },

                '& input:-webkit-autofill': {
                  boxShadow: 'none',
                },
              }}
              placeholder="Поиск"
              startAdornment={<SearchIcon />}
              onFocus={() => setSearchbarExpanded(true)}
              onBlur={() => setSearchbarExpanded(false)}
            /> */}
          </Box>
        )}
        {isSmallDevice ? null : (
          <Box>
            {/* <IconButton sx={{ mx: 2 }}>
              <NotificationsNoneIcon fontSize="large" />
            </IconButton> */}

            <div className='tw-flex tw-gap-8'>

              <div className='tw-flex tw-gap-8'>
                <div className='tw-flex tw-flex-col'>
                  <Typography variant='body2'>
                    Телефон центра поддержки:
                  </Typography>
                  {phone?.length > 0 && phone.map((item: string) => <a href={`tel:${item}`} key={item}>{item}</a>)}
                </div>
                <div className='tw-flex tw-flex-col'>
                  <Typography variant='body2'>
                    Электроный адрес:
                  </Typography>
                  {email?.length > 0 && email.map((item: string) => <a href={`mailto:${item}`} key={item}>{item}</a>)}
                </div>
              </div>
              <Tooltip title="Account settings" disableHoverListener>
                <div className="tw-relative">
                  <div
                    className={`tw-absolute tw-z-20 ${profileData?.authorized && profileData?.displayName
                      ? 'tw-top-[-7px] tw-left-12'
                      : profileData?.authorized && !profileData?.displayName
                        ? 'tw-top-[-11.5px] tw-left-[60px]'
                        : !profileData?.authorized && profileData?.displayName
                          ? 'tw-top-[-11px] tw-left-12'
                          : 'tw-top-[-10px] tw-left-[60px]'
                      }`}
                  >
                    {profileData?.authorized ? (
                      <GridCheckCircleIcon fontSize="medium" color="success" />
                    ) : (
                      <ErrorIcon fontSize="medium" color="error" />
                    )}
                  </div>
                  <Button
                    startIcon={
                      <>
                        <Avatar
                          src={profileData?.avatar || '/assets/images/ava.png'}
                          sx={{ width: 30, height: 30 }}
                        />
                      </>
                    }
                    endIcon={<KeyboardArrowDownIcon />}
                    onClick={handleClick}
                    size="small"
                    sx={{ minWidth: '150px', textTransform: 'none' }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <Typography variant="h6" color="primary" sx={{ mb: 0 }}>
                      {profileData?.displayName || ''}
                    </Typography>
                  </Button>
                </div>
              </Tooltip>
            </div>
          </Box>
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          p: 0,
          borderRadius: 1,
        }}
        MenuListProps={{
          sx: {
            p: 0,
          },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: '10px !important',
            minWidth: '150px',
            maxWidth: '200px',
            overflow: 'hidden',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
            mt: 1.5,
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {profileData?.roles?.includes(1) && (
          <Link to="/admin" onClick={() => {
            closeDrawer?.();

          }}>
            <ListItem disablePadding>
              <ListItemButton sx={{ gap: 1, justifyContent: 'start' }}>
                <AccountCircleIcon />
                <ListItemText sx={{ flex: 1 }} primary="Администрирование" />
              </ListItemButton>
            </ListItem>
          </Link>
        )}
        <Link to="/profile/account" style={{ textDecoration: 'none' }} onClick={() => {
          closeDrawer?.();

        }}>
          <ListItem disablePadding>
            <ListItemButton sx={{ gap: 1, justifyContent: 'start' }}>
              <AccountCircleIcon />
              <ListItemText sx={{ flex: 1 }} primary="Профиль" />
            </ListItemButton>
          </ListItem>
        </Link>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton
            onClick={onLogout}
            sx={{ gap: 1, justifyContent: 'start' }}
          >
            <Logout />
            <ListItemText sx={{ flex: 1 }} primary="Выход" />
          </ListItemButton>
        </ListItem>
      </Menu>
    </React.Fragment>
  );
};

export default MainMenu;
