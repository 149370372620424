export const blocks = [
  {
    img: '/assets/images/gerb.png',
    title: 'Чаримахои Вазорати Молияи ЧТ',
    btn: 'Перейти',
  },
  {
    img: '/assets/images/gerb.png',
    title: 'Назорати Давлатии Иёргирии Назди ВМ ЧТ',
    btn: 'Перейти',
  },

  {
    img: '/assets/images/gerb.png',
    title: 'Барои Китобхои МФСО ВМ ЧТ',
    btn: 'Перейти',
  },
];

export const chartData = {
  labels: ['Прибыль', 'Расход', ''],
  datasets: [
    {
      label: 'Процент ',
      data: [12, 19, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.19)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
      ],
      borderWidth: 1,
    },
  ],
};
