export const generateOptions = (records: Record<string, string>) =>
  Object.keys(records).map((key) => ({
    id: key,
    value: records[key],
  })) as ISelectOption[];

export const generateStates = (records: Record<number, string>) =>
  Object.keys(records).map((key) => ({
    id: Number(key),
    name: records[key as any],
  })) as StateType[];
