import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useFormik } from 'formik';
import { KeyofInitialValues, initialValues, validationSchema } from './schema';
import generator from 'generate-password-browser';
import {
  IChangePasswordRequest,
  useUpdateChangePasswordMutation,
} from '../../../../../../store/hooks/users';
import { getFieldErrors } from '../../../../../../shared/utils/yup';
import { IInitialValues } from './schema';
import { toastPromise } from '../../../../../../shared/utils/toastPromise';

interface IChangePasswordForm {
  toggleModal: (formik?: any) => void;
}
export const ChangePasswordForm: FC<IChangePasswordForm> = ({
  toggleModal,
}) => {
  const [changePassword] = useUpdateChangePasswordMutation();

  const [visibilityOldPassword, setVisibilityOldPassword] = useState(false);
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const [visibilityNewPassword, setVisibilityNewPassword] = useState(false);
  const [visibilityNewConfirmPassword, setVisibilityNewConfirmPassword] =
    useState(false);
  const [passwordGenerator, setPasswordGenerator] = useState('');

  const password = useMemo(() => {
    return generator.generate({
      length: 11,
      numbers: true,
      symbols: true,
      lowercase: true,
      uppercase: true,
    });
  }, [passwordGenerator]);

  const formik = useFormik<IInitialValues>({
    initialValues,
    validationSchema,
    onSubmit(values) {
      const payload = {
        oldPassWord: values.oldPassWord,
        newPassWord: values.newPassWord,
        passWordAgain: values.newPasswordConfirmation,
      };

      const promise = changePassword(payload);

      toastPromise(
        promise,
        {
          pending: 'Новый пароль сохраняется',
          success: 'Новый пароль сохранен',
          error: 'Произошла ошибка',
        },
        {
          then: () => {
            formik.resetForm();
            toggleModal();
          },
        }
      );
    },
  });

  const { values, setFieldValue, handleBlur } = formik;

  const handlers = {
    get(key: KeyofInitialValues) {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFieldValue(key, value);
      };
    },
  };

  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, []);

  return (
    <Box>
      <Box
        sx={{
          padding: isSmallDevice ? '-30px' : '0px',
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          mb: '8px',
        }}
      >
        <Box sx={{ height: '62px' }}>
          <TextField
            fullWidth
            name="oldPassWord"
            label="Старый пароль"
            type={visibilityOldPassword ? 'text' : 'password'}
            size="small"
            value={values.oldPassWord}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() =>
                    setVisibilityOldPassword(!visibilityOldPassword)
                  }
                >
                  {visibilityOldPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              ),
            }}
            onBlur={handleBlur}
            {...getFieldErrors(formik, 'oldPassWord')}
            onChange={handlers.get('oldPassWord')}
          />
        </Box>
        <Box sx={{ height: '62px' }}>
          <TextField
            fullWidth
            name="newPassWord"
            label="Новый пароль"
            size="small"
            value={values.newPassWord}
            type={visibilityNewPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() =>
                    setVisibilityNewPassword(!visibilityNewPassword)
                  }
                >
                  {visibilityNewPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              ),
            }}
            onBlur={handleBlur}
            {...getFieldErrors(formik, 'newPassWord')}
            onChange={handlers.get('newPassWord')}
          />
        </Box>
      </Box>

      <Box sx={{ marginBottom: '4rem', height: '62px' }}>
        <TextField
          fullWidth
          name="passWordAgain"
          value={values.newPasswordConfirmation}
          type={visibilityNewConfirmPassword ? 'text' : 'password'}
          label="Новый пароль ещё раз"
          size="small"
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() =>
                  setVisibilityNewConfirmPassword(!visibilityNewConfirmPassword)
                }
              >
                {visibilityNewConfirmPassword ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </IconButton>
            ),
          }}
          onBlur={handleBlur}
          {...getFieldErrors(formik, 'newPasswordConfirmation')}
          onChange={handlers.get('newPasswordConfirmation')}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: isSmallDevice ? '10px' : 0,
        }}
      >
        <Button
          sx={{ borderRadius: '8px' }}
          variant="outlined"
          onClick={() => toggleModal(formik)}
        >
          Отмена
        </Button>
        <Button
          onClick={() => formik.submitForm()}
          sx={{ borderRadius: '8px' }}
          variant="contained"
        >
          Сохранить
        </Button>
      </Box>
    </Box>
  );
};
