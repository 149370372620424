import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { mainStyles } from './style';
import Logo from '../../components/ui/Logo';
import { getTokenFromStorage } from '../../shared/utils/getTokenFromStorage';
import SnowFlake from '../../components/ui/SnowFlake';
import './index.scss';
import { useFetchHotLineEmailQuery, useFetchHotLinePhoneQuery } from 'store/hooks/general';
import { supportStyles } from 'layouts/private/styles';

interface IPublicLayout {
  children?: ReactNode;
}

const PublicLayout: FC<IPublicLayout> = ({ children }) => {
  console.log(
    ' process.env.REACT_APP_SEASON_ANIMATION',
    process.env.REACT_APP_SEASON_ANIMATION
  );
  const isAnimationAllowed = parseInt(
    process.env.REACT_APP_SEASON_ANIMATION || ''
  );
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const { data: email } = useFetchHotLineEmailQuery("")
  const { data: phone } = useFetchHotLinePhoneQuery("")
  if (getTokenFromStorage()) {
    return <Navigate to={'/services'} />;
  }

  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      {isSmallDevice && <div style={supportStyles}>
        <div className='tw-flex tw-gap-8 tw-py-2 tw-justify-center tw-w-full '>
          <div className='tw-flex tw-flex-col'>
            <Typography variant='body2'>
              Телефон центра поддержки:
            </Typography>
            {phone?.length > 0 && phone.map((item: string) => <a href={`tel:${item}`} key={item}>{item}</a>)}
          </div>
          <div className='tw-flex tw-flex-col'>
            <Typography variant='body2'>
              Электроный адрес:
            </Typography>
            {email?.length > 0 && email.map((item: string) => <a href={`mailto:${item}`} key={item}>{item}</a>)}
          </div>
        </div>
      </div>}
      <header className="headerStyles">
        <Container>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Logo />
            {!isSmallDevice && <div className='tw-flex tw-gap-8 tw-items-center'>
              <div className='tw-flex tw-flex-col'>
                <Typography variant='body2' >
                  Телефон центра поддержки:
                </Typography>
                {phone?.length > 0 && phone.map((item: string) => <a href={`tel:${item}`} key={item}>{item}</a>)}
              </div>
              <div className='tw-flex tw-flex-col'>
                <Typography variant='body2'>
                  Электроный адрес:
                </Typography>
                {email?.length > 0 && email.map((item: string) => <a href={`mailto:${item}`} key={item}>{item}</a>)}
              </div>
            </div>}
          </Box>
        </Container>
      </header>
      <main style={mainStyles}>
        {isAnimationAllowed > 0 && <SnowFlake />}
        <Outlet />
      </main>
    </Box>
  );
};

export default PublicLayout;
