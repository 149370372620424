import React, { CSSProperties, FC, useState } from 'react';
import { Box, Button, IconButton, TextField, Typography, useMediaQuery } from '@mui/material';
import CustomField from '../../../../components/forms/CustomField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CustomButton from '../../../../components/ui/CustomButton';
import { FormikProps, useFormik } from 'formik';
import { IUserCreate } from '../../../../store/hooks/users';
import { IInitialValues, KeyOfInitials } from '../schema';
import { getFieldErrors } from '../../../../shared/utils/yup';
import { useDispatch } from '../../../../store';
import { getCode } from '../../../../store/slices/credentials/actions';
import {
  ICheckCodeReq,
  IGetCodeReq,
} from '../../../../store/slices/credentials/models';
import { AuthType, UserType } from '../../login';
import { toast } from 'react-toastify';
import authApi from '../../../../store/slices/credentials/service';
import { phoneRegexp } from '../../login/helpers/constants';

interface IUserForm {
  formik: FormikProps<IInitialValues>;
}

const UserForm: FC<IUserForm> = ({ formik }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [phoneCode, setPhoneCode] = useState(false);
  const dispatch = useDispatch();

  const inputWithoutIcon: CSSProperties = {
    paddingLeft: '2rem',
    fontWeight: 400,
    height: '60px',
    fontSize: '1.4rem',
  };

  const labelWithoutIcon: CSSProperties = {
    paddingLeft: '1rem',
    fontSize: '1.4rem',
  };

  const { values, errors, touched, setFieldValue, setFieldTouched } = formik;
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const isGetCodeDisabled = () => {
    console.log('klsdjflk', touched, errors);
    console.log(values)
    return [
      'name',
      'surName',
      'passWord',
      'confirmPassWord',
      'inn',
      'phone',
      'passportNumber',
    ].some(
      (key) =>
        !values[key as KeyOfInitials] || Boolean(errors[key as KeyOfInitials])
    );
  };


  const getHandler = (key: string, regexp?: RegExp) => (e: any) => {
    const value = e.target.value;
    if (regexp && !regexp.test(value)) {
      return;
    }
    setFieldValue(key, value);
  };

  const getPhone = (key = 'phone') => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;

      if (value.length === 3) {
        setFieldValue(key, '');
        return;
      }

      if (!value.startsWith('992')) {
        value = '992' + value;
      }

      if (value.length > 12 || !phoneRegexp.test(value)) return;

      setFieldValue(key, value);
    };
  };

  const getHandleBlur = (key: string) => () => {
    setFieldTouched(key, true);
  };

  const handleGetCode = () => {
    const data: IGetCodeReq = {
      login: values.phone,
      password: values.passWord || '',
      userType: UserType.individual,
      authType: AuthType.registration,
    };
    dispatch(
      getCode({
        data,
        onSuccess: () => {
          setFieldValue('phoneCodeSended', true);
          toast.success(`Код успешно отправлен на номер ${values.phone}`);
        },
      })
    );
  };

  const handleCheckCode = (code: string) => {
    const data: ICheckCodeReq = {
      login: values.phone,
      code: Number(code),
      authType: AuthType.registration,
    };
    const toastId = toast.loading('Проверяется введенный вами код');
    authApi.checkCode(data).then((res) => {
      toast.dismiss(toastId);
      if (res.status === 200) {
        toast.success('Проверка кода успешно');
        setFieldValue('codeChecked', true);
      }
    });
  };


  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: isSmallDevice ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)',
        gap: isSmallDevice ? 2 : 4,
      }}
    >
      <CustomField
        label="Имя"
        inputWithoutIcon={inputWithoutIcon}
        labelWithoutIcon={labelWithoutIcon}
        value={values.name}
        onChange={getHandler('name')}
        onBlur={getHandleBlur('name')}
        {...getFieldErrors(formik, 'name')}
      />

      <CustomField
        label="Фамилия"
        inputWithoutIcon={inputWithoutIcon}
        labelWithoutIcon={labelWithoutIcon}
        value={values.surName}
        onChange={getHandler('surName')}
        onBlur={getHandleBlur('surName')}
        {...getFieldErrors(formik, 'surName')}
      />
      <CustomField
        label="Отчество"
        inputWithoutIcon={inputWithoutIcon}
        labelWithoutIcon={labelWithoutIcon}
        value={values.patronicName}
        onChange={getHandler('patronicName')}
      />
      <CustomField
        label="Серийный номер паспорта"
        inputWithoutIcon={inputWithoutIcon}
        labelWithoutIcon={labelWithoutIcon}
        value={values.passportNumber}
        onChange={getHandler('passportNumber')}
        onBlur={getHandleBlur('passportNumber')}
        autoComplete="new-password"
        {...getFieldErrors(formik, 'passportNumber')}
      />
      <CustomField
        inputWithoutIcon={inputWithoutIcon}
        labelWithoutIcon={labelWithoutIcon}
        label="Пароль"
        type={passwordVisible ? 'text' : 'password'}
        autoComplete="new-password"
        endIcon={
          <IconButton onClick={() => setPasswordVisible(!passwordVisible)}>
            {passwordVisible ? (
              <VisibilityOffIcon color="primary" fontSize="large" />
            ) : (
              <VisibilityIcon color="primary" fontSize="large" />
            )}
          </IconButton>
        }
        value={values.passWord}
        onChange={getHandler('passWord')}
        onBlur={getHandleBlur('passWord')}
        {...getFieldErrors(formik, 'passWord')}
      />
      <CustomField
        inputWithoutIcon={inputWithoutIcon}
        labelWithoutIcon={labelWithoutIcon}
        label="Подтвердите пароль"
        type={confirmPasswordVisible ? 'text' : 'password'}
        endIcon={
          <IconButton
            onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
          >
            {confirmPasswordVisible ? (
              <VisibilityOffIcon color="primary" fontSize="large" />
            ) : (
              <VisibilityIcon color="primary" fontSize="large" />
            )}
          </IconButton>
        }
        value={values.confirmPassWord}
        onChange={getHandler('confirmPassWord')}
        onBlur={getHandleBlur('confirmPassWord')}
        {...getFieldErrors(formik, 'confirmPassWord')}
      />

      <CustomField
        label="ИНН"
        inputWithoutIcon={inputWithoutIcon}
        labelWithoutIcon={labelWithoutIcon}
        value={values.inn}
        onChange={getHandler('inn', /^[0-9]{0,9}$/)}
        onBlur={getHandleBlur('inn')}
        {...getFieldErrors(formik, 'inn')}
      />

      <CustomField
        inputWithoutIcon={inputWithoutIcon}
        labelWithoutIcon={labelWithoutIcon}
        label="Email"
        value={values.email}
        onChange={getHandler('email')}
        onBlur={getHandleBlur('email')}
        {...getFieldErrors(formik, 'email')}
      />

      <CustomField
        inputWithoutIcon={inputWithoutIcon}
        labelWithoutIcon={labelWithoutIcon}
        label="Телефон"
        value={values.phone}
        onChange={getPhone('phone')}
        onBlur={getHandleBlur('phone')}
        {...getFieldErrors(formik, 'phone')}
      />
      {values.phoneCodeSended ? (
        <CustomField
          inputWithoutIcon={inputWithoutIcon}
          labelWithoutIcon={labelWithoutIcon}
          label="Код подтверждения"
          value={values.code.toString()}
          onChange={(e: any) => {
            const value = e.target.value;
            const regexp = /^[0-9]{0,4}$/;

            if (!regexp.test(value)) {
              return;
            }

            setFieldValue('code', value);
            if (value.length === 4) {
              handleCheckCode(value);
            }
          }}
          onBlur={getHandleBlur('code')}
          {...getFieldErrors(formik, 'code')}
        />
      ) : (
        <CustomButton
          variant="contained"
          disabled={isGetCodeDisabled()}
          onClick={handleGetCode}
          sx={{
            boxShadow: 'none',
            textTransform: 'unset',
            fontSize: '16px',
          }}
        >
          Получить код
        </CustomButton>
      )}
    </Box>
  );
};

export default UserForm;
