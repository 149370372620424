import { Box } from '@mui/material';
import { randomInt } from 'crypto';
import { ChangeEvent, FC, useRef } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';

interface IChatFileAttacher {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const ChatFileAttacher: FC<IChatFileAttacher> = ({ onChange }) => {
  const ref = useRef(Math.random().toString());
  return (
    <Box>
      <input
        className="tw-hidden"
        type="file"
        id={ref.current}
        onChange={onChange}
      />
      <label htmlFor={ref.current} className="tw-cursor-pointer">
        <AttachFileIcon fontSize="large" />
      </label>
    </Box>
  );
};
