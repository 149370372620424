import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Icon,
  InputLabel,
  LinearProgress,
  ListItemIcon,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import {
  boxSx,
  infoBoxSx,
  inputStyles,
  safeBoxSx,
  secondInfoBoxSx,
  subtitleSx,
  titleBoxSx,
  titleSx,
} from './styles';
import Modal from '../../../../components/ui/Modal';
import generator from 'generate-password-browser';
import {
  AvatarUrlBody,
  IUserprofileDetails,
  useChangeEmailMutation,
  useChangeInnMutation,
  useDeleteAvatarMutation,
  useGetUserprofileDetailsQuery,
  useSaveAvatarMutation,
} from '../../../../store/hooks/userprofile';
import { useSession } from '../../../../shared/hooks/useSession';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import ErrorIcon from '@mui/icons-material/Error';
import { ChangePasswordForm } from './components/ChangePasswordForm';
import { toast } from 'react-toastify';
import fileService from 'store/services/fileService';
import { menuItem } from '../constants';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SelectForMonile from 'components/selectForMobile/SelectForMonile';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

const Account = () => {
  const [modal, setModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editInnMode, setEditInnMode] = useState(false);
  const [email, setEmail] = useState('bekovparviz@gmail.com');
  const [inn, setInn] = useState('12345678');
  const [btnShow, setBtnShow] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [profile, setProfile] = useState<IUserprofileDetails>();
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const [, setUserData] = useLocalStorage<IUserprofileDetails>(
    'userDetails',
    {} as IUserprofileDetails
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const inputInnRef = useRef<HTMLInputElement>(null);
  const [passwordGenerator, setPasswordGenerator] = useState('');
  const { data: profileData, refetch, isLoading, isSuccess: ProfileSuccess } = useGetUserprofileDetailsQuery();
  const [changeEmail] = useChangeEmailMutation();
  const [changeInn] = useChangeInnMutation();
  const [saveAvatar] = useSaveAvatarMutation();
  const [deleteAvatar, { isSuccess }] = useDeleteAvatarMutation();
  const session = useSession();
  const [age, setAge] = useState('');
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const clicker = (path: string) => {
    navigate(path);
  };

  const handleChangeSelect = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  const toggleModal = (formik: any) => {
    setModal(!modal);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const clickHandler = () => {
    setEditMode(!editMode);
  };

  const clickHandlerInn = () => {
    setEditInnMode(!editInnMode);
  };

  const handleUploadFile = async (idx: number, event: HTMLInputElement) => {
    setFileLoading(true);
    const filesFormats = ['.png', '.jpg', '.jpeg', '.gif'];
    const file = event.files;

    if (!file) {
      return;
    }
    function ext(name: any) {
      return name.match(/\.([^.]+)$|$/)[1];
    }

    const isRightFormat = filesFormats.includes('.' + ext(file[0].name));
    if (!isRightFormat) {
      toast(
        'Вы можете загрузить только фотографии в формате .png, .jpg, .jpeg или .gif',
        { type: 'error', position: 'top-right' }
      );
      setFileLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append(idx.toString(), file[0]);

    await fileService
      .uploadFileV2(formData)
      .then((e) => {
        const res = e as { data: AvatarUrlBody };
        saveAvatar(res.data).then((i) => {
          refetch().then((r) => setProfile(r.data));
        });
      })
      .finally(() => {
        setFileLoading(false);
        setBtnShow(false);
      });
  };

  useEffect(() => {
    if (profileData) {
      setProfile(profileData);
      setEmail(profileData.email || '');
      setInn(profileData.inn || '');
      setUserData(profileData);
    }
    setBtnShow(false);
  }, [profileData]);

  useEffect(() => {
    if (editMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editMode]);

  const removeAvatar = () => {
    deleteAvatar({})
      .then(() => {
        refetch().then((r) => setProfile(r.data));
      })
      .catch((e) => {
        toast(`Ошибка удаления аватарки, ${e}`, {
          type: 'error',
          position: 'top-right',
        });
      });
  };

  const theme = useTheme();

  const password = useMemo(() => {
    return generator.generate({
      length: 11,
      numbers: true,
      symbols: true,
      lowercase: true,
      uppercase: true,
    });
  }, [passwordGenerator]);

  const checkInput = (e: any) => {
    if (e.target.value.trim().length >= 10) return;
    setInn(e.target.value.trim());
  };
  return (
    <Box>
      <SelectForMonile />
      <Box className="lg:tw-grid tw-grid-cols-5 tw-gap-2">
        <Box
          className="tw-relative lg:tw-w-[90%]  tw-w-[100%] lg:tw-col-span-2"
          sx={boxSx}
        >
          <div
            className="tw-relative  lg:tw-w-96 tw-h-96 tw-rounded-full tw-mb-4 tw-overflow-hidden tw-shadow-[0px_0px_2px_1px_rgba(0,0,0,0.5)]"
            onMouseEnter={() => !fileLoading && setBtnShow(true)}
            onMouseLeave={() => !fileLoading && setBtnShow(false)}
          >
            <img
              className="tw-w-full tw-h-full tw-object-cover"
              style={{
                transition: 'all 0.5s ease',
                opacity: btnShow || fileLoading ? '0.3' : '1',
              }}
              src={profile?.avatar || '/assets/images/ava.png'}
              alt=""
            />
            {fileLoading && (
              <i className="tw-absolute tw-top-1/2 -tw-translate-y-1/2  tw-left-1/2 -tw-translate-x-1/2">
                <CircularProgress size={60} />
              </i>
            )}
            {btnShow && !fileLoading && (
              <label
                className="tw-absolute tw-top-1/2 -tw-translate-y-1/2  tw-left-1/2 -tw-translate-x-1/2"
                htmlFor="file-upload"
              >
                <PhotoCameraIcon
                  className="tw-cursor-pointer"
                  color="primary"
                  style={{ fontSize: 80 }}
                />
                <input
                  id="file-upload"
                  type="file"
                  accept="*"
                  onChange={(e) =>
                    handleUploadFile(1, e.currentTarget as HTMLInputElement)
                  }
                  style={{ display: 'none' }}
                />
              </label>
            )}
          </div>
          {profile?.avatar && (
            <Button
              style={{ fontSize: 10 }}
              variant="contained"
              onClick={() => removeAvatar()}
            >
              Удалить аватар
            </Button>
          )}
          <Typography sx={titleSx}>
            {profile?.displayName || 'Unknown'}
          </Typography>
          {isLoading && <Stack sx={{ width: '100%', color: 'grey.500' }} ><LinearProgress color='primary' /></Stack>}
          {ProfileSuccess && <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {profile?.authorized ? (
              <GridCheckCircleIcon fontSize="large" color="success" />
            ) : (
              <ErrorIcon fontSize="large" color="error" />
            )}
            <Typography sx={subtitleSx}>
              {profile?.authorized
                ? 'Подтверждённая учётная запись'
                : ' Неподтверждённая учётная запись'}
            </Typography>
          </Box>}
          {ProfileSuccess && !profile?.authorized && (
            <Button
              variant="text"
              onClick={() => {
                navigate('/profile/documents');
              }}
            >
              пройти аутентификацию
            </Button>
          )}
        </Box>
        <Box
          className="tw-col-span-3"
          sx={{ display: 'flex', flexDirection: 'column', gap: '3.2rem' }}
        >
          <Box sx={{ marginTop: isSmallDevice ? '20px' : '0' }}>
            <Typography sx={titleBoxSx}>Тел</Typography>
            <Box sx={{ display: 'flex' }}>
              <Box sx={secondInfoBoxSx}>{profile?.phone}</Box>
            </Box>
          </Box>

          <Box>
            <Typography sx={titleBoxSx}>E-mail</Typography>

            <Box sx={{ display: 'flex' }}>
              {editMode ? (
                <form
                  onSubmit={(event: any) => {
                    event.preventDefault();
                    const target: any = event.target;

                    if (target[0] && target[0].value !== profile?.email) {
                      const promise = changeEmail({
                        id: session.user?.info?.id || 0,
                        email: target[0].value,
                      });

                      toast.promise(promise, {
                        pending: 'Новая почта сохраняется',
                        success: 'Почта успешно сохранена',
                        error: 'Произошла ошибка на сервере',
                      });
                    }
                  }}
                >
                  <input
                    required
                    type="email"
                    style={inputStyles}
                    name="email"
                    placeholder="Введите email"
                    ref={inputRef}
                  />
                  <Button
                    variant="contained"
                    sx={{ borderRadius: '0.8rem' }}
                    type="submit"
                  >
                    Сохранить
                  </Button>
                </form>
              ) : (
                <>
                  <Box sx={infoBoxSx}>{email}</Box>
                  <Button
                    variant="contained"
                    sx={{ borderRadius: '0.8rem' }}
                    onClick={clickHandler}
                  >
                    Изменить
                  </Button>
                </>
              )}
            </Box>
          </Box>
          <Box>
            <Typography sx={titleBoxSx}>ИНН</Typography>

            <Box sx={{ display: 'flex' }}>
              {editInnMode ? (
                <form
                  onSubmit={(event: any) => {
                    event.preventDefault();
                    const target: any = event.target;

                    if (target[0] && target[0].value !== profile?.inn) {
                      const promise = changeInn({
                        id: session.user?.info?.id || 0,
                        inn: target[0].value,
                      });

                      toast.promise(promise, {
                        pending: 'Новый инн сохраняется',
                        success: 'ИНН успешно сохранена',
                        error: 'Произошла ошибка на сервере',
                      });
                    }
                  }}
                >
                  <input
                    required
                    type="number"
                    style={inputStyles}
                    name="inn"
                    value={inn}
                    maxLength={9}
                    onChange={(e) => checkInput(e)}
                    placeholder="Введите ИНН"
                    ref={inputRef}
                  />
                  <Button
                    variant="contained"
                    sx={{ borderRadius: '0.8rem' }}
                    disabled={inn.length !== 9}
                    type="submit"
                  >
                    Сохранить
                  </Button>
                </form>
              ) : (
                <>
                  <Box sx={infoBoxSx}>{inn}</Box>
                  <Button
                    variant="contained"
                    sx={{ borderRadius: '0.8rem' }}
                    onClick={clickHandlerInn}
                  >
                    Изменить
                  </Button>
                </>
              )}
            </Box>
          </Box>
          {/* <Box
            sx={{
              padding: isSmallDevice ? 2 : '2.4rem',
              borderRadius: '0.8rem',
              border: '1px solid rgba(0, 0, 0, 0.31) ',
              display: 'flex',
              flexDirection: 'column',
              gap: '1.6rem',
            }}
          >
            <Typography fontSize={16} fontWeight={700} color="#607D8B">
              Как обезопасить свою учётную запись на Госуслугах?
            </Typography>
            <Typography fontSize={16}>
              1. Подключить вход с дополнительным способом подтверждения.
            </Typography>
            <Typography fontSize={16}>
              2. Подключить оповещение о входе на почту.
            </Typography>
            <Typography fontSize={16}>
              3. Настроить контрольный вопрос.
            </Typography>
          </Box> */}
        </Box>
      </Box>
      <Box className=" tw-pt-[20px] tw-flex tw-justify-center ">
        <Button
          sx={{ borderRadius: '0.8rem' }}
          variant="contained"
          onClick={toggleModal}
        >
          Сменить пароль
        </Button>
      </Box>
      <Modal modal={modal} title="Сменить пароль" toggleModal={toggleModal}>
        <ChangePasswordForm toggleModal={toggleModal} />
      </Modal>
    </Box>
  );
};

export default Account;
