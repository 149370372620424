import { SxProps } from '@mui/material';

export const secondBoxSx: SxProps = {
  boxShadow: '0 0 4px  rgba(0, 0, 0, 0.24)',
  display: 'inline-flex',
  borderRadius: '20px',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '1.5rem',
};

export const buttonSx: SxProps = {
  background: 'white',
  color: 'black',
  fontSize: '18px',
  boxShadow: '0 5px 40px rgba(0, 0, 0, 0.24)',
  borderRadius: '26px',
  width: '90%',
  padding: '1.5rem 0',

  '&:hover': {
    background: 'none',
  },
};
