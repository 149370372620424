export const questions = [
  'Свидетельство о государственной регистрации',
  'Свидетельство о праве на осуществление  деятелности',
  'Выписка из единого государственного реестра юридических лиц ',
  'Выписка из единого государственного реестра индивидуальной предпринимателей',
  'Заключенный договор',
  'Справка определяющая источник дохода',
  'Документ, определяющий источник доходов из иностранного государства',
  'Паспорт гражданина Республики Таджикистана',
  'Чек оплаты за услугу <<Документ подтверждающий статуса резидента РТ>>',
];

export const variants = [
  'Проверьте, что все документы предоставлены в полном объеме ',
  'Осуществите оплату за государственную услугу.',
  'Нажмите на кнопку “Оформить запрос” для оформления заявки на получение необходимого документа.',
  'Отслеживайте свой статус и ожидайте завершения процесса подготовки документа.',
];
