import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSession } from 'shared/hooks/useSession';
import { useIsAuthorizedQuery } from 'store/hooks/userprofile';

interface Props {
  serviceId: string;
}

function AuthButton(props: Props) {
  const { serviceId } = props;
  const { data: isAuthorized } = useIsAuthorizedQuery({});
  const { user } = useSession();

  const navigate = useNavigate();

  return (
    <Button
      size="large"
      sx={{ height: 48, borderWidth: 2 }}
      fullWidth
      variant="outlined"
      color="primary"
      onClick={() => {
        if (user) {
          if (isAuthorized) {
            navigate('application/create');
          } else {
            toast.error('Необходимо пройти аутентификацию.', {
              autoClose: 5000,
            });
            navigate('/profile/documents');
          }
        }
      }}
    >
      Оформить запрос
    </Button>
  );
}

export default AuthButton;
