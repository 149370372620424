import { useSearchParams } from 'react-router-dom';

const useUrlParams = (): any => {
  const [params, setParams] = useSearchParams();
  const paramsAsObject = Object.fromEntries([...(params as any)]);

  const setParamsHandle = (key: string, value: string) => {
    if (value) {
      params.set(key, value);
      setParams(params);
    } else if (params.has(key) && !value) {
      params.delete(key);
      setParams(params);
    }
  };

  return [paramsAsObject, setParamsHandle];
};

export default useUrlParams;
