import BaseIcon from './BaseIcon';
type Props = {
  fill?: string;
  stroke?: string;
};
const ListIcon = (props: any & Props) => {
  return (
    <BaseIcon {...props}>
      <g clipPath="url(#clip0_406_1957)">
        <path
          d="M7 6.00024H23C23.2652 6.00024 23.5196 5.89488 23.7071 5.70735C23.8946 5.51981 24 5.26546 24 5.00024C24 4.73503 23.8946 4.48067 23.7071 4.29314C23.5196 4.1056 23.2652 4.00024 23 4.00024H7C6.73478 4.00024 6.48043 4.1056 6.29289 4.29314C6.10536 4.48067 6 4.73503 6 5.00024C6 5.26546 6.10536 5.51981 6.29289 5.70735C6.48043 5.89488 6.73478 6.00024 7 6.00024Z"
          fill={props.fill || '#374957'}
        />
        <path
          d="M23 10.9998H7C6.73478 10.9998 6.48043 11.1051 6.29289 11.2927C6.10536 11.4802 6 11.7345 6 11.9998C6 12.265 6.10536 12.5193 6.29289 12.7069C6.48043 12.8944 6.73478 12.9998 7 12.9998H23C23.2652 12.9998 23.5196 12.8944 23.7071 12.7069C23.8946 12.5193 24 12.265 24 11.9998C24 11.7345 23.8946 11.4802 23.7071 11.2927C23.5196 11.1051 23.2652 10.9998 23 10.9998Z"
          fill={props.fill || '#374957'}
        />
        <path
          d="M23 18H7C6.73478 18 6.48043 18.1054 6.29289 18.2929C6.10536 18.4804 6 18.7348 6 19C6 19.2652 6.10536 19.5196 6.29289 19.7071C6.48043 19.8947 6.73478 20 7 20H23C23.2652 20 23.5196 19.8947 23.7071 19.7071C23.8946 19.5196 24 19.2652 24 19C24 18.7348 23.8946 18.4804 23.7071 18.2929C23.5196 18.1054 23.2652 18 23 18Z"
          fill={props.fill || '#374957'}
        />
        <path
          d="M2 6.99999C3.10457 6.99999 4 6.10456 4 5C4 3.89543 3.10457 3 2 3C0.89543 3 0 3.89543 0 5C0 6.10456 0.89543 6.99999 2 6.99999Z"
          fill={props.fill || '#374957'}
        />
        <path
          d="M2 14.0002C3.10457 14.0002 4 13.1048 4 12.0002C4 10.8957 3.10457 10.0002 2 10.0002C0.89543 10.0002 0 10.8957 0 12.0002C0 13.1048 0.89543 14.0002 2 14.0002Z"
          fill={props.fill || '#374957'}
        />
        <path
          d="M2 20.9997C3.10457 20.9997 4 20.1043 4 18.9997C4 17.8951 3.10457 16.9997 2 16.9997C0.89543 16.9997 0 17.8951 0 18.9997C0 20.1043 0.89543 20.9997 2 20.9997Z"
          fill={props.fill || '#374957'}
        />
      </g>
      <defs>
        <clipPath id="clip0_406_1957">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export default ListIcon;
