import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FC } from 'react';
import { FeedbackForm } from './components/FeedbackForm';
import { Faq } from './components/Faq';
import SelectForMonile from 'components/selectForMobile/SelectForMonile';

const Support: FC = () => {
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');

  return (
    <Box>
      <SelectForMonile />
      <FeedbackForm />
      {/* <Box pt={4}>
        <Faq />
      </Box> */}
      <Box mt={5}>
        {/* <Typography variant="h6">Обратитесь при помощи чата</Typography> */}
        <Box
          sx={{
            display: isSmallDevice ? 'flex' : '',
            justifyContent: isSmallDevice ? 'center' : '',
          }}
        >
          {/* <Button
            sx={{
              borderRadius: '5px',
              minWidth: '150px',
              marginTop: isSmallDevice ? '10px' : '',
            }}
            variant="contained"
          >
            Открыть чат
          </Button> */}
        </Box>
      </Box>
    </Box>
  );
};

export default Support;
