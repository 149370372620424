import { Box, Typography, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';

interface ISectionForm {
  title: string;
  emptyBox?: boolean;
  children?: ReactNode;
  isSmallScreen?: boolean
}

export const SectionForm: FC<ISectionForm> = ({
  title,
  children,
  emptyBox,
  isSmallScreen = false
}) => {
  const theme = useTheme();
  return (
    <Box mb={6}>
      <Box
        sx={{
          py: '12px',
          px: '24px',
          background: theme.palette.primary.main,
          mb: 3,
          borderRadius: '40px',
        }}
      >
        <Typography fontSize={isSmallScreen ? '14px' : '17px'} color="#fff" fontWeight={600} >
          {title}
        </Typography>
      </Box>
      {emptyBox ? (
        children
      ) : (
        <Box
          sx={{
            p: 4,
            borderRadius: '40px',
            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
            backgroundColor: '#fff',
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

interface ISectionBox {
  py?: number;
  px?: number;
  children: ReactNode;
}

export const SectionBox: FC<ISectionBox> = ({ py = 1, px, children }) => {
  return (
    <Box
      sx={{
        py,
        px,
        borderRadius: '40px',
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
        backgroundColor: '#fff',
      }}
    >
      {children}
    </Box>
  );
};
