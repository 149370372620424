import { ITabsSegmentItem } from '../../../../components/ui/CustomSegment';

export const tabsOptions: ITabsSegmentItem[] = [
  {
    label: 'Физическое лицо',
    value: 'individual',
    code: 1,
  },
  {
    label: 'Юридическое  лицо',
    value: 'entity',
    code: 2,
  },
];

export const phoneRegexp = /^[0-9]{1,12}$/;
export const phoneRegexpCompleted = /^[0-9]{12}$/;

export type TabsType = 'individual' | 'entity';
