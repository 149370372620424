export enum ActionsType {
  AUTH_LOGIN = 'auth/login',
  AUTH_GET_CODE = 'auth/get_code',
  Auth_CHECK_CODE = 'auth/check_code',
  AUTH_REFRESH_TOKEN = 'auth/refresh_token',
  AUTH_LOGOUT = 'auth/logout',
  AUTH_CODE_RESET_PASSWORD = 'auth/get_code_in_reset_password',

  // Application resident
  APPLICATION_RESIDENT_LIST = 'application_resident/list',

  // UserProfile
  GET_USER_PROFILE_DETAIL = 'get_user_profile/detail',
  RESET_PASSWORD = 'resetPassword',
}
