interface IDocumentItem {
  docType: DocumentTypes;
  docTypeName: string;
}

enum DocumentTypes {
  InternalPassport = 1,
  InternalPassportBack = 2,
  // InternationalPassport = 3,
  //DriverLicense = 4,
  // SNILS = 5,
  INN = 6,
  CompSert = 7,
  CompINN = 8
}

export const DocumentTypeNames: Record<DocumentTypes, string> = {
  [DocumentTypes.InternalPassport]: 'Паспорт РТ (Передняя сторона)',
  [DocumentTypes.InternalPassportBack]: 'Паспорт РТ (Задняя сторона)',
  // [DocumentTypes.SNILS]: 'СНИЛС',
  [DocumentTypes.INN]: 'ИНН',
  // [DocumentTypes.InternationalPassport]: 'Загранпаспорт',
  [DocumentTypes.CompSert]: 'Иктибос',
  [DocumentTypes.CompINN]: 'ИНН организации',

};

export const documentsMain = [
  DocumentTypes.InternalPassport,
  DocumentTypes.InternalPassportBack,
  // DocumentTypes.SNILS,
  DocumentTypes.INN,
  // DocumentTypes.InternationalPassport,
];

export const documentsAdditionals = [

  DocumentTypes.CompINN,
  DocumentTypes.CompSert,

];

export const mainDocuments: IDocumentItem[] = documentsMain.map(
  (key: DocumentTypes) => ({
    docType: +key,
    docTypeName: DocumentTypeNames[key],
  })
);

export const additionalDocuments: IDocumentItem[] = documentsAdditionals.map(
  (key: DocumentTypes) => ({
    docType: +key,
    docTypeName: DocumentTypeNames[key],
  })
);
