/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Avatar, Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import ErrorIcon from '@mui/icons-material/Error';

export const initialRequest: any = {
  ids: null,
  filters: {},
  orderBy: {
    column: 1,
    order: 1,
  },
  pageInfo: {
    pageNumber: 1,
    pageSize: 15,
  },
};

export enum UserType {
  Admin = 0,
  EGov_Individual = 1,
  EGov_LegalEntity = 2,
  SystemAdmin = 100,
}

export const statuses = {
  1: 'Новая',
  2: 'Концелярия',
  3: 'Резолюция',
  4: 'Испольнение',
  5: 'Подготовка документов',
  6: 'Концелярия',
  7: 'Завершено',
  100: 'Удален',
};

export const payStatuses = {
  0: 'Не оплачено',
  1: 'Оплачено',
  2: 'Частично оплачено',
};

export const userType = {
  0: 'Admin',
  1: 'Физическое лицо',
  2: 'Юридическое лицо',
};

export const initialFilters = {};

export const headerTitles: GridColDef[] = [
  {
    field: 'id',
    headerName: '№',
    flex: 0.5,
    sortable: false,
    filterable: false,
  },
  {
    field: 'userName',
    headerName: 'Имя',
    flex: 2,
    sortable: false,
    filterable: false,
    valueFormatter: (params) => {
      return params.value;
    },
  },
  {
    field: 'login',
    headerName: 'login',
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell(params) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Avatar src={params.value} />
          <Typography fontSize="15px">{params.value}</Typography>
        </Box>
      );
    },
  },
  {
    field: 'inn',
    headerName: 'ИНН',
    flex: 1,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
  },
  {
    field: 'passportNumber',
    headerName: 'Паспорт',
    flex: 1,
  },
  {
    field: 'userType',
    headerName: 'Тип пользователя',
    flex: 1,
    renderCell(params) {
      //@ts-ignore
      return <>{userType[params.value]}</>;
    },
  },
  {
    field: 'authorized',
    renderCell(params) {
      return params.value ? (
        <GridCheckCircleIcon fontSize="large" color="success" />
      ) : (
        <ErrorIcon fontSize="large" color="error" />
      );
    },
  },
];
