import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Logo from '../../components/ui/Logo';
import MainMenu from '../../components/ui/MainMenu';
import SnowFlake from '../../components/ui/SnowFlake';
import { getTokenFromStorage } from '../../shared/utils/getTokenFromStorage';
import { useDispatch, useSelector } from '../../store';
import { getUserProfile, logout } from '../../store/slices/credentials/actions';
import './index.scss';
import { contentSx, headerStyles, mainStyles, supportStyles } from './styles';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { IUserprofileDetails } from 'store/hooks/userprofile';
import {
  useFetchHotLineEmailQuery,
  useFetchHotLinePhoneQuery,
} from 'store/hooks/general';

interface IPrivateLayout {
  children?: ReactNode;
}
const PrivateLayout: FC<IPrivateLayout> = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: email } = useFetchHotLineEmailQuery('');
  const { data: phone } = useFetchHotLinePhoneQuery('');
  const ref = useRef<HTMLDivElement>(null);
  const [userDetails] = useLocalStorage<IUserprofileDetails>(
    'userDetails',
    {} as IUserprofileDetails
  );
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const data = useSelector((state) => state.credentials);
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handlers = {
    logout() {
      dispatch(
        logout(() => {
          navigate('auth/login');
        })
      );
    },
  };

  useEffect(() => {
    if (data.isAuthorized) {
      dispatch(getUserProfile(0));
    }
  }, [data.isAuthorized]);

  if (!getTokenFromStorage()) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <Box sx={contentSx}>
      {/* {isSmallDevice ? null : <Box
        sx={{
          display: 'flex',
          position: 'absolute',
          top: '-125px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 999,
          pointerEvents: 'none',
        }}
      >
        <Box
          className="bg-new-year"
          sx={{
            height: '200px',
            width: '240px',
            position: 'relative',
            left: '195px',
            transform: 'rotate(260deg)',
          }}
        />
        <Box
          className="bg-new-year"
          sx={{
            height: '200px',
            width: '240px',
            position: 'relative',
            left: '85px',
          }}
        />
        <Box
          className="bg-new-year"
          sx={{
            height: '200px',
            width: '240px',
            top: '-100px',
            right: '60%',
          }}
        />
        <Box
          className="bg-new-year"
          sx={{
            height: '200px',
            width: '240px',
            right: '75px',
            position: 'relative',
            transform: 'rotate(180deg)',
          }}
        />
      </Box>} */}

      {isSmallDevice && (
        <div style={supportStyles}>
          <div className="tw-flex tw-gap-8 tw-py-2 tw-justify-center tw-w-full">
            <div className="tw-flex tw-flex-col">
              <Typography variant="body2">Телефон центра поддержки:</Typography>
              {phone?.length > 0 &&
                phone.map((item: string) => (
                  <a href={`tel:${item}`} key={item}>
                    {item}
                  </a>
                ))}
            </div>
            <div className="tw-flex tw-flex-col">
              <Typography variant="body2">Электроный адрес:</Typography>
              {email?.length > 0 &&
                email.map((item: string) => (
                  <a href={`mailto:${item}`} key={item}>
                    {item}
                  </a>
                ))}
            </div>
          </div>
        </div>
      )}
      <header style={headerStyles}>
        <Container maxWidth="xl">
          <Box
            display="flex"
            // justifyContent="center"
            alignItems="center"
            position={'relative'}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {isSmallDevice && (
                <img
                  src="/assets/images/Burger.svg"
                  alt="burgerMenu"
                  width={32}
                  height={32}
                  onClick={() => {
                    setOpen(true);
                  }}
                />
              )}
              <Logo />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                right: 0,
              }}
            >
              <Box>
                <MainMenu onLogout={handlers.logout} />
              </Box>
            </Box>
          </Box>
          {/* <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {isSmallDevice && (
              <img
                src="/assets/images/Burger.svg"
                alt="burgerMenu"
                width={32}
                height={32}
                onClick={() => {
                  setOpen(true);
                }}
              />
            )}
            <Logo />
            <Box>
              <MainMenu onLogout={handlers.logout} />
            </Box>
          </Box> */}
        </Container>
      </header>
      <main style={mainStyles}>
        <SnowFlake />
        <Container maxWidth="xl">{children}</Container>
      </main>

      {/* <footer
        style={{
          boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
          backgroundColor: '#fff',
        }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              py: isSmallDevice ? 5 : 8,
              px: isSmallDevice ? 2 : 4,
              display: 'flex',
              flexDirection: isSmallDevice ? 'column' : 'row',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: isSmallDevice ? '12px' : '40px',
                marginBottom: isSmallDevice ? 3 : '0px',
              }}
            >
              <Link to="" style={{ textDecoration: 'none' }}>
                <Typography color="primary" sx={{ fontSize: '16px' }}>
                  Личный кабинет
                </Typography>
              </Link>
              <Link to="" style={{ textDecoration: 'none' }}>
                <Typography color="primary" sx={{ fontSize: '16px' }}>
                  Как найти услугу
                </Typography>
              </Link>
              <Link to="" style={{ textDecoration: 'none' }}>
                <Typography color="primary" sx={{ fontSize: '16px' }}>
                  Регистрация на Госуслуги
                </Typography>
              </Link>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: isSmallDevice ? '12px' : '40px',
                marginBottom: isSmallDevice ? 3 : '0px',
              }}
            >
              <Link to="" style={{ textDecoration: 'none' }}>
                <Typography color="primary" sx={{ fontSize: '16px' }}>
                  Бизнесу
                </Typography>
              </Link>
              <Link to="" style={{ textDecoration: 'none' }}>
                <Typography color="primary" sx={{ fontSize: '16px' }}>
                  О нас
                </Typography>
              </Link>
              <Link to="" style={{ textDecoration: 'none' }}>
                <Typography color="primary" sx={{ fontSize: '16px' }}>
                  Партнёрам
                </Typography>
              </Link>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: isSmallDevice ? '12px' : '40px',
              }}
            >
              <Link to="" style={{ textDecoration: 'none' }}>
                <Typography color="primary" sx={{ fontSize: '16px' }}>
                  Помощь
                </Typography>
              </Link>
              <Link to="" style={{ textDecoration: 'none' }}>
                <Typography color="primary" sx={{ fontSize: '16px' }}>
                  Контакты
                </Typography>
              </Link>
              <Link to="" style={{ textDecoration: 'none' }}>
                <Typography color="primary" sx={{ fontSize: '16px' }}>
                  Карта центров обслуживания
                </Typography>
              </Link>
            </Box>
          </Box>
        </Container>
      </footer> */}

      <div className="sidenav" style={{ width: open ? '100%' : '0px' }}>
        <div className="tw-py-4 tw-pl-5">
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
            sx={{ color: '#fff' }}
          >
            <Close fontSize="large" />
          </IconButton>
        </div>
        <div className="tw-flex tw-flex-col tw-mt-8 tw-ml-8 tw-mb-10">
          <p
            className="tw-text-3xl tw-mb-6 tw-text-white tw-cursor-pointer"
            onClick={() => {
              navigate('/');
              setOpen(false);
            }}
          >
            Все услуги
          </p>
          <p
            className="tw-text-3xl tw-mb-6 tw-text-white tw-cursor-pointer"
            onClick={() => {
              navigate('/my-services');
              setOpen(false);
            }}
          >
            Мои услуги{' '}
          </p>
          {/* <p
            className="tw-text-3xl tw-mb-6 tw-text-white tw-cursor-pointer"
            onClick={() => {
              navigate('/profile/account');
              setOpen(false);
            }}
          >
            Учетная запись{' '}
          </p>
          {userDetails.userType && (
            <p
              className="tw-text-3xl tw-mb-6 tw-text-white tw-cursor-pointer"
              onClick={() => {
                navigate('/profile/organization');
                setOpen(false);
              }}
            >
              Организация{' '}
            </p>
          )}
          <p
            className="tw-text-3xl tw-mb-6 tw-text-white tw-cursor-pointer"
            onClick={() => {
              navigate('/profile/documents');
              setOpen(false);
            }}
          >
            {' '}
            Документы и данные
          </p>
          <p
            className="tw-text-3xl tw-mb-6 tw-text-white tw-cursor-pointer"
            onClick={() => {
              navigate('/profile/support');
              setOpen(false);
            }}
          >
            Поддержка{' '}
          </p> */}
        </div>
        <div className="tw-ml-5">
          <MainMenu
            isDrawer
            closeDrawer={() => {
              setOpen(false);
            }}
            onLogout={handlers.logout}
          />
        </div>
      </div>
    </Box>
  );
};

export default PrivateLayout;
