import BaseIcon from './BaseIcon';
type Props = {
  fill?: string;
  stroke?: string;
};
const FileAddIcon = (props: any & Props) => {
  return (
    <BaseIcon {...props}>
      <g clipPath="url(#clip0_406_2060)">
        <path
          d="M14.0002 7V0.46C14.9252 0.809335 15.7654 1.35131 16.4652 2.05L19.9492 5.536C20.6486 6.23488 21.191 7.0749 21.5402 8H15.0002C14.7349 8 14.4806 7.89464 14.293 7.70711C14.1055 7.51957 14.0002 7.26522 14.0002 7ZM22.0002 10.485V19C21.9986 20.3256 21.4713 21.5964 20.5339 22.5338C19.5966 23.4711 18.3257 23.9984 17.0002 24H7.00015C5.67456 23.9984 4.40371 23.4711 3.46637 22.5338C2.52903 21.5964 2.00174 20.3256 2.00015 19V5C2.00174 3.6744 2.52903 2.40356 3.46637 1.46622C4.40371 0.528882 5.67456 0.00158786 7.00015 0L11.5152 0C11.6782 0 11.8392 0.013 12.0002 0.024V7C12.0002 7.79565 12.3162 8.55871 12.8788 9.12132C13.4414 9.68393 14.2045 10 15.0002 10H21.9762C21.9872 10.161 22.0002 10.322 22.0002 10.485ZM16.0002 17C16.0002 16.7348 15.8948 16.4804 15.7073 16.2929C15.5197 16.1054 15.2654 16 15.0002 16H13.0002V14C13.0002 13.7348 12.8948 13.4804 12.7073 13.2929C12.5197 13.1054 12.2654 13 12.0002 13C11.7349 13 11.4806 13.1054 11.293 13.2929C11.1055 13.4804 11.0002 13.7348 11.0002 14V16H9.00015C8.73494 16 8.48058 16.1054 8.29305 16.2929C8.10551 16.4804 8.00015 16.7348 8.00015 17C8.00015 17.2652 8.10551 17.5196 8.29305 17.7071C8.48058 17.8946 8.73494 18 9.00015 18H11.0002V20C11.0002 20.2652 11.1055 20.5196 11.293 20.7071C11.4806 20.8946 11.7349 21 12.0002 21C12.2654 21 12.5197 20.8946 12.7073 20.7071C12.8948 20.5196 13.0002 20.2652 13.0002 20V18H15.0002C15.2654 18 15.5197 17.8946 15.7073 17.7071C15.8948 17.5196 16.0002 17.2652 16.0002 17Z"
          fill={props.fill || '#374957'}
        />
      </g>
      <defs>
        <clipPath id="clip0_406_2060">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export default FileAddIcon;
