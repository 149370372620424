import { Box, Chip } from '@mui/material';
import { FC } from 'react';
import { ChatMessageItem } from './ChatMessageItem';
import { IChatMessageService, IChatMessages } from 'store/hooks/services/chat';
import { useSession } from 'shared/hooks/useSession';

interface IChatMessageGroup {
  group: IChatMessages;
  onEditMessage: (item: IChatMessageService) => void;
}

export const ChatMessageGroup: FC<IChatMessageGroup> = ({
  group,
  onEditMessage,
}) => {
  const { user } = useSession();
  return (
    <Box className="tw-mb-8">
      <Box className="tw-flex">
        <Chip
          sx={{ fontSize: '14px' }}
          className="tw-mx-auto"
          label="2022-10-10"
        />
      </Box>
      <Box className="tw-pt-8">
        {group.messages.map((item) => (
          <ChatMessageItem
            key={item.id}
            data={item}
            isMe={user?.info?.id === item.userId}
            onEditMessage={onEditMessage}
          />
        ))}
      </Box>
    </Box>
  );
};
