import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getConfirmationDetail } from './api';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './style.css';

const Confirmation: React.FC = () => {
  const { applicationId } = useParams();
  const [detail, setDetail] = useState('');

  const fetchData = async (id: string) => {
    const resp = await getConfirmationDetail(id);

    if (resp) {
      const text = resp?.readyText || '';

      const signBase64 = resp?.sign;

      const updatedReadyText =
        text +
        `<br><br><br><br><br><br><img src="data:image/png;base64,${signBase64}" alt="document-signature" />`;

      setDetail(resp?.sign ? updatedReadyText : text);
    }
  };

  useEffect(() => {
    if (applicationId) {
      fetchData(applicationId);
    }
  }, [applicationId]);

  return (
    <div className="tw-flex tw-flex-row tw-justify-center tw-py-10 tw-px-5">
      <ReactQuill
        readOnly
        className="tw-w-[100%] tw-max-w-[210mm] tw-h-[280mm] tw-m-auto tw-rounded-lg"
        value={detail}
        modules={{
          toolbar: [],
          clipboard: {
            matchVisual: false,
          },
        }}
        theme="snow"
      />
    </div>
  );
};

export default Confirmation;
