import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Pagination,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { SectionForm } from 'components/forms/SectionForm';
import DataTable from 'components/ui/DataTable';
import Loading from 'components/ui/Loading';
import { FC, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import FileAddIcon from 'shared/icons/FileAddIcon';
import ListIcon from 'shared/icons/ListIcon';
import { deepCopy } from 'shared/utils/deepCopy';
import { useFetchOrganisationListQuery } from 'store/hooks/general';
import {
  IServiceSearchRequest,
  useGetServiceDetailQuery,
  useSearchServiceQuery,
} from 'store/hooks/services';
import { serviceStatusOptions } from './create/helpers/constants';
import { headerTitles, initialFilters } from './helpers/constants';
import { useIsAuthorizedQuery } from 'store/hooks/userprofile';
import { toast } from 'react-toastify';
import TableCellToRow from 'components/ui/TableCellToRow/TableCellToRow';
import TableCellToRowValue from 'components/ui/TableCellToRow/TableCellToRowValue/TableCellToRowValue';
import TableCellToRowKey from 'components/ui/TableCellToRow/TableCellToRowKey/TableCellToRowKey';
import { payStatuses, statuses } from 'routes/private/my-services/helpers/constants';

interface IServiceApplicationsPage {
  isIncoming?: boolean;
}

const ServiceApplicationsPage: FC<IServiceApplicationsPage> = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate()

  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(1);
  const { data: isAuthorized } = useIsAuthorizedQuery({});
  const [pageSize, setPageSize] = useState(20);
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const [filters, setFilters] = useState<IServiceSearchRequest['filters']>(
    deepCopy(initialFilters)
  );
  const { serviceId } = useParams();
  const { data: details } = useGetServiceDetailQuery(serviceId || '');
  const { data: orgList } = useFetchOrganisationListQuery();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage1(value);
  };

  const { data: dataList, isFetching } = useSearchServiceQuery({
    ids: null,
    orderBy: {
      orderColumn: 1,
      direction: 1,
    },
    pageInfo: {
      pageNumber: page + 1,
      pageSize: pageSize,
    },
    filters: { ...filters, serviceId: Number(serviceId) },
  });

  const selectedOrg = useMemo(
    () => orgList?.items?.find((item) => item.id === filters.organisationId),
    [filters.organisationId]
  );
  const selectedState = useMemo(
    () => serviceStatusOptions?.find((item: any) => item.id === filters.state),
    [filters.state]
  );

  return (
    <Box pt={3}>
      <SectionForm
        emptyBox
        title={details?.name || ''}
        isSmallScreen={isSmallDevice}
      >
        <div className="tw-py-4">
          <div className="tw-grid tw-grid-cols-[1fr_187px] tw-w-full tw-gap-4 tw-mb-10">
            {isSmallDevice ? null : (
              <div className="tw-grid tw-grid-flow-col-dense tw-auto-cols-[200px] tw-gap-4">
                <Autocomplete
                  disablePortal
                  options={orgList?.items || []}
                  size="small"
                  value={selectedOrg}
                  getOptionLabel={(option: any) => option.name as string}
                  renderInput={(params) => (
                    <TextField {...params} label="Организация" />
                  )}
                  onChange={(event, value) => {
                    setFilters({
                      ...filters,
                      organisationId: value?.id || 0,
                    });
                  }}
                />

                <Autocomplete
                  disablePortal
                  options={serviceStatusOptions}
                  size="small"
                  value={selectedState}
                  getOptionLabel={(option) => option.value as string}
                  renderInput={(params) => (
                    <TextField {...params} label="Статус" />
                  )}
                  onChange={(event, value) => {
                    setFilters({
                      ...filters,
                      state: Number(value?.id) || 0,
                    });
                  }}
                />

                <Button
                  startIcon={
                    isFetching ? (
                      <Loading />
                    ) : (
                      <ListIcon
                        width="18px"
                        height="18px"
                        fill="currentColor"
                        stroke="none"
                      />
                    )
                  }
                  disabled={isFetching}
                  onClick={() => { }}
                >
                  Список
                </Button>
              </div>
            )}
            {isAuthorized ? (
              <Link to={`/services/details/${serviceId}/application/create`}>
                <Button
                  variant="contained"
                  sx={{ fontSize: isSmallDevice ? '12px' : '18px' }}
                  startIcon={
                    <FileAddIcon
                      width="18px"
                      height="18px"
                      fill="currentColor"
                      stroke="none"
                    />
                  }
                >
                  Добавить
                </Button>
              </Link>
            ) : (
              <Link to={'/profile/account'}>
                <Button
                  variant="contained"
                  sx={{ fontSize: isSmallDevice ? '12px' : '25px' }}
                  startIcon={
                    <FileAddIcon
                      width="18px"
                      height="18px"
                      fill="currentColor"
                      stroke="none"
                    />
                  }
                  onClick={() => {
                    toast.error('Необходимо пройти аутентификацию.', {
                      autoClose: 5000,
                    });
                  }}
                >
                  Добавить
                </Button>
              </Link>
            )}
          </div>
          {isSmallDevice ? (
            dataList &&
            dataList?.items && (
              <>
                {' '}
                {dataList?.items?.map((item: any) => {
                  return (
                    <TableCellToRow key={item.id} onClick={() => {
                      navigate(`/services/details/${serviceId}/application/show/${item.id}`)
                    }}>
                      <TableCellToRowKey>№</TableCellToRowKey>
                      <TableCellToRowValue>{item.id}</TableCellToRowValue>
                      <TableCellToRowKey>Название сервиса</TableCellToRowKey>
                      <TableCellToRowValue>{item.service.value}</TableCellToRowValue>
                      <TableCellToRowKey>Название сервиса</TableCellToRowKey>
                      <TableCellToRowValue>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                          }}
                        >
                          <Avatar src={item.user?.avatar} />
                          <Typography fontSize="15px">{item.user?.value}</Typography>
                        </Box>
                      </TableCellToRowValue>
                      <TableCellToRowKey>Статус заявки</TableCellToRowKey>
                      <TableCellToRowValue>{
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore 
                        statuses[item.state as string] ? statuses[item.state as string] : 'не активен'}
                      </TableCellToRowValue>
                      <TableCellToRowKey>Статус оплаты</TableCellToRowKey>
                      <TableCellToRowValue>{
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore 
                        payStatuses[item.state as string] ? payStatuses[item.state as string] : 'не активен'}
                      </TableCellToRowValue>
                    </TableCellToRow>
                  );
                })}
                <Pagination
                  color="primary"
                  siblingCount={0}
                  count={Math.ceil(dataList?.total / 10)}
                  page={page1}
                  onChange={handleChange}
                />
              </>
            )
          ) : (
            <DataTable
              sx={{ fontSize: '13px' }}
              pushUri={`/services/details/${serviceId}/application/show`}
              columns={headerTitles}
              items={dataList?.items || []}
              isLoading={isFetching}
              page={page}
              pageSize={pageSize}
              setPageSize={setPageSize}
              setPage={setPage}
              totalItems={dataList?.total}
            />
          )}
        </div>
      </SectionForm>
    </Box>
  );
};

export default ServiceApplicationsPage;
