import { CSSProperties } from 'react';

export const imgBoxPlaceholderStyles: CSSProperties = {
  borderRadius: '1.6rem',
  height: '317px',
  display: 'flex',
  alignItems: 'center',
};

export const imgBoxStyles: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  height: '317px',
  borderRadius: '',
  position: 'relative',
  cursor: 'pointer',
};
