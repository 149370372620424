import { SxProps } from '@mui/material';

export const boxSx: SxProps = {
  border: '1px dashed rgba(0, 0, 0, 0.31)',
  borderRadius: '1.6rem',
  padding: '2.4rem',
  boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  alignItems: 'center',
  gap: '5.8rem',
  background: '#FBFCFF',
};

export const typographySx: SxProps = {
  color: '#222',
  fontSize: '1.6rem',
  fontWeight: 500,
  opacity: 0.51,
};

export const titleSx: SxProps = {
  color: '#222',
  fontSize: '2rem',
  fontWeight: 600,
  marginBottom: '1.6rem',
};

export const modalCardSx: SxProps = {
  padding: '2.4rem',
  border: '1px solid rgba(0, 0, 0, 0.32)',
  borderRadius: '1.6rem',
};

export const modalGroupCardSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: '2.4rem',
  width: '400px',
};

export const cardLogoBoxSx: SxProps = {
  padding: '0 2.4rem',
  border: '1px solid rgba(0, 0, 0, 0.32)',
  borderRadius: '0.8rem',
};

export const modalContainerCardSx: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const cardTextBoxSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: '3.2rem',
};
