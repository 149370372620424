/* eslint-disable @typescript-eslint/ban-ts-comment */
import { UploadFile } from '@mui/icons-material';
import { Avatar, Box, IconButton, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import { IServiceSearchRequest } from 'store/hooks/services';

export const initialRequest: any = {
  ids: null,
  filters: {
    serviceId: 0,
    state: 0,
  },
  orderBy: {
    column: 1,
    order: 1,
  },
  pageInfo: {
    pageNumber: 1,
    pageSize: 15,
  },
};

export const statuses = {
  1: 'Новая',
  2: 'Концелярия',
  3: 'Резолюция',
  4: 'Испольнение',
  5: 'Подготовка документов',
  6: 'Концелярия',
  7: 'Завершено',
  100: 'Удален',
};

export const payStatuses = {
  0: 'Не оплачено',
  1: 'Оплачено',
  2: 'Частично оплачено',
};

export const initialFilters: Nullable<IServiceSearchRequest['filters']> = {
  organisationId: 0,
  state: 0,
  serviceId: 0,
};

export const headerTitles: (check: any) => GridColDef[] = (check) => [
  {
    field: 'id',
    headerName: '№',
    flex: 0.5,
    sortable: false,
    filterable: false,
  },
  {
    field: 'service',
    headerName: 'Название сервиса',
    flex: 2,
    sortable: false,
    filterable: false,
    valueFormatter: (params) => {
      // console.log('Params', params.value);
      return params.value.value;
    },
  },
  {
    field: 'user',
    headerName: 'Создал',
    flex: 2,
    sortable: false,
    filterable: false,
    renderCell(params) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Avatar src={params.value?.avatar} />
          <Typography fontSize="15px">{params.value?.value}</Typography>
        </Box>
      );
    },
  },

  {
    field: 'invoicePaidMoney',
    headerName: 'Сумма',
    flex: 2,
    sortable: false,
    filterable: false,
    valueFormatter: (params) => {
      // console.log('Params', params.value);
      return params.value.value;
    },
  },
  {
    field: 'payState',
    headerName: 'Статус оплаты',
    flex: 2,
    sortable: false,
    filterable: false,
    valueFormatter: (params) => {
      //@ts-ignore
      return payStatuses[params.value as string]
        ? //@ts-ignore
        payStatuses[params.value as string]
        : 'Статус не определен';
    },
  },
  {
    field: 'state',
    headerName: 'Статус заявки',
    flex: 2,
    sortable: false,
    filterable: false,
    valueFormatter: (params) => {
      //@ts-ignore
      return statuses[params.value as string]
        ? //@ts-ignore
        statuses[params.value as string]
        : 'не активен';
    },
  },
  {
    field: 'invoiceNumber',
    headerName: 'Квитанция',
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      console.log(params, 'params')
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* Add your custom action buttons here */}
          <IconButton
            disabled={params.row.payState !== 1 || params.row.payState == 2}
            onClick={(e) => {
              e.stopPropagation();
              //@ts-ignore
              if (!params?.value) return toast.error('Номер счета не найден ');
              check(params.value);
            }}
          >
            <UploadFile />
          </IconButton>
        </Box>
      );
    },
  },
];
