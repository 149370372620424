import React, {
  FC,
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getEtokenPlugin } from './plugin';
import { useFormik } from 'formik';
import { Box, IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import { TokenInfo } from '../../../shared/utils/types';
import CustomSelect from '../../forms/CustomSelect';
import CustomField from '../../forms/CustomField';
import UsbIcon from '@mui/icons-material/Usb';
import SecurityIcon from '@mui/icons-material/Security';
import FiberPinIcon from '@mui/icons-material/FiberPin';
import { TokenSubmitContext } from '../../../routes/public/login';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { dateFormat } from '../../../shared/constants';
export interface LoginProps {
  children?: ReactNode;
  title?: string;
  id?: string;
  onSuccess?: (data: TokenInfo) => void;
  onOutsideClick?: () => void;
}

interface IDevice {
  id: number;
  tokenExists: boolean;
  device: {
    formFactor: string;
    model: string;
    modelFriendlyName: string;
    name: string;
    serialNumber: string;
  };
}

interface ISelectOption {
  label: string;
  value: number;
}

export interface FormProps {
  pin: string;
  device?: ISelectOption | null;
  cert?: ISelectOption | null;
}

interface Certificate {
  id: number;
  description: string;
}

const TokenComponent: FC<LoginProps> = ({
  title = 'Требуется сертификат(E-token или Rutoken)',
  id,
  onSuccess,
  onOutsideClick,
}) => {
  const contentRef: RefObject<any> = useRef(null);
  const modalRef: RefObject<any> = useRef(null);

  const [etokenPlugin, setEtokenPlugin] = useState<any>(null);

  /**
   * devices will be obtained from etokenPlugin
   * and will be setted to this state
   */
  const [devices, setDevices] = useState<IDevice[]>([]);

  const [tokenID, setTokenID] = useState<number>(0);
  const [certs, setCerts] = useState<Certificate[]>([]);
  const [certId, setCertId] = useState<number>(0);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const submitController = useContext(TokenSubmitContext);

  const [login, setLogin] = useState(false);

  const getCertificateData = (params?: { id?: number; tokenId?: number }) => {
    const { id: ID, tokenId } = params || {};
    if (etokenPlugin && (ID || certId)) {
      const body = etokenPlugin.getCertificateBody({
        args: {
          id: ID || certId,
          tokenID: tokenId || tokenID,
        },
      });

      return etokenPlugin.parseX509Certificate({
        args: {
          id: certId,
          tokenID,
          cert: body,
        },
      });
    }

    return null;
  };

  const isCertValid = (certData: any) => {
    const beginDate = certData.Data.Validity['Not Before'];
    const endDate = certData.Data.Validity['Not After'];

    return beginDate <= Date.now() && Date.now() <= endDate;
  };

  const toHex = (arr: number[]) =>
    arr
      .map((item) => {
        const hex = item.toString(16);
        if (hex.length === 1) return `0${hex}`;
        return hex;
      })
      .join('');

  const tokenLogin = (pin: string) => {
    if (etokenPlugin) {
      setLogin(true);
      new Promise((resolve, reject) => {
        try {
          etokenPlugin.bindToken({
            args: {
              tokenID,
              pin,
            },
          });
          const cert = getCertificateData();

          if (cert && !isCertValid(cert)) {
            toast.error('Выбранный сертификат просрочен');
            submitController?.resetSubmitSignature();
            return;
          }

          if (onSuccess && cert) {
            onSuccess({
              startDate: new Date(
                cert.Data.Validity['Not Before']
              ).toISOString(),
              expiryDate: new Date(
                cert.Data.Validity['Not After']
              ).toISOString(),
              certId: toHex(cert.Data['Serial Number']),
              deviceId: tokenID,
            });
          }
          resolve(true);
        } catch (err) {
          console.log(err);
          reject(false);
          submitController?.resetSubmitSignature();
        }
      })
        .then(() => toast.success('ПИН-код успешно принят'))
        .catch(() => {
          toast.error('Некорректный PIN-код');
        })
        .finally(() => {
          setLogin(false);
          submitController?.resetSubmitSignature();
        });
    } else {
      submitController?.resetSubmitSignature();
    }
  };

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    setErrors,
  } = useFormik<FormProps>({
    initialValues: {
      pin: '',
      cert: { label: 'Hello', value: 0 },
      device: { label: 'Hello', value: 1 },
    },
    onSubmit(params) {
      tokenLogin(params.pin);
    },
  });

  useEffect(() => {
    const handleOutsideClick = (e: Event) => {
      const target = e.target;
      if (
        target === modalRef.current ||
        target === modalRef.current?.firstChild
      ) {
        if (onOutsideClick) onOutsideClick();
      }
    };
    if (onOutsideClick && contentRef.current) {
      document.addEventListener('click', handleOutsideClick);
    }
    return () => document.removeEventListener('click', handleOutsideClick);
  }, [contentRef.current]);

  useEffect(() => {
    getEtokenPlugin()
      .then((plugin) => setEtokenPlugin(plugin))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const submitSignature = submitController?.submitSignature;
    if (handleSubmit && submitSignature && submitSignature !== -1) {
      handleSubmit();
    }
  }, [handleSubmit, submitController?.submitSignature]);

  const getAllCerts = (tokenId: number) => {
    if (etokenPlugin) {
      const certList = etokenPlugin.getStandaloneCertificateList({
        args: {
          tokenID: tokenId,
        },
      }) as Certificate[];
      if (certList) {
        let certOptions = certList;
        try {
          certOptions = certList.map(({ id: ID }) => {
            const certBody = getCertificateData({ tokenId, id: ID as number });

            const expiryDate = new Date(certBody.Data.Validity['Not After']);
            if (expiryDate.getTime() < Date.now()) return null;

            const { Data } = certBody || {};
            const subject =
              Data?.Subject?.find((item: any) => {
                if (typeof item === 'object') {
                  return item.rdn === 'CN';
                }
                return false;
              })?.value || 'Unknown';
            return {
              id: ID,
              description: `${Data?.Issuer[0]?.value} ${subject} (${dateFormat(expiryDate.toString())})`,
            };
          }).filter(item => item) as Certificate[];
        } catch (err) {
          console.log('Error: ', err);
        }
        setCerts(certOptions);
      }
    }
  };

  const addDevices = () => {
    const deviceList: Array<any> = etokenPlugin.getAllSlots();
    setDevices(deviceList);
  };

  useEffect(() => {
    return () => {
      const state = etokenPlugin && etokenPlugin.getLoggedInState().state;
      if (
        etokenPlugin &&
        etokenPlugin.Vars &&
        etokenPlugin.Vars.AuthState.binded === state
      ) {
        etokenPlugin.unbindToken();
      }
    };
  }, [etokenPlugin, id]);

  useEffect(() => {
    if (etokenPlugin) {
      addDevices();
      const timerId = setInterval(addDevices, 3000);
      return () => {
        clearInterval(timerId);
      };
    }
    return () => {};
  }, [etokenPlugin, id]);

  const deviceOptions = useMemo(
    () =>
      (devices &&
        devices.map(({ id: ID, device: { modelFriendlyName } }) => ({
          label: modelFriendlyName,
          value: ID,
        }))) ||
      [],
    [devices]
  );

  const certOptions = useMemo(
    () =>
      (certs &&
        certs.map(({ id: ID, description }) => ({
          label: description,
          value: ID,
        }))) ||
      [],
    [certs]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 4,
      }}
    >
      <CustomSelect
        StartIcon={UsbIcon}
        label="Доступные устройство"
        value={values.device}
        items={deviceOptions}
        onChange={(value) => {
          const currTokenID = value?.value as number;
          setFieldValue('device', value);
          setTokenID(currTokenID);
          getAllCerts(currTokenID);
        }}
      />
      <CustomSelect
        StartIcon={SecurityIcon}
        label="Сертификаты"
        value={values.cert}
        items={certOptions}
        onChange={(value) => {
          if (value) {
            setFieldValue('cert', value);
            setCertId(value?.value);
          }
        }}
      />
      <CustomField
        id="outlined-password-input"
        label="PIN-код"
        type={passwordVisible ? 'text' : 'password'}
        autoComplete="current-password"
        StartIcon={FiberPinIcon}
        value={values.pin}
        onChange={(e: any) => {
          setFieldValue('pin', e.target.value);
        }}
        endIcon={
          <IconButton onClick={() => setPasswordVisible(!passwordVisible)}>
            {passwordVisible ? (
              <VisibilityOffIcon color="primary" fontSize="large" />
            ) : (
              <VisibilityIcon color="primary" fontSize="large" />
            )}
          </IconButton>
        }
      />
    </Box>
  );
};

export default React.memo(TokenComponent);
