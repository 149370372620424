import { FormikProps } from 'formik';
import * as Yup from 'yup';

export const selectSchema = () =>
  Yup.object()
    .shape({
      id: Yup.mixed(),
      value: Yup.string().required('Обязательно'),
    })
    .nullable()
    .required('Обязательно');

export const stringSchema = (min?: number, max?: number, required?: boolean) => {
  const schema = Yup.string()
    .min(min || 1, `Минимальная допустимая длина строки ${min}`)
    .max(max || 120, `Максимально допустимая длина строки ${max}`);

  if (required === undefined || required) {
    return schema.required('Обязательно');
  }

  return schema;
}

export const numberSchema = () => Yup.number().required('Обязательно');

export const dateSchema = () => Yup.date().required('Обязательно');

export const innSchema = () =>
  Yup.string()
    .length(9, 'Необходимая длина ИНН 9 символов')
    .required('Обязательно');

export const phoneSchema = () =>
  Yup.string()
    .matches(/^\+992[0-9]{9}$/, 'Правильный формат телеффона: +992XXXXXXXXX')
    .required('Обязательно');

export const emailSchema = () => Yup.string().required('Обязательно');

type GetFieldErrors = (
  formik: FormikProps<any>,
  key: string
) => { helperText?: string; error: boolean };

export const getFieldErrors: GetFieldErrors = (formik, key) => {
  const { touched, errors } = formik;

  const error = Boolean(touched[key] && errors[key]);
  return {
    error,
    helperText: error ? (errors[key] as string) : '',
  };
};
