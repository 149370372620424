import React, { FC } from 'react';
import { TablePagination } from '@mui/material';
interface ICustomPagination {
  page: number;
  perPage: number;
  rowCount: number;
  onChange: (currPage: number) => void;
  onChangePerPage: (perPage: number) => void;
}

const CustomPagination: FC<ICustomPagination> = ({
  page,
  perPage,
  rowCount,
  onChange,
  onChangePerPage,
}) => {
  const pagBlock = document.getElementsByClassName(
    'MuiTablePagination-toolbar'
  )[0];
  const prevBtn = document.querySelectorAll('[title="Go to previous page"]')[0];
  const nextBtn = document.querySelectorAll('[title="Go to next page"]')[0];

  if (pagBlock && prevBtn && nextBtn) {
    pagBlock.appendChild(prevBtn);
    pagBlock.appendChild(nextBtn);
  }

  return (
    <TablePagination
      sx={{
        '& .MuiTablePagination-selectLabel': {
          fontSize: '16px !important',
        },

        '& .MuiTablePagination-displayedRows': {
          fontSize: '1.6rem',
        },

        '& .MuiButtonBase-root svg': {
          fontSize: '2.2rem',
        },
      }}
      count={rowCount}
      page={page}
      rowsPerPageOptions={[10, 20, 30, 40, 50]}
      rowsPerPage={perPage}
      onRowsPerPageChange={(event: any) => {
        onChangePerPage(Number(event.target.value));
      }}
      onPageChange={(it, value) => {
        onChange(value);
      }}
    />
  );
};

export default CustomPagination;
