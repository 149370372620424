import {
  FormControl,
  StandardTextFieldProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { CSSProperties, FC, HTMLInputTypeAttribute, ReactNode } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { iconSx, inputStyles, labelStyles, textFieldSx } from './styles';

type IconType = typeof PersonIcon;

interface ICustomField extends StandardTextFieldProps {
  label: string;
  value?: string;
  type?: HTMLInputTypeAttribute;
  id?: string;
  autoComplete?: string;
  endIcon?: ReactNode;
  StartIcon?: IconType;
  inputWithoutIcon?: CSSProperties;
  labelWithoutIcon?: CSSProperties;
  errorText?: string;
  isSmallScreen?: boolean
}

const CustomField: FC<ICustomField> = (props) => {
  const { endIcon, StartIcon, inputWithoutIcon, labelWithoutIcon, isSmallScreen = false, ...other } =
    props;
  return (
    <FormControl
      sx={{
        position: 'relative',
        height: '60px !important',
        '& .MuiFormControl-root': {
          height: '60px',
          overflow: 'visible',
        },

        '& .MuiInputBase-root': {
          height: '60px',
        },

        '& .MuiInputBase-input': {
          borderBottomLeftRadius: 'inherit',
          borderBottomRightRadius: 'inherit',
          boxSizing: 'border-box',
          // paddingTop: '8px !important',
          // paddingBottom: '0 !important',
        },

        '& .MuiFormHelperText-root': {
          paddingLeft: '8px'
        },
      }}
    >
      {StartIcon && <StartIcon fontSize="large" sx={iconSx} />}
      <TextField
        autoComplete="off"
        sx={textFieldSx}
        inputProps={{
          style: inputWithoutIcon ? inputWithoutIcon : inputStyles(isSmallScreen),
        }}
        InputProps={{
          endAdornment: endIcon,
        }}
        InputLabelProps={{
          style: labelWithoutIcon ? labelWithoutIcon : labelStyles(isSmallScreen),
        }}
        fullWidth
        variant="filled"
        {...other}
      />
    </FormControl>
  );
};

export default CustomField;
