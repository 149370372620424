import React from 'react';
import { Navigate } from 'react-router-dom';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { IUserprofileDetails } from 'store/hooks/userprofile';

function AdminAuth(props: any) {
  const [userDetails] = useLocalStorage<IUserprofileDetails>(
    'userDetails',
    {} as IUserprofileDetails
  );

  return userDetails.roles.includes(1) ? (
    props.children
  ) : (
    <Navigate to="/404" replace={true} />
  );
}

export default AdminAuth;
