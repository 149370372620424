import { ThemeProvider } from '@emotion/react';
import {
  Box,
  Button,
  createTheme,
  styled,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import SelectForMonile from 'components/selectForMobile/SelectForMonile';
import { useEffect, useState } from 'react';
import {
  useGetUserprofileDetailsQuery,
  useLazyGetOrgQuery,
  useUserLegalUpdateMutation,
  useUserUpdateMutation,
} from '../../../../store/hooks/userprofile';
import { titleBoxSx } from './styles';
import { useFormik } from 'formik';
import {
  phoneRegexp,
  phoneRegexpCompleted,
} from 'routes/public/login/helpers/constants';
import { getFieldErrors, innSchema, stringSchema } from 'shared/utils/yup';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

const theme = createTheme({});

export const CustomTextFieldOrganization = styled(TextField)(({}) => ({
  color: '#222',
  borderRadius: '0.8rem',
  fontFamily: 'Roboto',
  flex: 8,
  marginRight: '1.6rem',
  boxSizing: 'border-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  borderColor: '#dddbde',
  '& .MuiOutlinedInput-root': {
    fontSize: '14px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0.8rem',
      borderColor: '#dddbde',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderRadius: '0.8rem',
      borderColor: '#dddbde',
    },
  },
}));

const validationLegalSchema = Yup.object({
  orgName: stringSchema(),
  orgINN: innSchema(),
  orgAdress: stringSchema(),
  name: stringSchema(),
  surName: stringSchema(),
  login: stringSchema(),
  inn: innSchema(),
  phone: Yup.string()
    .required('Обязательно')
    .test({
      test: (value) => phoneRegexpCompleted?.test(value),
      message: 'Неправильный формат телефона: 992XXXXXXXXX',
    }),
  passportNumber: stringSchema(),
});

const Account = () => {
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');
  const { data: profileData } = useGetUserprofileDetailsQuery();
  const [refetch, data] = useLazyGetOrgQuery();
  const [updateUser] = useUserLegalUpdateMutation();
  const [editMode, setEditMode] = useState(false);
  const [modal, setModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      orgName: '',
      orgINN: '',
      orgAdress: '',
      name: '',
      surName: '',
      patronicName: '',
      login: '',
      inn: '',
      phone: '',
      email: '',
      passportNumber: '',
      address: '',
    },
    validationSchema: validationLegalSchema,
    onSubmit(values) {
      updateUser({ ...values, id: profileData?.id }).then((res) => {
        refetch(profileData?.id);
      });
    },
  });

  const { values, errors, touched, setFieldValue, setFieldTouched } = formik;

  useEffect(() => {
    if (profileData?.id) {
      refetch(profileData?.id);
    }
  }, [profileData?.id]);

  const getHandler = (key: string, regexp?: RegExp) => (e: any) => {
    const value = e.target.value;
    if (regexp && !regexp.test(value)) {
      return;
    }
    setFieldValue(key, value);
  };

  const getPhone = (key = 'phone') => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;

      if (value.length === 3) {
        setFieldValue(key, '');
        return;
      }

      if (!value.startsWith('992')) {
        value = '992' + value;
      }

      if (value.length > 12 || !phoneRegexp.test(value)) return;

      setFieldValue(key, value);
    };
  };

  const getHandleBlur = (key: string) => () => {
    setFieldTouched(key, true);
  };

  useEffect(() => {
    if (data?.data) {
      setFieldValue('orgName', data.data?.orgName || '');
      setFieldValue('orgINN', data.data?.orgINN || '');
      setFieldValue('orgAdress', data.data?.orgAdress || '');
      setFieldValue('name', data.data?.name || '');
      setFieldValue('surName', data.data?.surName || '');
      setFieldValue('patronicName', data.data?.patronicName || '');
      setFieldValue('login', data.data?.login || '');
      setFieldValue('inn', data.data?.inn || '');
      setFieldValue('phone', data.data?.phone || '');
      setFieldValue('email', data.data?.email || '');
      setFieldValue('passportNumber', data.data?.passportNumber || '');
      setFieldValue('address', data.data?.address || '');
    }
  }, [data]);

  console.log(errors);
  return (
    <Box>
      <SelectForMonile />
      <form>
        <ThemeProvider theme={theme}>
          <Box className="lg:tw-grid tw-grid-cols-3 tw-gap-10">
            <Box
              className="tw-col-span-3"
              sx={{ display: 'flex', flexDirection: 'column', gap: '3.2rem' }}
            >
              <Typography variant="h5">Данные ораганизации</Typography>
              <Box sx={{ marginTop: isSmallDevice ? '20px' : '0' }}>
                <Typography sx={titleBoxSx}>
                  Наименование ораганизации
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <CustomTextFieldOrganization
                    fullWidth
                    value={values.orgName}
                    disabled={!editMode}
                    onChange={getHandler('orgName')}
                    onBlur={getHandleBlur('orgName')}
                    {...getFieldErrors(formik, 'orgName')}
                  />
                </Box>
              </Box>
              <Box className="lg:tw-grid tw-grid-cols-2 tw-gap-10">
                <Box>
                  <Typography sx={titleBoxSx}>ИНН ораганизации</Typography>
                  <Box sx={{ display: 'flex' }}>
                    <CustomTextFieldOrganization
                      disabled={!editMode}
                      fullWidth
                      value={values.orgINN}
                      onChange={getHandler('orgINN')}
                      onBlur={getHandleBlur('orgINN')}
                      {...getFieldErrors(formik, 'orgINN')}
                    />
                  </Box>
                </Box>

                <Box>
                  <Typography sx={titleBoxSx}>Адрес ораганизации</Typography>
                  <Box sx={{ display: 'flex' }}>
                    <CustomTextFieldOrganization
                      disabled={!editMode}
                      fullWidth
                      value={values.orgAdress}
                      onChange={getHandler('orgAdress')}
                      onBlur={getHandleBlur('orgAdress')}
                      {...getFieldErrors(formik, 'orgAdress')}
                    />
                  </Box>
                </Box>
              </Box>

              <Typography variant="h5">Данные руководителя</Typography>
              <Box className="lg:tw-grid tw-grid-cols-2 tw-gap-10">
                <Box>
                  <Typography sx={titleBoxSx}>Имя</Typography>
                  <Box sx={{ display: 'flex' }}>
                    <CustomTextFieldOrganization
                      fullWidth
                      disabled={!editMode}
                      value={values.name}
                      onChange={getHandler('name')}
                      onBlur={getHandleBlur('name')}
                      {...getFieldErrors(formik, 'name')}
                    />
                  </Box>
                </Box>

                <Box>
                  <Typography sx={titleBoxSx}>Фамилия</Typography>
                  <CustomTextFieldOrganization
                    fullWidth
                    disabled={!editMode}
                    value={values.surName}
                    onChange={getHandler('surName')}
                    onBlur={getHandleBlur('surName')}
                    {...getFieldErrors(formik, 'surName')}
                  />
                </Box>

                <Box>
                  <Typography sx={titleBoxSx}>Отчество</Typography>
                  <CustomTextFieldOrganization
                    fullWidth
                    disabled={!editMode}
                    value={values.patronicName}
                    onChange={getHandler('patronicName')}
                  />
                </Box>

                <Box>
                  <Typography sx={titleBoxSx}>
                    Серийный номер паспорт
                  </Typography>
                  <CustomTextFieldOrganization
                    fullWidth
                    disabled={!editMode}
                    value={values.passportNumber}
                    onChange={getHandler('passportNumber')}
                    onBlur={getHandleBlur('passportNumber')}
                    autoComplete="new-password"
                    {...getFieldErrors(formik, 'passportNumber')}
                  />
                </Box>

                <Box>
                  <Typography sx={titleBoxSx}>ИНН</Typography>
                  <CustomTextFieldOrganization
                    fullWidth
                    disabled={!editMode}
                    value={values.inn}
                    onChange={getHandler('inn', /^[0-9]{0,9}$/)}
                    onBlur={getHandleBlur('inn')}
                    {...getFieldErrors(formik, 'inn')}
                  />
                </Box>

                <Box>
                  <Typography sx={titleBoxSx}>Email</Typography>
                  <CustomTextFieldOrganization
                    fullWidth
                    disabled={!editMode}
                    value={values.email}
                    onChange={getHandler('email')}
                    onBlur={getHandleBlur('email')}
                    {...getFieldErrors(formik, 'email')}
                  />
                </Box>
                <Box>
                  <Typography sx={titleBoxSx}>Телефон</Typography>
                  <CustomTextFieldOrganization
                    fullWidth
                    disabled={!editMode}
                    value={values.phone}
                    onChange={getPhone('phone')}
                    onBlur={getHandleBlur('phone')}
                    {...getFieldErrors(formik, 'phone')}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {editMode ? (
            <Button
              variant="contained"
              sx={{ borderRadius: '0.8rem', mt: 2 }}
              onClick={(e) => {
                e.preventDefault();
                formik.submitForm();
                setEditMode(false);
              }}
              type="submit"
            >
              Сохранить
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{ borderRadius: '0.8rem', mt: 2 }}
              onClick={(e) => {
                e.preventDefault();
                setEditMode(true);
              }}
              type="submit"
            >
              Изменить
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default Account;
