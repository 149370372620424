import { FC } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { imgBoxPlaceholderStyles, imgBoxStyles } from './styles';

export interface IItem {
  docType: number;
  docTypeName: string;
  files: {
    userId: number;
    docPath: string;
    docName: string;
    docType: number;
    id?: number;
  }[];
}

interface IFileUploaderAndViewer {
  item: IItem;
  handleUploadFile: (item: IItem, e: HTMLInputElement) => void;
  fileLoading: boolean;
  documentType: number;
}

const FileUploaderAndViewer: FC<IFileUploaderAndViewer> = ({
  item,
  handleUploadFile,
  fileLoading,
  documentType,
}) => {
  console.log(item);
  return (
    <Box>
      {item.files.length === 0 ? (
        <label
          key={item.docType}
          htmlFor={item.docType.toString()}
          className="hover:tw-opacity-70 tw-cursor-pointer tw-transition-all"
        >
          <input
            type="file"
            id={item.docType.toString()}
            style={{ display: 'none' }}
            onChange={(e) => {
              handleUploadFile(item, e.currentTarget as HTMLInputElement);
            }}
          />
          <Box
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.35)',
              borderRadius: '1.6rem',
            }}
          >
            <Box
              sx={{
                borderBottom: '1px solid rgba(0, 0, 0, 0.35)',
                padding: '1.3rem 4rem',
              }}
            >
              <Typography> {item.docTypeName}</Typography>
            </Box>
            <div style={imgBoxPlaceholderStyles}>
              <img
                src="/assets/images/document-placeholder.png"
                style={{ borderRadius: '1.6rem' }}
              />
            </div>
          </Box>
        </label>
      ) : (
        item.files.map((file) => {
          return (
            <Box
              sx={{
                border: '1px solid rgba(0, 0, 0, 0.35)',
                borderRadius: '1.6rem',
              }}
            >
              <Box
                sx={{
                  borderBottom: '1px solid rgba(0, 0, 0, 0.35)',
                  padding: '1.3rem 4rem',
                }}
              >
                <Typography> {item.docTypeName}</Typography>
              </Box>
              <div
                style={imgBoxStyles}
                onClick={() => {
                  window.open(file.docPath, '_blank');
                }}
              >
                {fileLoading && documentType === item.docType && (
                  <i className="tw-absolute tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
                    <CircularProgress size={60} />
                  </i>
                )}
                <img
                  key={file.docType}
                  src={file.docPath}
                  style={{ borderRadius: '0 0 1.6rem 1.6rem' }}
                />
              </div>
              <label
                key={item.docType}
                htmlFor={item.docType.toString()}
                className="hover:tw-opacity-70 tw-cursor-pointer tw-transition-all"
              >
                <input
                  type="file"
                  id={item.docType.toString()}
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    handleUploadFile(item, e.currentTarget as HTMLInputElement);
                  }}
                />
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontSize: '24px',
                    textTransform: 'uppercase',
                  }}
                  color={'primary'}
                >
                  загрузить файл
                </Typography>
              </label>
            </Box>
          );
        })
      )}
    </Box>
  );
};

export default FileUploaderAndViewer;
