import { FC } from 'react';
import { ActionsGroup } from 'components/forms/ActionsGroup';
import { Box, Button, useMediaQuery } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import IosShareIcon from '@mui/icons-material/IosShare';
import { FormikProps } from 'formik';
import { IInitialValues } from '../../schema';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IServiceCreateResponse,
  useSignServiceMutation,
} from 'store/hooks/services';
import { toastPromise } from 'shared/utils/toastPromise';

interface IServiceActions {
  formik: FormikProps<IInitialValues>;
  transitions?: IServiceCreateResponse['transitions'];
  setDto: (dto: IServiceCreateResponse) => void;
}

export const ServiceActions: FC<IServiceActions> = ({
  formik,
  transitions,
  setDto,
}) => {
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const [signServiceApplication] = useSignServiceMutation();
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');

  const discIcon = (
    <SaveIcon width="16px" height="16px" fill="currentColor" stroke="none" />
  );

  const signIcon = (
    <IosShareIcon
      width="16px"
      height="16px"
      fill="currentColor"
      stroke="none"
    />
  );

  const handleSign = () => {
    const promise = signServiceApplication({
      id: Number(applicationId) || 0,
    });

    toastPromise(
      promise,
      {
        pending: 'Заявка подписывается',
        success: 'Заявка успешно подписан',
        error: 'Произошла ошибка',
      },
      {
        then: (data) => {
          console.log('Data: ', data);
          setDto(data);
        },
      }
    );
  };

  return (
    <ActionsGroup onBack={() => navigate(-1)}>
      <Button
        disabled={transitions?.buttonSettings.btn_save?.readOnly}
        startIcon={discIcon}
        onClick={() => formik.submitForm()}
      >
        Сохранить
      </Button>
      <Button
        disabled={transitions?.buttonSettings.btn_sign?.readOnly}
        startIcon={signIcon}
        onClick={handleSign}
      >
        Отправить
      </Button>
    </ActionsGroup>
  );
};
