import { FC, memo } from 'react';

const snowTypes = ['❅', '❆', '❄'];

const getRandomSnow = () => {
  const idx = Math.round(Math.random() * 2);
  return snowTypes[idx];
};

interface ISnowFlake {
  count?: number;
}
const SnowFlake: FC<ISnowFlake> = ({ count = 25 }) => {
  const isAnimationAllowed = parseInt(
    process.env.REACT_APP_SEASON_ANIMATION || ''
  )

  if (!isAnimationAllowed) {
    return null
  }

  return (
    <div className="snowflake">
      {Array.from({ length: count }).map((item, idx) => (
        <span key={idx} className="snow">
          {getRandomSnow()}
        </span>
      ))}
    </div>
  );
};

export default memo(SnowFlake);
