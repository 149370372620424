import { Typography } from '@mui/material';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

function TableCellToRowValue(props: Props) {
  return (
    <Typography
      variant="body1"
      sx={{
        fontSize: '14px',
        fontWeight: '400',
        color: '#22222280',
        mb: '12px',
        width: '50%',
      }}
    >
      {props.children}
    </Typography>
  );
}

export default TableCellToRowValue;
