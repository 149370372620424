import { AxiosResponse } from 'axios';
import { ApiRoutes, AuthApiRoutes } from '../../../shared/constants/ApiRoutes';
import {
  ICheckCodeReq,
  IChangePassword,
  IGetCodeReq,
  ILoginReq,
  ILoginRes,
  IRefreshTokenReq,
  IRefreshTokenRes,
  IUserProfileDetailResponse,
  IGetCodeInResetPassword,
  IResetPassword,
} from './models';
import axios from '../../../shared/utils/axios';
import { clearTokenFromStorage } from '../../../shared/utils/getTokenFromStorage';

class AuthService {
  async login(payload: ILoginReq): Promise<AxiosResponse<ILoginRes>> {
    return axios.post(AuthApiRoutes.login, payload);
  }

  async getCode(payload: IGetCodeReq) {
    return axios.post(AuthApiRoutes.getCode, payload);
  }

  async getCodeInResetPassword(payload: IGetCodeInResetPassword) {
    return axios.post(AuthApiRoutes.getCodeInResetPassword, payload);
  }

  async checkCode(payload: ICheckCodeReq) {
    return axios.post(AuthApiRoutes.checkCode, payload);
  }

  async changePassword(payload: IChangePassword) {
    return axios.post(AuthApiRoutes.changePassword, payload);
  }

  async resetPassword(payload: IResetPassword) {
    return axios.post(AuthApiRoutes.resetPassword, payload);
  }

  async refreshToken(
    payload: IRefreshTokenReq
  ): Promise<AxiosResponse<IRefreshTokenRes>> {
    return axios.post(AuthApiRoutes.refreshToken, payload);
  }

  async logout() {
    const response = axios.post(AuthApiRoutes.logout, {});
    clearTokenFromStorage();
    return response;
  }

  async getUserProfileDetail(): Promise<
    AxiosResponse<IUserProfileDetailResponse>
  > {
    return axios.post(ApiRoutes.userProfileDetail, {});
  }
}

const authApi = new AuthService();

export default authApi;
