import { LinearProgress } from '@mui/material';
import { DataGrid, GridRowId, ruRU } from '@mui/x-data-grid';
import { useState } from 'react';
import Pagination from '../ui/Pagination';
import { useNavigate } from 'react-router-dom';

export function useSelectedRow() {
    const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
    const onSelectionModelChange = (selection: any) => {
        if (selection.length > 1) {
            const selectionSet = new Set(selectedRows);
            const result = selection.filter((s: any) => !selectionSet.has(s));
            setSelectedRows(result);
        } else {
            setSelectedRows(selection);
        }
    };
    return { selectedRows, onSelectionModelChange };
}

const DataTable = ({
    sx,
    columns,
    items,
    isLoading,
    page,
    totalItems,
    setPage,
    pageSize,
    setPageSize,
    pushUri,
    rowStyle,
    getRowId,
    components = {},
    onRowClick,
    showCellRightBorder,
    showColumnRightBorder,
    checkboxSelection = true,
    selectionModel,
    onSelectionModelChange,
    ...props
}: any) => {
    const navigate = useNavigate();

    return (
        <div>
            <DataGrid
                sx={{
                    fontSize: '14px',
                    ...sx,

                    '& .MuiDataGrid-cell': {
                        borderBottom: 'none',
                    },
                }}
                autoHeight
                columns={columns}
                getRowId={getRowId}
                classes={{
                    root: 'tw-bg-white !tw-rounded-[26px] tw-border-[1px] tw-border-[#f6f4f3] tw-shadow-[0_0_4px_0_#00000025]',
                    row: 'tw-cursor-pointer',
                }}
                checkboxSelection={checkboxSelection}
                getRowClassName={rowStyle}
                rows={items ? items : []}
                selectionModel={selectionModel}
                loading={isLoading}
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                showCellRightBorder={showCellRightBorder}
                showColumnRightBorder={showColumnRightBorder}
                hideFooterSelectedRowCount
                initialState={{
                    pagination: {
                        paginationModel: {
                            page,
                            pageSize: pageSize || 10,
                        }
                    },
                }}
                page={page}
                rowCount={totalItems}
                slots={{
                    loadingOverlay: LinearProgress,
                    pagination: Pagination,
                    ...components,
                }}
                slotProps={{
                    pagination: {
                        page,
                        rowCount: totalItems,
                        perPage: pageSize || 10,
                        onChange: (currPage: any) => {
                            setPage(currPage);
                        },
                        onChangePerPage: (perPage: number) => {
                            if (setPageSize) {
                                setPageSize(perPage);
                            }
                        },
                    },
                }}
                pagination
                paginationMode="server"
                onRowClick={(params) => {
                    console.log(params)
                    if (pushUri) {
                        navigate(`${pushUri}/${params.row.service.id}/application/show/${params.row.id}`);
                    }
                    if (onRowClick) onRowClick(params);
                }}
                onSelectionModelChange={onSelectionModelChange}
                {...props}
            />
        </div>
    );
};
export default DataTable;
