import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import {
  IUserDocumentCreate,
  useCreateDocumentsMutation,
  useGetDocumentsQuery,
  useGetUserprofileDetailsQuery,
  useUpdateDocumentsMutation,
} from 'store/hooks/userprofile';
import { toast } from 'react-toastify';
import fileService from 'store/services/fileService';
import { toastPromise } from 'shared/utils/toastPromise';
import FileUploaderAndViewer, {
  IItem,
} from './components/FileUploaderAndViewer';
import { useDocuments } from './helpers/hooks';
import { useState } from 'react';
import SelectForMonile from 'components/selectForMobile/SelectForMonile';
import { useNavigate } from 'react-router-dom';

const Documents = () => {
  const { data, refetch } = useGetDocumentsQuery({
    ids: null,
    filters: {},
    orderBy: {
      orderColumn: 1,
      direction: 0,
    },
    pageInfo: {
      pageNumber: 1,
      pageSize: 120,
    },
  });
  const navigate = useNavigate();
  const [createDocuments] = useCreateDocumentsMutation();
  const { data: user } = useGetUserprofileDetailsQuery();
  const [updateDocuments] = useUpdateDocumentsMutation();
  const [fileLoading, setFileLoading] = useState(false);
  const [documentType, setDocumentType] = useState(0);
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');

  const handleUploadFile = async (item: IItem, event: HTMLInputElement) => {
    setDocumentType(item.docType);
    setFileLoading(true);
    const filesFormat = ['.png', '.jpg', '.jpeg'];
    const file = event.files;

    if (!file) return;

    function ext(name: any) {
      return name.match(/\.([^.]+)$|$/)[1];
    }

    const isRightFormat = filesFormat.includes('.' + ext(file[0].name));
    if (!isRightFormat) {
      toast(
        'Вы можете загрузить только фотографии в формате .png, .jpg, .jpeg ',
        { type: 'error', position: 'top-right' }
      );
      setFileLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append(Math.random().toString(), file[0]);

    await fileService.uploadFileV2(formData).then((e) => {
      const resp = e as { data: any };

      let promise = null;

      const payload: IUserDocumentCreate = {
        userId: user?.id,
        docPath: resp.data.url,
        docName: file[0]?.name,
        docType: item.docType,
      };

      if (item.files.length > 0) {
        promise = updateDocuments({
          id: item.files[0].id,
          ...payload,
        });
      } else {
        promise = createDocuments(payload);
      }

      const isUpdating = item.files.length > 0;
      toastPromise(
        promise,

        {
          pending: `${isUpdating ? 'Обновление' : 'Добавление'} документа`,
          success: `Документ успешно ${isUpdating ? 'обновлен' : 'добавлен'}`,
          error: `Не удалось ${isUpdating ? 'обновить' : 'добавить'} документ`,
        },
        {
          then: () => {
            refetch();
            setFileLoading(false);
          },
        }
      );
    });
  };

  const { mainDocs, additionalDocs } = useDocuments(data?.items || []);

  return (
    <Box sx={{ padding: isSmallDevice ? '-20px' : '40px' }}>
      <SelectForMonile />
      <Box
        sx={{
          display: 'flex',
          flexDirection: isSmallDevice ? 'column' : '',
          gap: '16px',
          marginBottom: '3.2rem',
        }}
      >
        <Button
          variant="contained"
          sx={{
            borderRadius: '1.6rem',
            fontSize: isSmallDevice ? '14px' : '16px',
            width: isSmallDevice ? '200px' : '200px',
            margin: isSmallDevice ? 'auto' : '0',
          }}
        >
          Личные данные
        </Button>
        <Button
          sx={{
            borderRadius: '1.6rem',
            fontSize: isSmallDevice ? '14px' : '16px',
            width: isSmallDevice ? '220px' : '250px',
            margin: isSmallDevice ? 'auto' : '0',
          }}
          onClick={() => {
            navigate('/my-services');
          }}
        >
          Приобретенные услуги
        </Button>
      </Box>
      <Box>
        <div className="lg:tw-grid tw-grid-cols-2 tw-gap-x-14 ">
          <Box>
            <Typography
              fontSize={20}
              fontWeight={700}
              sx={{ marginBottom: '1.6rem' }}
            >
              Основные документы
            </Typography>
            <div className="tw-flex tw-flex-col tw-gap-10  lg:tw-mb-0">
              {mainDocs.map((item) => (
                <FileUploaderAndViewer
                  key={item.docType}
                  item={item}
                  handleUploadFile={handleUploadFile}
                  fileLoading={fileLoading}
                  documentType={documentType}
                />
              ))}
            </div>
          </Box>
          {user?.userType == 2 && (
            <Box>
              <Typography
                fontSize={20}
                fontWeight={700}
                sx={{
                  marginBottom: '1.6rem',
                  marginTop: isSmallDevice ? '20px' : '',
                }}
              >
                Документы организации
              </Typography>
              <div className="tw-flex tw-flex-col tw-gap-y-10">
                {additionalDocs.map((item) => (
                  <FileUploaderAndViewer
                    key={item.docType}
                    item={item}
                    handleUploadFile={handleUploadFile}
                    fileLoading={fileLoading}
                    documentType={documentType}
                  />
                ))}
              </div>
            </Box>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default Documents;
