import * as Yup from 'yup';
import { stringSchema } from '../../../shared/utils/yup';
import { phoneRegexpCompleted } from '../login/helpers/constants';

export interface IInitialValues {
  userLogin: string;
  password: string;
  code: string;
  type: string;
  codeSended: boolean;
  codeChecked: boolean;
}

export type KeyOfInitials = keyof IInitialValues;

export const initialUserValues: IInitialValues = {
  userLogin: '',
  password: '',
  code: '',
  type: '',
  codeSended: false,
  codeChecked: false,
};

export const validationSchema = Yup.object({
  userLogin: Yup.string()
    .required('Обязательно')
    .test({
      test: (value) => phoneRegexpCompleted?.test(value),
      message: 'Неправильный формат логина: 992XXXXXXXXX',
    }),
  password: stringSchema(5),
});
