import { ToastOptions, ToastPromiseParams, toast } from 'react-toastify';

interface IHandleParam {
  then?: (data: any) => void;
  catch?: (err: any) => void;
}

export const toastPromise = (
  promise: Promise<any>,
  options?: ToastPromiseParams<string, string, string>,
  handler?: IHandleParam
) => {
  const toastId = toast.loading((options?.pending as string) || '');

  promise.then((res) => {
    toast.dismiss(toastId);
    if (!res || 'error' in res) {
      if (handler?.catch) {
        handler.catch(res.error.data);
      }
      return;
    }

    if (handler?.then) {
      toast.success((options?.success as string) || '');
      handler?.then(res.data);
    }
  });
};
