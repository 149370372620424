import { FC } from 'react';
import { Avatar, Box, Checkbox, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { IInitialValues } from '../../schema';

interface IServiceExecutors {
  formik: FormikProps<IInitialValues>;
}

export const ServiceExecutors: FC<IServiceExecutors> = ({ formik }) => {
  const { values } = formik;

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          {!values.executor ? (
            <Typography color="gray" variant="h6">
              Нет испольнителья
            </Typography>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Avatar />
              <Typography fontSize="15px">{values.executor?.value}</Typography>
            </Box>
          )}
        </Box>
        {/* <Button color="primary" variant="outlined">
          Открыт Чат
        </Button> */}
      </Box>
    </Box>
  );
};
