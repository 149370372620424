import { SxProps, Theme } from '@mui/material';

export const tabsListSx: SxProps = {
  p: 0.6,
  gap: 0.6,
  borderRadius: '5rem',
  height: '52px',
  bgcolor: 'background.level1',
  display: 'inline-flex',
  ['& button']: {
    flex: 1,
  },
};

export const tabsSx: SxProps = { bgcolor: 'transparent' };

export const boxSx: SxProps = {
  p: '3px',
  borderRadius: 5,
  display: 'flex',
  gap: '3px',
  backgroundColor: '#fff',
  boxShadow: 1,
};

export const getItemSx = (isActive: boolean, theme: Theme) => {
  return {
    p: '12px 5px',
    flex: 1,
    textAlign: 'center',
    borderRadius: 5,
    cursor: 'pointer',
    color: isActive ? '#fff' : '#000',
    backgroundColor: isActive ? theme.palette.primary.main : 'transparent',
  } as SxProps;
};
