import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { IChatMessageService } from 'store/hooks/services/chat';
import { ChatMessageItemFiles } from './ChatMessageItemFIles';
import { hoursFormat } from 'shared/utils/functions';
import EditIcon from '@mui/icons-material/Edit';

interface IChatMessageItem {
  isMe?: boolean;
  data: IChatMessageService;
  onEditMessage: (item: IChatMessageService) => void;
}

export const ChatMessageItem: FC<IChatMessageItem> = ({
  isMe,
  data,
  onEditMessage,
}) => {
  return (
    <Box
      sx={{
        '&:hover .edit-icon': {
          display: 'inline-block',
        },
      }}
      className="tw-mb-3 tw-flex"
    >
      <Box
        className={`tw-inline-block tw-relative tw-max-w-[60%] ${
          isMe ? 'tw-ml-auto' : ''
        }`}
      >
        <Box className="tw-flex tw-flex-col tw-flex-gap-4 tw-px-3 tw-py-5 tw-rounded-lg tw-bg-primaryLight">
          <Typography variant="body1">{data.text}</Typography>
          {Boolean(data.files?.length) && (
            <ChatMessageItemFiles files={data.files} />
          )}
          <Typography
            className={'tw-absolute tw-bottom-1 tw-right-2'}
            variant="caption"
          >
            {hoursFormat(data.createAt)}
          </Typography>
          <EditIcon
            onClick={() => onEditMessage(data)}
            sx={{ display: 'none' }}
            className="edit-icon tw-absolute tw-top-1 -tw-left-8 tw-cursor-pointer"
          />
        </Box>
      </Box>
    </Box>
  );
};
