import { AppRoutes } from '../../../../shared/constants/AppRoutes';

export const categories = [
  {
    iconUrl: '/assets/images/finance-icon.png',
    name: 'Министерство Финансов',
  },
  {
    iconUrl: '/assets/images/internal-icon.png',
    name: 'Министерство Внутренных Дел',
  },
  {
    iconUrl: '/assets/images/healthcare-icon.png',
    name: 'Министерство Здравохранение',
  },
  {
    iconUrl: '/assets/images/foreign-icon.png',
    name: 'Министерство Иностранных дел',
  },
  {
    iconUrl: '/assets/images/defence-icon.png',
    name: 'Министерство Обороны',
  },
  {
    iconUrl: '/assets/images/education-icon.png',
    name: 'Министерство Образование',
  },
  {
    iconUrl: '/assets/images/education-icon.png',
    name: 'Министерство Образование',
  },
  {
    iconUrl: '/assets/images/education-icon.png',
    name: 'Министерство Образование',
  },
];

export const services = [
  {
    url: AppRoutes.ApplicationResident,
    name: 'Заявление на получение резидентсвто РТ',
  },
];

export const moduleRoutes: Record<number, string> = {
  5: 'application-resident',
};
