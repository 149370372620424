import { SxProps } from '@mui/material';

type GetModalSx = (modal: boolean) => SxProps;

export const getBackgroundSx: GetModalSx = (modal) => {
  return {
    opacity: modal ? 1 : 0,
    transform: `scale(${modal ? 1 : 0})`,
    height: '100vh',
    width: '100vw',
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
  };
};

export const getContainerSx: GetModalSx = (modal) => {
  return {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: `translateX(-50%) translateY(-50%) scale(${modal ? 1 : 0})`,
    opacity: modal ? 1 : 0,
    transition: 'all .3s ease',
  };
};

export const titleBoxSx: SxProps = {
  padding: '1.6rem 4rem',
  background: '#607D8B',
  borderRadius: '1.6rem 1.6rem 0 0 ',
};

export const titleSx: SxProps = {
  color: '#FFF',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '130%',
};

export const contentBoxSx: SxProps = {
  padding: '4rem',
  background: '#fff',
  borderRadius: '0 0 1.6rem 1.6rem',
};
