import { SxProps } from '@mui/material';
import { CSSProperties } from 'react';

export const boxSx: SxProps = {
  padding: '3.2rem',
  borderRadius: '1.6rem',
  border: '1px solid rgba(0, 0, 0, 0.31)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginRight: '4rem',
};

export const titleSx: SxProps = {
  fontSize: '2.4rem',
  fontWeight: 700,
  textAlign: 'center',
  lineHeight: 'normal',
  color: '#222',
  marginBottom: '0.8rem',
};

export const subtitleSx: SxProps = {
  fontSize: '1.4rem',
  fontWeight: '500',
  color: 'rgba(34, 34, 34, 0.50)',
};

export const titleBoxSx: SxProps = {
  color: '#737C86',
  fontSize: '1.2rem',
};

export const infoBoxSx: SxProps = {
  padding: '1.2rem 2.4rem',
  border: '1px solid #dddbde',
  color: '#222',
  fontSize: '1.6rem',
  borderRadius: '0.8rem',
  fontFamily: 'Roboto',
  flex: 8,
  marginRight: '1.6rem',
  height: '49.6px',
  boxSizing: 'border-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const secondInfoBoxSx: SxProps = {
  padding: '1.2rem 2.4rem',
  border: '1px solid #dddbde',
  color: '#222',
  fontSize: '1.6rem',
  borderRadius: '0.8rem',
  fontFamily: 'Roboto',
  flex: 8,
  height: '49.6px',
  boxSizing: 'border-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const inputStyles: CSSProperties = {
  border: '1px solid #607d8b',
  flex: 8,
  marginRight: '1.6rem',
  borderRadius: '0.8rem',
  padding: '1.2rem 2.4rem',
  outline: 'none',
};

export const safeBoxSx: SxProps = {
  padding: '2.4rem',
  borderRadius: '0.8rem',
  border: '1px solid rgba(0, 0, 0, 0.31) ',
  display: 'flex',
  flexDirection: 'column',
  gap: '1.6rem',
};
