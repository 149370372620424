import { SxProps } from '@mui/material';

export const buttonSx: any = {
  borderRadius: 12,
  fontSize: '18px',
  height: '60px',
  textTransform: 'unset',
};

export const circularSx: SxProps = {
  position: 'absolute',
  top: '3px',
  left: '-36px',
  color: '#fff',
};
