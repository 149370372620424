import { useMemo } from 'react';
import { additionalDocuments, mainDocuments } from './constants';
import { IDocument } from 'store/hooks/userprofile';

export const useDocuments = (items?: IDocument[]) => {
  return useMemo(() => {
    return {
      mainDocs: mainDocuments.map((item) => ({
        ...item,
        files: items?.filter((file) => file.docType == item.docType) || [],
      })),
      additionalDocs: additionalDocuments.map((item) => ({
        ...item,
        files: items?.filter((file) => file.docType == item.docType) || [],
      })),
    };
  }, [items]);
};
